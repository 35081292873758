/* eslint-disable no-nested-ternary */
import { useTranslation } from "react-i18next";

import {
  StyledTable,
  TableBody,
  TableCol,
  TableHeadCol,
  TableHeadRow,
  TableRow,
  TableText,
  TableWrapper
} from "../../../../../../components/common/New/Table/ToolTable.styled";
import Percent from "../../../../../../components/ui/New/Percent/Percent";
import { SkeletonToolTable } from "../../../../../../components/ui/New/Skeleton/SkeletonToolTable/SkeletonToolTable";
import Tooltip from "../../../../../../components/ui/New/Tooltip/Tooltip";

import { InfoIcon } from "./AttributesTable.styled";

export const AttributesTable = ({ isLoading, data, margin = "0" }) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <SkeletonToolTable cells={10} />
      ) : (
        <StyledTable margin={margin}>
          <TableWrapper>
            <TableHeadRow>
              <TableHeadCol width="64px" justifyContent="center">
                {"№"}
              </TableHeadCol>
              <TableHeadCol width="265px">
                {t("fingerprint.table.attribute")}
              </TableHeadCol>
              <TableHeadCol width="180px">
                {t("fingerprint.table.similarityRatio")}
              </TableHeadCol>
              <TableHeadCol minWidth="600px">
                {t("fingerprint.table.value")}
              </TableHeadCol>
            </TableHeadRow>
            <TableBody maxHeight="1086px">
              {data.map((item, index) => (
                <TableRow key={index} height="64px">
                  <TableCol width="64px">
                    <TableText>
                      {index < 9 ? (
                        <TableText justifyContent="center">{`0${
                          index + 1
                        }.`}</TableText>
                      ) : (
                        <TableText justifyContent="center">{`${
                          index + 1
                        }.`}</TableText>
                      )}
                    </TableText>
                  </TableCol>
                  <TableCol width="265px">
                    <TableText>
                      <TableText width="auto">{item.attribute}</TableText>
                      <Tooltip body={item.attribute} place="top">
                        <InfoIcon src="img/icons/info.svg" alt="Info" />
                      </Tooltip>
                    </TableText>
                  </TableCol>
                  <TableCol width="180px">
                    <TableText>
                      <Percent
                        number={item.similarityRatio}
                        color={
                          Number(item.similarityRatio) >= 50
                            ? "success"
                            : Number(item.similarityRatio) >= 10
                            ? "warning"
                            : "error"
                        }
                      />
                    </TableText>
                  </TableCol>
                  <TableCol minWidth="600px">
                    <TableText>{item?.value}</TableText>
                  </TableCol>
                </TableRow>
              ))}
            </TableBody>
          </TableWrapper>
        </StyledTable>
      )}
    </>
  );
};
