import styled from "@emotion/styled";

export const StyledTabTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;

  overflow: hidden;

  & > img {
    width: 40px;
    aspect-ratio: 1;
    object-fit: contain;
    flex: none;

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      width: 44px;
    }
  }

  & > span {
    color: ${(p) => p.theme.colors["neutral-800"]};
    font-size: ${(p) => p.theme.fontSizes.xl};
    font-family: ${(p) => p.theme.fontFamily.title};
    font-weight: ${(p) => p.theme.fontWeight.bold};
    line-height: 120%;

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      font-size: ${(p) => p.theme.fontSizes.xll};
    }
  }
`;
