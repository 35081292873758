import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { StyledIcon, StyledLocationItem } from "./LocationItem.styled";

export const LocationItem = ({ title, isSelected, onClick, className }) => {
  const { t } = useTranslation();
  return (
    <StyledLocationItem
      active={isSelected}
      className={className}
      onClick={onClick}
    >
      {t(`navigation.${title}`)}
      <StyledIcon name="arrowDown" />
    </StyledLocationItem>
  );
};

LocationItem.propTypes = {
  title: PT.string.isRequired,
  isSelected: PT.bool,
  onClick: PT.func,
  className: PT.string
};

LocationItem.defaultProps = {
  className: ""
};
