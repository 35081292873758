import styled from "@emotion/styled";

import { Title } from "../../../ui/New/Title/Title";
import Container from "../Container/Container";

export const StyledContainer = styled(Container)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 64px !important;
  padding-bottom: 64px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
`;

export const StyledContent = styled.div`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-right: 46px;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 32px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 48px;
  }
`;

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 32px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
  }
`;

export const StyledListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 36px;
  }
`;

export const StyledStep = styled.p`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.21;
  margin-bottom: 24px;
`;

export const StyledDescription = styled.p`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.67;
`;

export const StyledPicture = styled.picture`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  max-width: 500px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const StyledAnimationWrapper = styled.div`
  position: relative;
  display: none;
  width: 0;
  height: 0;
`;
