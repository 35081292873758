import { useTranslation } from "react-i18next";

import { browserInfoTableData } from "../../../../../../utils/helpers";

import { List } from "./ReactTable.styled";
import ReactTableItem from "./components";

export const ResultsTable = ({ data, info }) => {
  const { t } = useTranslation();

  const tableData = browserInfoTableData(data, info, t);

  return (
    <List>
      {tableData.map(({ src, alt, label, value }, i) => (
        <ReactTableItem
          key={i}
          src={src}
          alt={alt}
          label={label}
          value={value}
        />
      ))}
    </List>
  );
};
