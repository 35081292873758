import { useState } from "react";

import PT from "prop-types";

import Icon from "../Icon/Icon";

import {
  AlertStyled,
  SltyledClose,
  StyledContent,
  StyledText,
  StyledTitle
} from "./Alert.styled";

export const Alert = ({
  title,
  showIcon,
  closable,
  onClose,
  type,
  className,
  children
}) => {
  const [close, setClose] = useState(false);

  const handlerClose = () => {
    setClose(true);
    onClose?.();
  };

  return (
    <AlertStyled className={className} type={type} close={close}>
      {showIcon && <Icon name={type} />}
      <StyledContent>
        {title && <StyledTitle>{title}</StyledTitle>}
        <StyledText>{children}</StyledText>
      </StyledContent>
      {closable && (
        <SltyledClose type="button" aria-label="Close" onClick={handlerClose}>
          <Icon name="x" />
        </SltyledClose>
      )}
    </AlertStyled>
  );
};

Alert.propTypes = {
  title: PT.string,
  showIcon: PT.bool,
  closable: PT.bool,
  onClose: PT.func,
  type: PT.oneOf(["info", "success", "warning", "error"]),
  className: PT.string,
  children: PT.node
};

Alert.defaultProps = {
  title: "",
  showIcon: true,
  closable: true,
  onClose: null,
  type: "info",
  className: "",
  children: null
};
