import { useTranslation } from "react-i18next";

import { RowsInfoStyled } from "./RowsInfo.styled";

export const RowsInfo = ({ currentPage, rowsPerPage, rowCount, className }) => {
  const { t } = useTranslation();
  const from = currentPage * rowsPerPage - rowsPerPage + 1;
  const to =
    currentPage * rowsPerPage > rowCount ? rowCount : currentPage * rowsPerPage;
  const total = rowCount;
  return (
    <RowsInfoStyled className={className}>
      {`${from} - ${to} ${t("pagination.table.from")} ${total}`}
    </RowsInfoStyled>
  );
};
