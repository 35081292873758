import {
  StyledShortInfoItem,
  StyledShortInfoList,
  StyledShortInfoText
} from "./ShortInfo.styled";

export const ShortInfo = ({ data }) => (
  <StyledShortInfoList>
    {data.map((item, index) => (
      <StyledShortInfoItem key={index}>
        <StyledShortInfoText>{item.title}</StyledShortInfoText>
        <StyledShortInfoText variant="value">{item.value}</StyledShortInfoText>
      </StyledShortInfoItem>
    ))}
  </StyledShortInfoList>
);
