import Icon from "../../../../../../components/ui/New/Icon/Icon";
import Tooltip from "../../../../../../components/ui/New/Tooltip/Tooltip";

import { StyledSkeleton } from "../../../../../../components/ui/New/Skeleton/components/SkeletonTableComponents.styled";

import {
  StyledListItem,
  StyledListItemTitle,
  StyledListItemValue
} from "./Informations.styled";

export const RowInfo = ({
  title,
  tooltip = "",
  labelCenter = false,
  valueDirection = "row",
  children,
  loading = false
}) => (
  <StyledListItem>
    <StyledListItemTitle center={labelCenter}>
      {tooltip && (
        <Tooltip
          body={"Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
        >
          <Icon name={"info"} />
        </Tooltip>
      )}
      {!loading ? title : <StyledSkeleton width={100} height={14} />}
    </StyledListItemTitle>
    <StyledListItemValue column={valueDirection === "column"}>
      {!loading ? children : <StyledSkeleton width={100} height={14} />}
    </StyledListItemValue>
  </StyledListItem>
);
