import styled from "@emotion/styled";

export const ShowPasswordStyled = styled.button`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  z-index: 1;

  width: 24px;
  height: 24px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  & > svg {
    width: 24px;
    height: 24px;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${(p) => p.theme.colors["neutral-300"]};
  }
`;

export const InputBoxStyled = styled.div`
  position: relative;
  display: flex;
  ${(p) =>
    p.code
      ? `gap: 12px; justify-content: space-between;
    @media (min-width: 480px) {
      gap: 16px;
    }

    & > input {
    text-align: center;
  }
      `
      : ""}

  width: 100%;

  & > input,
  & > textarea {
    outline: none;
    display: flex;
    // height: 48px;
    width: 100%;
    padding: 11px 15px;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;

    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: ${(p) => p.theme.fontSizes.m};
    font-style: ${(p) => p.theme.fontStyle.normal};
    font-weight: ${(p) => p.theme.fontWeight.normal};
    line-height: 150%;

    border: 1px solid
      ${(p) =>
        p.isError ? p.theme.colors["red-500"] : p.theme.colors["neutral-100"]};
    background-color: ${(p) =>
      p.isError ? "#fff5f54d" : p.theme.colors.white};

    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
      border: 1px solid
        ${(p) =>
          p.isError
            ? p.theme.colors["red-500"]
            : p.theme.colors["neutral-500"]};
      background-color: ${(p) =>
        p.isError ? "#fff5f54d" : p.theme.colors.white};
      transition: border-color 0.3s ease-in-out;
    }

    &:focus {
      outline: none;
      border: 1px solid
        ${(p) =>
          p.isError ? p.theme.colors["red-500"] : p.theme.colors["main-700"]};
      background-color: ${(p) => (p.isError ? "#fff5f54d" : "#f7ffe926")};
      transition: background-color 0.3s ease-in-out;
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
      -webkit-text-fill-color: ${(p) => p.theme.colors["neutral-800"]};
      transition: background-color 5000s ease-in-out 0s;

      &:focus,
      &:focus-within {
        -webkit-box-shadow: 0 0 0px 1000px
          ${(p) => (p.isError ? "#fff5f54d" : "#f7ffe926")} inset;
      }
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }

    &:disabled {
      background-color: ${(p) => p.theme.colors["neutral-25"]} !important;
      border: 1px solid ${(p) => p.theme.colors["neutral-100"]} !important;
      color: ${(p) => p.theme.colors["neutral-500"]} !important;
      transition: border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
      cursor: not-allowed;
    }

    &::placeholder {
      color: ${(p) => p.theme.colors["neutral-300"]};
    }
  }

  & > textarea {
    min-height: 60px;
    resize: vertical;
    &::-webkit-resizer {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.28577 11.7711L11.771 3.28584' stroke='%23D8DAE0' stroke-linecap='round'/%3E%3Cpath d='M7.05725 12.7142L12.7141 7.05738' stroke='%23D8DAE0' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }
`;

export const LengthCounterStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 10px;
  align-items: center;
  color: ${(p) =>
    p.isError ? p.theme.colors["red-500"] : p.theme.colors["neutral-200"]};
  text-align: right;
  font-size: ${(p) => p.theme.fontSizes.xxs};
  line-height: 130%;
`;
