import styled from "@emotion/styled";

export const StyledFeedBack = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`;

export const StyledFeedbackButton = styled.button`
  padding: 2px;
  outline: none;
  width: 24px;
  height: 24px;
  color: ${(p) => p.theme.colors["neutral-400"]};

  &:hover,
  &:focus {
    color: ${(p) =>
      p.variant === "yes"
        ? p.theme.colors["main-600"]
        : p.theme.colors["red-500"]};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${(p) => p.theme.colors["neutral-300"]};
  }

  & > svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledFeedbackCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  min-width: 18px;
  border-radius: 12px;
  padding: 3px 5px;

  background-color: ${(p) =>
    p.variant === "yes"
      ? p.theme.colors["main-400"]
      : p.theme.colors["red-400"]};

  color: ${(p) => p.theme.colors["neutral-800"]};

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.xxs};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: 500;
  line-height: 100%;
`;

export const StyledFeedbackDivider = styled.span`
  width: 1px;
  height: 18px;
  background-color: ${(p) => p.theme.colors["neutral-200"]};
`;
