import styled from "@emotion/styled";

import Select from "../../../../../ui/New/Select/Select";

export const RestyledSelect = styled(Select)`
  .form-select__control {
    height: 60px;
    padding: 7px 11px;
    gap: 0;
  }

  .form-select__single-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::before {
      content: "${(p) => p.dataTextLabel}";
    }
  }

  .form-select__indicator {
    padding: 10px;
  }
`;
