import FeedbackPage from "../../pages/New/AuthPages/FeedbackPage/FeedbackPage";
import FeedbackSuccess from "../../pages/New/AuthPages/FeedbackSuccess/FeedbackSuccess";
import LoginPage from "../../pages/New/AuthPages/LoginPage/LoginPage";
import RecoveryPassPage from "../../pages/New/AuthPages/RecoveryPassPage/RecoveryPassPage";
import RecoverySendPage from "../../pages/New/AuthPages/RecoverySendPage/RecoverySendPage";
import RegistrationPage from "../../pages/New/AuthPages/RegistrationPage/RegistrationPage";
import RegistrationSuccess from "../../pages/New/AuthPages/RegistrationSuccess/RegistrationSuccess";

export const AuthRoutes = (ssr) => [
  {
    path: "/:lang?/new/login",
    tag: "login",
    type: "auth",
    component: ssr ? LoginPage : () => import("../../pages/New/AuthPages/LoginPage/LoginPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/registration",
    tag: "registration",
    type: "auth",
    component: ssr ? RegistrationPage : () =>
      import("../../pages/New/AuthPages/RegistrationPage/RegistrationPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/registration/success",
    tag: "registration",
    type: "auth",
    component: ssr ? RegistrationSuccess : () =>
      import(
        "../../pages/New/AuthPages/RegistrationSuccess/RegistrationSuccess"
      ),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/recovery/",
    tag: "recovery",
    type: "auth",
    component: ssr ? RecoverySendPage : () =>
      import("../../pages/New/AuthPages/RecoverySendPage/RecoverySendPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/recovery/:token",
    tag: "recovery",
    type: "auth",
    component: ssr ? RecoveryPassPage : () =>
      import("../../pages/New/AuthPages/RecoveryPassPage/RecoveryPassPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/feedback",
    tag: "feedback",
    type: "auth",
    component: ssr ? FeedbackPage : () =>
      import("../../pages/New/AuthPages/FeedbackPage/FeedbackPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/new/feedback/success",
    tag: "feedback",
    type: "auth",
    component: ssr ? FeedbackSuccess : () =>
      import("../../pages/New/AuthPages/FeedbackSuccess/FeedbackSuccess"),
    isAuthRequired: false,
    isSsr: false
  }
];
