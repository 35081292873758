import { SkeletonTheme } from "react-loading-skeleton";

import {
  StyledCell,
  StyledRow,
  StyledSceletonTable,
  StyledSkeleton
} from "../components/SkeletonTableComponents.styled";

export const SkeletonUserChoise = ({ cells }) => (
  <SkeletonTheme inline>
    <StyledSceletonTable noBorder>
      <StyledRow rowHeight={52}>
        <StyledCell cellWidth={50} justify="center">
          <StyledSkeleton width={24} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={175}>
          <StyledSkeleton width={80} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={95}>
          <StyledSkeleton width={70} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={95}>
          <StyledSkeleton width={70} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={80}>
          <StyledSkeleton width={60} height={20} />
        </StyledCell>
      </StyledRow>
      {Array(cells)
        .fill(0)
        .map((_, i) => (
          <StyledRow rowHeight={64} key={`item-skeleton-${i}`}>
            <StyledCell cellWidth={50} justify="center">
              <StyledSkeleton width={24} height={16} />
            </StyledCell>
            <StyledCell cellMinWidth={175}>
              <StyledSkeleton width={22} height={16} />
              <StyledSkeleton width={110} height={16} />
            </StyledCell>
            <StyledCell cellMinWidth={95}>
              <StyledSkeleton width={40} height={14} />
            </StyledCell>
            <StyledCell cellMinWidth={95}>
              <StyledSkeleton width={40} height={14} />
            </StyledCell>
            <StyledCell cellMinWidth={80}>
              <StyledSkeleton width={60} height={14} />
            </StyledCell>
          </StyledRow>
        ))}
    </StyledSceletonTable>
  </SkeletonTheme>
);
