import styled from "@emotion/styled";

export const StyledListReviewContainer = styled.div`
  display: ${(p) => (p.active ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 32px;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    position: relative;
    margin: 0 !important;
    padding: 0 24px 24px;
    background: ${(p) => p.theme.colors.white};
    border-radius: 0 0 12px 12px;
    box-shadow: ${(p) => p.theme.shadows.addReviewCounter};
  }
`;

export const StyledListReviewHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;
  width: 100%;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    margin-bottom: 16px;
  }
`;

export const StyledListReviewSortBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 fit-content;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    max-width: 300px;
  }
`;

export const StyledListReviewSortLabel = styled.span`
  color: ${(p) => p.theme.colors["neutral-400"]};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 150%;
  white-space: nowrap;
`;

export const StyledListReviewSortSelectBox = styled.div`
  min-width: 130px;
  width: 100%;
`;
