import { useId } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useUserIp } from "../../../../../hooks/useUserIp";

import { useBrowserInfo } from "../../../../../hooks";

import {
  StyledBrowser,
  StyledContainer,
  StyledCountry,
  StyledCountryIcon,
  StyledFlag,
  StyledIcon,
  StyledImage,
  StyledInfo,
  StyledInnerContainer,
  StyledIp,
  StyledIpAddress,
  StyledIpAddressWrapper,
  StyledIpTitle,
  StyledIpWrapper,
  StyledResults,
  StyledText,
  StyledTextOverflow,
  StyledTooltip
} from "./BrowserInformation.styled";
import { ResultsTable } from "./ResultTable/ResultTable";

export const BrowserInformation = () => {
  const { t } = useTranslation();

  const customToastId = useId();
  const tooltipId = useId();

  const userIp = useUserIp();

  const { browserInfo } = useBrowserInfo(userIp);

  const onCopyHandler = () => {
    toast.success(t("notifications.copy"), {
      toastId: customToastId
    });
  };

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledInfo>
          <StyledImage src="img/ui/disguise-green.svg" />
          <StyledText>{t("browserInformation.youreUsing")}</StyledText>
          <StyledBrowser>
            {userIp?.app}
            {browserInfo.browserVersion.split(".")[0] ||
              userIp?.appMajorVersion}{" "}
            {t("ui.on")} {userIp?.platform}
          </StyledBrowser>
          <StyledIpWrapper>
            <StyledIp>
              <StyledIpTitle>{t("browserInformation.yourIP")}</StyledIpTitle>
              <StyledIpAddressWrapper>
                {userIp?.country && (
                  <StyledFlag
                    className={`fl fl-${userIp?.countryA2?.toLowerCase()} `}
                  />
                )}
                {userIp?.ipAddress?.length > 24 ? (
                  <StyledTooltip
                    id={tooltipId}
                    body={userIp?.ipAddress}
                    place="top-start"
                  >
                    <StyledTextOverflow>{userIp?.ipAddress}</StyledTextOverflow>
                  </StyledTooltip>
                ) : (
                  <StyledIpAddress>{userIp?.ipAddress}</StyledIpAddress>
                )}
                <CopyToClipboard
                  text={userIp?.ipAddress}
                  onCopy={onCopyHandler}
                >
                  <StyledIcon name="copyAlt" size="lg" />
                </CopyToClipboard>
              </StyledIpAddressWrapper>
            </StyledIp>
            {userIp?.country && (
              <StyledCountry>
                <StyledCountryIcon name="marker" size="lg" />
                <span>
                  {userIp?.city ? `${userIp?.city},` : ""} {userIp?.country}
                </span>
              </StyledCountry>
            )}
          </StyledIpWrapper>
        </StyledInfo>
        <StyledResults>
          <ResultsTable data={userIp} info={browserInfo} />
        </StyledResults>
      </StyledInnerContainer>
    </StyledContainer>
  );
};
