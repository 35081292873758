import { ExportPage } from "../../pages/New/ExportPage/ExportPage.jsx";

export const ExportRoutes = (ssr) => [
  {
    path: "/:lang?/export/",
    tag: "export",
    type: "export",
    component: ssr ? ExportPage : () => import("../../pages/New/ExportPage/ExportPage.jsx"),
    isAuthRequired: false
  }
];
