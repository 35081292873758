import styled from "@emotion/styled";

export const StyledDescription = styled.p`
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  color: ${(p) => p.theme.colors["neutral-600"]};
`;

export const StyledBoxModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;
