import { StyledExpnadButton } from "./CustomExpandCell.styled";

export const CustomExpandCell = ({ id, open, onClick }) => (
  <StyledExpnadButton
    id={id}
    className={open ? "open" : ""}
    onClick={onClick}
    type="button"
  />
);
