import styled from "@emotion/styled";

export const StyledNodata = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 8px;
  height: 64px;
  color: ${(p) => p.theme.colors["neutral-800"]}
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: 400;
  line-height: 170%;

    ${(p) =>
      p.bordered
        ? `
      border-left: ${p.theme.borders.border};
        border-right: ${p.theme.borders.border};
        border-bottom: ${p.theme.borders.border};
        border-radius: 0 0 12px 12px;
        `
        : ""}
`;
