import { useEffect } from "react";

import "moment/locale/ru";
import "moment/locale/uk";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ReadMore } from "../../../../../components/ui/New/ReadMore/ReadMore";
import { useDispatchedActions, useLangUrlDefault } from "../../../../../hooks";
import { getAllBlogInner } from "../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { Date, DateWrapper, StyledTitle } from "../Blog.styled";

import {
  ImageWrapper,
  Preview,
  StyledContainer,
  StyledLatestTitle
} from "./Latest.styled";
import { LatestItem } from "./LatestItem/LatestItem";

export const Latest = () => {
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { sidebar } = useSelector(getAllBlogInner);
  const preview = sidebar.data?.latest[0] || {};

  const { t } = useTranslation();

  const { getBlogSidebarContent } = useDispatchedActions();

  useEffect(() => {
    getBlogSidebarContent({ languageCode: queryLang });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);

  return (
    <StyledContainer>
      <Preview>
        <ImageWrapper>
          <Link to={`${hrefLang}/new/blog/${preview.alias}`}>
            <picture>
              <source
                srcSet={preview.coverImage || "/img/ui/blog-placeholder.svg"}
                media="(min-width: 993px)"
              />
              <source
                srcSet={
                  preview.coverImageSmall || "/img/ui/blog-placeholder.svg"
                }
                media="(max-width: 992px)"
              />
              <img src={preview.coverImage} alt={preview?.title} />
            </picture>
          </Link>
        </ImageWrapper>
        <Link to={`${hrefLang}/new/blog/${preview.alias}`}>
          <StyledTitle tag="h2" size="sm">
            {preview?.title}
          </StyledTitle>
        </Link>
        <DateWrapper>
          <Date suppressHydrationWarning>
            {preview?.creationDate && (
              <Moment
                format="MMMM DD, YYYY"
                locale={queryLang === "ua" ? "uk" : queryLang}
              >
                {preview.creationDate}
              </Moment>
            )}
          </Date>
          <Link to={`${hrefLang}/new/blog/${preview.alias}`}>
            <ReadMore variant="dark" />
          </Link>
        </DateWrapper>
      </Preview>
      <div>
        <StyledLatestTitle tag="h2" size="sm">
          {t("blogPage.latest")}
        </StyledLatestTitle>
        {sidebar.data?.latest?.slice(1).map((item, i) => (
          <LatestItem data={item} key={i} />
        ))}
      </div>
    </StyledContainer>
  );
};
