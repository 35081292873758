import styled from "@emotion/styled";

export const StyledResponse = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 16px;
  max-width: 100%;

  background-color: ${(p) =>
    p.nestingLevel % 2 === 0
      ? p.theme.colors.white
      : p.theme.colors["background-color"]};

  @media (min-width: ${(p) =>
      p.isDashboard ? p.theme.screenSizes.lgMin : p.theme.screenSizes.xlMin}) {
    padding: 24px;
  }
`;

export const StyledResponseForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > * {
    margin: 0 !important;
  }
`;
