/* eslint-disable react/destructuring-assignment */
import { useEffect, useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Modal, Rating } from "../../";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "../../../../store/reducers/UserReducer/User.selectors";
import {
  ReviewSchema,
  ReviewSchemaWithAuth
} from "../../../../utils/validation";
import { Input } from "../../../forms/Input/Input";
import { Textarea } from "../../../forms/Textarea/Textarea";
import { Button } from "../../../ui/Button/Button";
import { Loader } from "../../../ui/Loader/Loader";
import { Typography } from "../../../ui/Typography/Typography";

import "./ReviewModal.scss";

export const ReviewModal = (props) => {
  // **Props
  const { visible, captchaRef, setTokenCaptcha, isEdit, reviewItem } = props;
  const [queryLang] = useLangUrlDefault();

  const { t } = useTranslation();

  const { captchaConfig } = useSelector(getAllContent);
  const { isUserAuthenticated } = useSelector(getAllUser);
  const { site } = useSelector(getAllProxySite);
  // **Local state
  const [rating, setRating] = useState(isEdit ? reviewItem?.rating : 0);
  const [ratingError, setRatingError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enable, headerName } = captchaConfig.data;

  // **Ref
  const modalRef = useRef();

  // **Dispatch
  const { getLastSiteReviews } = useDispatchedActions();

  // Form
  const methods = useForm({
    resolver: yupResolver(
      isUserAuthenticated
        ? ReviewSchema("forms", t)
        : ReviewSchemaWithAuth("forms", t)
    ),
    defaultValues: reviewItem
  });
  const titleReviewModal = isEdit
    ? t("modals.review.titleEdit")
    : t("modals.review.titleAdd");

  useEffect(() => {
    if (!visible && isEdit) {
      // Reset form for edit
      methods.reset();
      setRating(reviewItem?.rating);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, isEdit, methods]);

  const onSubmit = async (data) => {
    let header = {};

    if (enable) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
    }

    try {
      // Check if rating is choosen
      if (!rating) {
        modalRef.current?.scrollTo({
          top: 0,
          behavior: "smooth"
        });

        setRatingError(true);
        return;
      }

      setIsLoading(true);

      // eslint-disable-next-line no-shadow
      const { name, email, advantages, disadvantages, review, usage, wishes } =
        data;
      const user = !isUserAuthenticated
        ? {
            name,
            email,
            locale: queryLang
          }
        : null;

      const formSummary = {
        attributes: { advantages, disadvantages, usage, wishes },
        ratings: {
          SPEED: rating || 0,
          PRICE: rating || 0,
          RELIABILITY: rating || 0,
          SUPPORT: rating || 0
        }
      };

      // If site edit, add some new values
      if (isEdit) {
        formSummary.id = reviewItem ? reviewItem?.id : null;
        formSummary.review = review;
      } else {
        formSummary.reviewBody = review;
        formSummary.siteId = site.data?.id;
        formSummary.user = user;
      }

      const response = !isEdit
        ? await ApiService.createSiteReview(formSummary, header)
        : await ApiService.updateSiteReview(formSummary);

      if (response && response.status !== 200) {
        throw response;
      }

      // Reset form (except rating)
      methods.reset();

      // Show success message
      toast.success(
        !isEdit
          ? t("notifications.modals.review")
          : t("notifications.modals.reviewEdit")
      );

      // Close modal
      props.cancelHandler(false);

      // Get new last reviews
      getLastSiteReviews();

      if (props.afterSubmitHandler) {
        props.afterSubmitHandler();
      }
    } catch (err) {
      if (err.response.status === 540) {
        methods.reset();
        props.cancelHandler(false);
        toast.error(t("notifications.modals.reviewAlreadySend"));
      } else {
        toast.error(t("notifications.apiError"));
      }
    } finally {
      if (enable) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }

      setRating(0);
      setIsLoading(false);
    }
  };

  const getRating = (ratingValue) => {
    setRating(ratingValue);
    setRatingError(false);
  };

  const onChangeDelUrlOrChar = (evt) => {
    methods.setValue(
      evt.target.name,
      evt.target.value.replace(
        /[^a-zа-яёіїє0-9!,.?()\s]|\b(https?:\/\/\S*\b)/gi,
        ""
      )
    );
  };

  return (
    <Modal ref={modalRef} width={100} {...props}>
      <Typography
        size="large"
        weight="semibold"
        padding="bottom-large"
        position="center"
      >
        {titleReviewModal}
      </Typography>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="review-modal__form"
        >
          <Rating rating={rating} getRating={getRating} isClickable />
          {ratingError && (
            <Typography
              padding="bottom-large"
              position="center"
              style={{ color: "var(--clr-secondary-900)", fontSize: "1.4rem" }}
            >
              {t("modals.review.form.rating")}
            </Typography>
          )}
          <div className="review-modal__form-list">
            {!isUserAuthenticated && (
              <>
                <Input
                  defaultValue={reviewItem?.userName}
                  layout="vertical"
                  label={t("forms.name.label")}
                  name="name"
                  type="text"
                  showError
                />
                <Input
                  defaultValue={reviewItem?.userEmail}
                  layout="vertical"
                  label={t("forms.email.label")}
                  name="email"
                  type="email"
                  showError
                />
              </>
            )}
            <Textarea
              defaultValue={reviewItem?.attributes?.advantages}
              name="advantages"
              label={t("modals.review.form.advantages")}
              layout="vertical"
              onChange={onChangeDelUrlOrChar}
              showError
            />
            <Textarea
              defaultValue={reviewItem?.attributes?.disadvantages}
              name="disadvantages"
              label={t("modals.review.form.disadvantages")}
              layout="vertical"
              onChange={onChangeDelUrlOrChar}
              showError
            />
            <Textarea
              defaultValue={reviewItem?.reviewBody}
              name="review"
              label={t("modals.review.form.review")}
              layout="vertical"
              onChange={onChangeDelUrlOrChar}
              showError
            />
            <Textarea
              defaultValue={reviewItem?.attributes?.usage}
              name="usage"
              label={t("modals.review.form.usage")}
              layout="vertical"
              onChange={onChangeDelUrlOrChar}
              showError
            />
            <Textarea
              defaultValue={reviewItem?.attributes?.wishes}
              name="wishes"
              label={t("modals.review.form.wishes")}
              layout="vertical"
              onChange={onChangeDelUrlOrChar}
              showError
            />
          </div>
          <Button disabled={isLoading} type="solid" position="center">
            {!isLoading ? (
              titleReviewModal
            ) : (
              <Loader
                type="absolute"
                scale={0.4}
                color="var(--clr-default-900)"
              />
            )}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};
