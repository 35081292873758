import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import Switch from "../../../ui/New/Switch/Switch";

import { FormFieldWrapper } from "../FormFieldWrapper/FormFieldWrapper";

export const FormSwitch = ({
  id,
  name,
  rules,
  label,
  className,
  defaultValue,
  showError,
  disabled
}) => {
  // Form
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <FormFieldWrapper
          className={className}
          error={error?.message}
          showError={showError}
        >
          <Switch
            id={id}
            label={label}
            disabled={disabled}
            checked={field.value}
            onChange={field.onChange}
          />
        </FormFieldWrapper>
      )}
    />
  );
};

FormSwitch.PT = {
  id: PT.string,
  name: PT.string.isRequired,
  rules: PT.object,
  label: PT.oneOfType([PT.string, PT.node]).isRequired,
  className: PT.string,
  defaultValue: PT.bool,
  showError: PT.bool,
  disabled: PT.bool
};

FormSwitch.defaultProps = {
  id: null,
  className: "",
  defaultValue: false,
  showError: false,
  disabled: false
};
