import { ApiService } from "../../../services";
import { AllActions } from "../../../store/reducers/AllActions";

import { Hero } from "./Hero/Hero";

const MainPage = () => <Hero />;

export default MainPage;

MainPage.getServerSideState = async (store, params) => {
  await ApiService.getUserIp(params.locale).then((response) => {
    if (response?.data) {
      store.dispatch(AllActions.setUserIp(response.data));
    }
  });
};
