import styled from "@emotion/styled";

export const StyledSubtitle = styled.p`
  width: 100%;
  color: ${(p) =>
    p.white ? p.theme.colors.white : p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) =>
    p.size === "lg" ? p.theme.fontSizes.l : p.theme.fontSizes.m};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: ${(p) => (p.size === "lg" ? 170 : 150)}%;
`;
