/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Export } from "../../../../../components/common/New/Export/Export";
import InfoMessage from "../../../../../components/ui/New/InfoMessage/InfoMessage";
import { ApiService } from "../../../../../services";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllFreeProxy } from "../../../../../store/reducers/FreeProxyReducer/FreeProxy.selectors";
import { getCountryId, isEqual } from "../../../../../utils/helpers";
import { getCityId } from "../../../../../utils/helpers/getCityId.helpers";
import { FreeProxyTable } from "../DataTable/FreeProxyTable/FreeProxyTable";

import { FreeProxyFilter } from "./FreeProxyFilter/FreeProxyFilter";
import {
  ExportItems,
  ExportWrapper,
  FreeProxyListContainer,
  InfoContainer,
  InnerBlock,
  MessageInner,
  MobileTableContainer,
  RestyledPagination,
  RestyledTitle,
  TableWrapper
} from "./FreeProxyList.styled";

export const FreeProxyList = () => {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  // **Redux state
  const { countries } = useSelector(getAllContent);
  const {
    freeProxy,
    allFreeProxy,
    cities: citiesData
  } = useSelector(getAllFreeProxy);
  const [searchParams, setSearchParams] = useSearchParams();

  // **Local state
  const [isLoading, setIsLoading] = useState(true);
  const countryId = getCountryId(
    countries.data,
    searchParams.get("country")
  )?.id;
  const [cities, setCities] = useState(citiesData?.data);
  const cityId = getCityId(cities, searchParams.get("city"))?.id;
  const [allData, setAllData] = useState(allFreeProxy?.data);
  const [data, setData] = useState({
    totalElements: freeProxy?.data?.totalElements || 0,
    totalPages: freeProxy?.data?.totalPages || 0,
    content: freeProxy?.data?.content || [],
    skip: 0
  });
  const [filterParams, setFilterParams] = useState({
    countryIds: countryId ? [countryId] : [],
    cityIds: cityId ? [cityId] : [],
    google:
      searchParams.get("google") === "yes"
        ? "true"
        : searchParams.get("google") === "no"
        ? "false"
        : "",
    type: searchParams.get("type") || "",
    page: 0,
    pageSize: 10
  });
  const [countriesFilter, setCountriesFilter] = useState([]);
  const [values, setValues] = useState({
    country: "",
    city: "",
    type: ""
  });

  const pageChangeHandler = (page) => {
    setFilterParams({ ...filterParams, page: page - 1 });
  };

  const handleSetRowsPerPage = (value) => {
    setFilterParams({ ...filterParams, page: 0, pageSize: value });
  };

  useEffect(() => {
    setIsLoading(true);
    ApiService.getFreeProxy(filterParams)
      .then((response) => {
        if (response && response?.status !== 200) {
          throw response;
        }
        setData({
          ...response.data,
          skip: filterParams.page * filterParams.pageSize
        });
      })
      .catch(() => {
        toast.error(t("notifications.apiError"));
      })
      .finally(() => {
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, cityId]);

  useEffect(() => {
    ApiService.getSities(countryId)
      .then((response) => {
        if (response && response?.status !== 200) {
          throw response;
        }
        setCities(response.data);
      })
      .catch(() => {
        toast.error(t("notifications.apiError"));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  useEffect(() => {
    ApiService.getAllFreeProxy({
      countryIds: filterParams.countryIds,
      cityIds: filterParams.cityIds,
      google: filterParams.google,
      type: filterParams.type
    })
      .then((response) => {
        if (response && response.status !== 200) {
          throw response;
        }
        setAllData(response.data);
      })
      .catch(() => {
        toast.error(t("notifications.apiError"));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams.countryIds, cityId, filterParams.google, filterParams.type]);

  useEffect(() => {
    const prevFilterParams = filterParams;
    const currentFilterParams = {
      page: 0,
      pageSize: filterParams.pageSize,
      countryIds: countryId ? [countryId] : countriesFilter,
      cityIds: cityId ? [cityId] : [],
      type: searchParams.get("type") || "",
      google:
        searchParams.get("google") === "yes"
          ? "true"
          : searchParams.get("google") === "no"
          ? "false"
          : ""
    };
    const isEqualFilter = isEqual(prevFilterParams, currentFilterParams);

    if (!isEqualFilter) {
      setFilterParams({
        ...filterParams,
        ...currentFilterParams
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, countries, countriesFilter, countryId]);

  const defaultValues = {
    country: "",
    city: "",
    type: "",
    google: searchParams.get("google") || ""
  };

  const methods = useForm({ defaultValues });

  useEffect(() => {
    const onSubmit = (data) => {
      const { country, city, type, google } = data;
      const newSearchparams = {
        ...(country?.value && { country: country.value }),
        ...(city?.value && { city: city.value }),
        ...(type?.value && { type: type.value }),
        ...(google && { google })
      };
      setSearchParams(newSearchparams);
    };
    const subscription = methods.watch(methods.handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, searchParams, setSearchParams]);

  const exportData = useMemo(
    () =>
      allData
        .map(
          // eslint-disable-next-line no-useless-escape
          (item) => `<div style=\"padding: 5px;\">${item.ip}:${item.port}</div>`
        )
        .join(" "),
    [allData]
  );

  const txtData = useMemo(
    () => allData?.map((item) => `${item.ip}:${item.port}`),
    [allData]
  );

  const renderTable = (mobile = false) => (
    <TableWrapper mobile={mobile}>
      <ExportWrapper>
        <ExportItems>
          {t("freeProxy.list.itemsFound")} {data?.totalElements}
        </ExportItems>
        <Export
          exportData={exportData}
          id="freeProxyTable"
          tooltip={t("freeProxy.list.tooltip")}
          txtData={txtData}
        />
      </ExportWrapper>
      <FreeProxyTable
        tableData={data.content}
        skip={data.skip}
        isLoading={isLoading}
        countries={countries.data}
        countriesFilter={countriesFilter}
        setCountriesFilter={setCountriesFilter}
        filterParams={filterParams}
      />
      {data.totalElements > 0 && (
        <RestyledPagination
          currentPage={filterParams?.page + 1}
          rowsPerPage={filterParams?.pageSize}
          rowCount={data?.totalElements}
          onChangeRowsPerPage={handleSetRowsPerPage}
          onChangePage={pageChangeHandler}
          useCount
        />
      )}
    </TableWrapper>
  );

  return (
    <>
      <FreeProxyListContainer>
        <InnerBlock>
          <RestyledTitle tag="h2">{t("freeProxy.list.title")}</RestyledTitle>
          <FreeProxyFilter
            defaultValues={defaultValues}
            methods={methods}
            countries={countries.data}
            values={values}
            setValues={setValues}
            cities={cities}
          />
          {renderTable(false)}
        </InnerBlock>
      </FreeProxyListContainer>
      <MobileTableContainer>{renderTable(true)}</MobileTableContainer>
      <InfoContainer>
        <MessageInner>
          <InfoMessage text={t("freeProxy.list.infoMessage")} />
        </MessageInner>
      </InfoContainer>
    </>
  );
};
