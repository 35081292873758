import styled from "@emotion/styled";

export const StyledTable = styled.div`
  position: relative;
  padding-right: 16px;
  padding-top: 16px;
  width: 100%;
  overflow-x: auto;
  border-radius: 12px;
  background: white;

  @media (min-width: ${(props) => props.theme.screenSizes.lgMin}) {
    position: absolute;
    padding-right: 0;
    padding-top: 0;
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  display: table;
`;

export const TableBody = styled.div`
  overflow-y: auto;
  max-height: 462px;
  width: 100%;
`;

export const TableHeadRow = styled.div`
  border-radius: 12px;
  background-color: #f6f7f9;
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 48px;
  min-height: 48px;
`;

export const TableHeadCol = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "auto")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.width)};
  height: 100%;
  padding-left: 24px;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  color: var(--neutral-400);
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  text-transform: uppercase;
`;

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: auto;
  min-height: fit-content;
  padding-top: 4px;
  padding-bottom: 4px;

  &:nth-of-type(even) {
    background-color: rgba(246, 247, 249, 0.6);
  }
`;

export const TableCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  word-break: break-word;
  width: ${(props) => (props.width ? props.width : "auto")};
  max-width: 100%;
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.width)};
  height: 100%;
  padding-left: 24px;
  align-items: center;
`;

export const TableText = styled.span`
  display: flex;
  align-items: center;
  min-height: 48px;
  font-family: ${(p) => p.theme.fontFamily.lato};
  color: ${(props) => props.theme.colors["neutral-800"]};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
`;

export const StyledNoData = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
`;
