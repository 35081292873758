import PT from "prop-types";
import { StyledItem, StyledItemTitle, StyledImage, StyledValue } from "./HttpHeadersCheckItem.styled";

const HttpHeaderCheckItem = ({ title, image, value }) => (
  <StyledItem>
    <StyledImage>
      <img src={image} loading="lazy" alt={title} aria-hidden="true" />
    </StyledImage>
    <div>
      <StyledItemTitle>
        {title}
      </StyledItemTitle>
      <StyledValue>{value}</StyledValue>
    </div>
  </StyledItem>
);

HttpHeaderCheckItem.propTypes = {
  title: PT.string,
  value: PT.string,
  image: PT.node
};

HttpHeaderCheckItem.defaultProps = {
  title: "",
  value: "",
  image: null
};

export default HttpHeaderCheckItem;
