import { SkeletonTheme } from "react-loading-skeleton";

import {
  StyledBox,
  StyledCell,
  StyledSkeleton
} from "./SkeletonTableComponents.styled";

export const SkeletonSiteNameCell = ({ subInfoWidth, width, minWidth }) => (
  <SkeletonTheme inline>
    <StyledCell cellWidth={width} cellMinWIdth={minWidth}>
      <StyledSkeleton width={40} height={40} />
      <StyledBox>
        <StyledSkeleton width={width ? width - 64 : 155} height={16} />
        {subInfoWidth && (
          <StyledSkeleton
            width={subInfoWidth >= width ? width : subInfoWidth}
            height={20}
          />
        )}
      </StyledBox>
    </StyledCell>
  </SkeletonTheme>
);
