import { useTranslation } from "react-i18next";

import {
  animationDnsLeakFix,
  animationDnsLeakMain
} from "../../../../assets/animations";
import {
  dnsLeakTestHeroImageMobile,
  fingerprintFaqImage,
  fingerprintFaqImage2x
} from "../../../../assets/img";
import SeoSection from "../../../../components/common/New/SeoSection/SeoSection";
import { Rive } from "../../../../components/ui/New/Rive/Rive";
import { $api } from "../../../../services/AxiosInstance";
import { AllActions } from "../../../../store/reducers/AllActions";
import { faqToolPortScanner } from "../../../../utils/constants/questionsAndAnswers";

import { DnsLeakTest } from "./DnsLeakTest/DnsLeakTest";
import { DnsLeakTestInfo } from "./DnsLeakTestInfo/DnsLeakTestInfo";
import {
  RestuledHowToFix,
  RestyledFAQ,
  RestyledMainSection
} from "./DnsLeakTestPage.styled";

const stepsData = [
  "To go to the site you want, you enter its name in the browser bar, or follow the link",
  "When you initiate a test, the tool sends a series of DNS requests from your device and records the responses.",
  "You can view the results to determine if any of the requests are sent to unintended DNS servers. If you’re connected to a VPN or proxy and at least one of the servers in the results belongs to your internet provider, your connection likely has a DNS leak."
];

const DnsLeakTestPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <RestyledMainSection
        title={t("dnsLeak.main.title")}
        subtitle={t("dnsLeak.main.subtitle")}
        button={false}
        images={{
          mobile1x: dnsLeakTestHeroImageMobile,
          mobile2x: dnsLeakTestHeroImageMobile
        }}
        animation={
          <Rive
            src={animationDnsLeakMain}
            autoPlay
            top={-128}
            right={-47}
            bottom={-123}
            left={-47}
          />
        }
      />
      <DnsLeakTest />
      <DnsLeakTestInfo />
      <RestuledHowToFix
        title={t("dnsLeak.howFixDns.title")}
        imageDesktop="img/dns-leak-test/dns-leak-fix-image.svg"
        imageMobile="img/dns-leak-test/dns-leak-fix-image-mobile.svg"
        data={stepsData}
        animation={
          <Rive
            src={animationDnsLeakFix}
            autoPlay
            top={-53}
            right={-70}
            bottom={-53}
            left={-37}
          />
        }
      />
      <RestyledFAQ
        list={faqToolPortScanner}
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        tools
      />
      <SeoSection />
    </>
  );
};

export default DnsLeakTestPage;

DnsLeakTestPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });

  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
};
