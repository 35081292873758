import styled from "@emotion/styled";

import Container from "../../../../../components/common/New/Container/Container";
import Search from "../../../../../components/ui/New/Search/Search";

export const StyledContainerSearch = styled(Container)`
  width: 100%;
  margin-bottom: 24px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
    padding: 0;
    padding-top: 2px !important;
    max-width: 248px;
    min-width: 248px;
  }
`;

export const StyledSearch = styled(Search)`
  height: 48px !important;
`;
