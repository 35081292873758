import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Progress } from "../../../../../../components/common/New/Progress/Progress";
import { FormInput } from "../../../../../../components/forms/New/FormInput/FormInput";
import { useUserIp } from "../../../../../../hooks/useUserIp";
import { TraicingIpSchema } from "../../../../../../utils/validation/traicingIp.validation";

import {
  StyledAdditionalText,
  StyledButton,
  StyledContainer,
  StyledForm,
  StyledFormSelect,
  StyledInfoMessage,
  StyledInputWrapper,
  StyledResults,
  StyledResultsItem,
  StyledResultsList,
  StyledTitle
} from "./TestPort.styled";

const mockData = [
  "IP/ Domain: 185.19.6.116",
  "Not shown: 0 closed ports",
  "109 pop2 - closed",
  "110 pop3 - closed",
  "115 sftp - closed",
  "118 sqlserv - closed",
  "119 nntp - closed",
  "IP/ Domain: 185.19.6.116",
  "Not shown: 0 closed ports",
  "109 pop2 - closed",
  "110 pop3 - closed",
  "115 sftp - closed",
  "118 sqlserv - closed",
  "119 nntp - closed"
];

export const TestPort = () => {
  const { t } = useTranslation();

  const userIp = useUserIp();

  const methods = useForm({
    resolver: yupResolver(
      TraicingIpSchema(t("portScanner", { returnObjects: true }))
    )
  });

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const onSubmit = (data) => {
    try {
      setIsLoading(true);

      methods.reset();

      setResult(mockData);

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      // setIsLoading(false);
    }
  };

  const portsOptions = [
    {
      label: t("portScanner.form.popularPorts"),
      value: "popularPorts"
    },
    {
      label: t("portScanner.form.serverPorts"),
      value: "serverPorts"
    },
    {
      label: t("portScanner.form.gamePorts"),
      value: "gamePorts"
    },
    {
      label: t("portScanner.form.applicationPorts"),
      value: "applicationPorts"
    },
    {
      label: t("portScanner.form.p2pPorts"),
      value: "p2pPorts"
    }
  ];

  return (
    <StyledContainer>
      <StyledTitle tag="h2">{t("portScanner.testPort")}</StyledTitle>
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <StyledInputWrapper>
            <FormInput
              name="ipAddress"
              type="text"
              placeholder={t("tracingIp.form.enterIP")}
              label={t("portScanner.form.inputLabel")}
              showError
            />
            <StyledAdditionalText
              type="button"
              onClick={() => {
                methods.setValue("ipAddress", userIp?.ipAddress);
                methods.clearErrors();
              }}
            >
              {t("portScanner.form.pasteIP")}
            </StyledAdditionalText>
          </StyledInputWrapper>
          <StyledFormSelect
            name="type"
            id="type"
            placeholder="Popular ports"
            label={t("portScanner.form.selectLabel")}
            showError
            defaultValue="popularPorts"
            returnObject
            options={portsOptions}
          />
          <StyledButton
            type="submit"
            fullWidth
            loading={isLoading}
            iconLeft={isLoading ? "loading" : "scan"}
          >
            {isLoading
              ? t("portScanner.form.loading")
              : t("portScanner.form.scan")}
          </StyledButton>
        </StyledForm>
      </FormProvider>
      <StyledInfoMessage>
        <img src="img/icons/information.svg" alt="Info icon" />
        <span>{t("portScanner.form.infoMessage")}</span>
      </StyledInfoMessage>
      <StyledTitle tag="h2">{t("portScanner.checkResults")}</StyledTitle>
      <StyledResults isLoading={isLoading}>
        {isLoading ? (
          <Progress isLoading={isLoading} />
        ) : (
          <StyledResultsList>
            {result
              ? result?.map((el, index) => (
                  <StyledResultsItem key={index}>{el}</StyledResultsItem>
                ))
              : null}
          </StyledResultsList>
        )}
      </StyledResults>
    </StyledContainer>
  );
};
