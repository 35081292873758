import BlogInnerPage from "../../pages/New/BlogInnerPage/BlogInnerPage";
import BlogPage from "../../pages/New/BlogPage/BlogPage";
import MainPage from "../../pages/New/MainPage/MainPage";
import ProxyPage from "../../pages/New/ProxyPage/ProxyPage";
import ServicePage from "../../pages/New/ServicePage/ServicePage";

export const AppRoutes = (ssr = false) => [
  {
    path: "/:lang?/new/",
    tag: "main",
    type: "app",
    component: ssr ? MainPage : () => import("../../pages/New/MainPage/MainPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/blog",
    tag: "blog",
    type: "app",
    component: ssr ? BlogPage : () => import("../../pages/New/BlogPage/BlogPage"),
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/blog/:alias",
    tag: "bloginner",
    type: "app",
    component: ssr ? BlogInnerPage : () => import("../../pages/New/BlogInnerPage/BlogInnerPage"),
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/proxy",
    tag: "proxy",
    type: "app",
    component: ssr ? ProxyPage : () => import("../../pages/New/ProxyPage/ProxyPage"),
    isAuthRequired: false,
    isSsr: true
  },

  {
    path: "/:lang?/new/proxy/:alias",
    tag: "proxy-site",
    type: "app",
    component: ssr ? ServicePage : () => import("../../pages/New/ServicePage/ServicePage"),
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/proxy/:alias/promocodes",
    tag: "proxy-site-promo",
    type: "app",
    component: ssr ? ServicePage : () => import("../../pages/New/ServicePage/ServicePage"),
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  }
];
