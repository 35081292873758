import styled from "@emotion/styled";

export const LatestItemWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const LatestItemTitle = styled.p`
  color: ${(props) => props.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
`;
