import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AllActions } from "../../../store/reducers/AllActions";
import { useDispatchedActions, useLangUrlDefault } from "../../../hooks";
import { ApiService } from "../../../services";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { getAllSite } from "../../../store/reducers/SiteReducer/Site.selectors";
import {
  getCurrency,
  getDomainTitle,
  getRentPeriod,
  transformInitialValue,
  transformNetworks
} from "../../../utils/helpers";
import { Loader } from "../../ui/Loader/Loader";
import { Typography } from "../../ui/Typography/Typography";

import { Form } from "./Form/Form";
import { Validation } from "./Validation/Validation";

import "./Proxy.scss";

export const Proxy = ({ title = "", isEdit = false }) => {
  const { id } = useParams();
  const [queryLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Redux state
  const { countries, goals, currencies, rentPeriods, socials } =
    useSelector(getAllContent);
  const { previousLang } = useSelector(getAllSite);

  // **Local state
  const [allowToRenderForm, setAllowToRenderForm] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    api: t("yesno", { returnObjects: true })[0],
    freeTest: t("yesno", { returnObjects: true })[0],
    refunds: t("yesno", { returnObjects: true })[0],
    replacementPossibility: t("yesno", { returnObjects: true })[0],
    affiliateProgram: t("yesno", { returnObjects: true })[0],
    individual: t("yesno", { returnObjects: true })[0],
    currencyId: getCurrency(currencies.data, "USD"),
    socialNetworks: socials.data.reduce((target, key) => {
      target[key.code] = [" "];
      return target;
    }, {})
  });

  const [domainName, setDomainName] = useState("");

  // Dispatch
  const { getAllGoals, setPreviousLang } = useDispatchedActions();

  const fetchSiteById = useCallback(
    // eslint-disable-next-line no-shadow
    async (currencies, countries, goals) => {
      try {
        const response = await ApiService.getProxySiteById(id);

        if (response && response.status !== 200) {
          throw response;
        }

        // TRANSFORM DATA TO NECCESSARY FORMAT
        response.data.api = transformInitialValue(
          t("yesno", { returnObjects: true }),
          response.data.api
        );
        response.data.freeTest = transformInitialValue(
          t("yesno", { returnObjects: true }),
          response.data.freeTest
        );
        response.data.affiliateProgram = transformInitialValue(
          t("yesno", { returnObjects: true }),
          response.data.affiliateProgram
        );
        response.data.refunds = transformInitialValue(
          t("yesno", { returnObjects: true }),
          response.data.refunds
        );
        response.data.replacementPossibility = transformInitialValue(
          t("yesno", { returnObjects: true }),
          response.data.replacementPossibility
        );
        response.data.individual = transformInitialValue(
          t("yesno", { returnObjects: true }),
          response.data.individual
        );
        response.data.minRentPeriod = getRentPeriod(
          response.data.minRentPeriod,
          queryLang,
          t("times", { returnObjects: true })
        );
        response.data.socialNetworks = transformNetworks(
          response.data.socialNetworks,
          socials.data,
          false,
          true
        );
        response.data.currencyId = getCurrency(
          currencies,
          response.data.currencyId,
          true
        );

        if (countries.length === response.data.countries.length) {
          response.data.countries = [" ", ...response.data.countries];
        }

        if (goals.length === response.data.purposeUse.length) {
          response.data.purposeUse = [" ", ...response.data.purposeUse];
        }

        setDefaultValues(response.data);
        setDomainName(response.data?.name);
      } catch (err) {
        toast.error(t("notifications.apiError"));
      } finally {
        setAllowToRenderForm(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const getDomainName = (val) => {
    const domainName = getDomainTitle(val);

    if (domainName) {
      setDomainName(domainName[0]);
    } else {
      setDomainName("");
    }
  };

  useEffect(() => {
    if (!goals.isDataLoaded || previousLang !== queryLang) {
      getAllGoals(queryLang);
      setPreviousLang(queryLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goals.isDataLoaded, queryLang]);

  useEffect(() => {
    if (
      countries.isDataLoaded &&
      goals.isDataLoaded &&
      currencies.isDataLoaded &&
      rentPeriods.isDataLoaded
    ) {
      // Check for render fork
      if (!isEdit) {
        setDefaultValues({
          ...defaultValues,
          currencyId: getCurrency(currencies.data, "USD")
        });
        setAllowToRenderForm(true);
      } else {
        // Fetch site content
        fetchSiteById(currencies.data, countries.data, goals.data);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countries.isDataLoaded,
    goals.isDataLoaded,
    currencies.isDataLoaded,
    rentPeriods.isDataLoaded
  ]);

  return (
    <div className="cabinet__content-container">
      {isEdit &&
        Object.keys(defaultValues).includes("valid") &&
        !defaultValues.valid && (
          <Validation
            domainName={domainName}
            validationToken={defaultValues?.validationToken}
            validationFileUrl={defaultValues?.validationFileUrl}
            validationMetaTag={defaultValues?.validationMetaTag}
            validationNextDate={defaultValues?.validationNextDate}
          />
        )}
      <div className="proxy-form">
        <Typography Tag="h2" size="middle" weight="semibold">
          {`${title} ${domainName ? `«${domainName}»` : ""}`}
        </Typography>
        {allowToRenderForm ? (
          <Form
            defaultValues={defaultValues}
            setDefaultValues={setDefaultValues}
            isEdit={isEdit}
            domainName={domainName}
            getDomainName={getDomainName}
          />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

Proxy.getServerSideState = async (store, params) => {
  await ApiService.getAllGoals(params.locale).then((response) => {
    if (response?.data) {
      store.dispatch(AllActions.setGoals(response.data));
    }
  });
};
