import styled from "@emotion/styled";

export const StyledProxyTypesBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
