import { useState } from "react";

import { useTranslation } from "react-i18next";

import { SectionSearch } from "../../../../../components/common/New/SectionSearch/SectionSearch";
import { mockTableData } from "../../../../../utils/constants/mockTableData";

import { AttributesTable } from "./AttributesTable/AttributesTable";
import { FingerprintContainer, FingerprintSection } from "./Fingerprint.styled";

export const Fingerprint = () => {
  const { t } = useTranslation();

  const [http, setHttp] = useState("");
  const [js, setJs] = useState("");

  return (
    <FingerprintSection>
      <FingerprintContainer>
        <SectionSearch
          title={t("fingerprint.httpAttributes")}
          placeholder={t("fingerprint.searchAttributes")}
          value={http}
          onChange={(value) => setHttp(value)}
        />
        <div id="httpAttributes" />
        <AttributesTable data={mockTableData} margin="0 0 88px 0" />
        <SectionSearch
          title={t("fingerprint.javaScriptAttributes")}
          placeholder={t("fingerprint.searchAttributes")}
          value={js}
          onChange={(value) => setJs(value)}
        />
        <AttributesTable data={mockTableData} />
      </FingerprintContainer>
    </FingerprintSection>
  );
};
