import styled from "@emotion/styled";

import Annotation from "../../../../ui/New/Annotation/Annotation";

export const StyledAnnotationCell = styled(Annotation)`
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: 600;
  line-height: 140%;
`;
