import { forwardRef } from "react";

import { Modal as BaseModal } from "@mui/base/Modal";
import PT from "prop-types";

import Icon from "../Icon/Icon";

import {
  StyledBackdrop,
  StyledModal,
  StyledModalBody,
  StyledModalClose,
  StyledModalContent,
  StyledModalTitle
} from "./Modal.styled";

const Backdrop = forwardRef((props, ref) => {
  // eslint-disable-next-line no-unused-vars
  const { open, className, ownerState, ...other } = props;
  return <StyledBackdrop ref={ref} {...other} />;
});

Backdrop.propTypes = {
  className: PT.string.isRequired,
  open: PT.bool
};

export const Modal = ({
  className,
  classNameBody,
  open,
  title,
  size,
  setOpen,
  minWidth,
  maxWidth,
  children,
  mobilePosition
}) => {
  const handleClose = () => setOpen?.(false);

  return (
    <BaseModal open={open} onClose={handleClose} slots={{ backdrop: Backdrop }}>
      <StyledModal
        className={className}
        minWidth={minWidth}
        maxWidth={maxWidth}
        size={size}
        mobilePosition={mobilePosition}
      >
        <StyledModalBody>
          <StyledModalClose
            onClick={handleClose}
            type="button"
            className="modal-close-btn"
          >
            <Icon name="closeModal" />
          </StyledModalClose>
          {title && (
            <StyledModalTitle className="md_title">{title}</StyledModalTitle>
          )}
          <StyledModalContent className={`modal-body ${classNameBody}`}>
            {children}
          </StyledModalContent>
        </StyledModalBody>
      </StyledModal>
    </BaseModal>
  );
};

Modal.propTypes = {
  children: PT.node.isRequired,
  open: PT.bool.isRequired,
  setOpen: PT.func.isRequired,
  className: PT.string,
  classNameBody: PT.string,
  size: PT.oneOf(["sm", "md", "lg"]),
  title: PT.string,
  minWidth: PT.number,
  maxWidth: PT.number,
  mobilePosition: PT.oneOf(["center", "bottom"])
};

Modal.defaultProps = {
  className: "",
  classNameBody: "",
  size: "md",
  title: "",
  minWidth: 0,
  maxWidth: 0,
  mobilePosition: "center"
};

export default Modal;
