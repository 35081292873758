import styled from "@emotion/styled";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;

  h2 {
    margin-bottom: 32px;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 48px;
    h2 {
      max-width: 760px;
      margin-bottom: 0px;
    }
  }

  div {
    width: 100%;

    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      max-width: 248px;
    }
  }
`;
