import PT from "prop-types";
import { useTranslation } from "react-i18next";

import {
  StyledContainer,
  StyledRelated,
  StyledRelatedList,
  StyledTitle
} from "./Related.styled";
import { RelatedItem } from "./RelatedItem/RelatedItem";

export const Related = ({ data }) => {
  // **Props
  const { t } = useTranslation();

  return (
    <StyledRelated>
      <StyledContainer>
        <div>
          <StyledTitle tag="h2">
            {t("blogInnerPage.articleBody.relatedTitle")}
          </StyledTitle>
          <StyledRelatedList>
            {data?.slice(0, 3)?.map((post) => (
              <RelatedItem key={post?.id} data={post} />
            ))}
          </StyledRelatedList>
        </div>
      </StyledContainer>
    </StyledRelated>
  );
};

Related.propTypes = {
  data: PT.arrayOf(
    PT.shape({
      viewCount: PT.number
    })
  )
};
