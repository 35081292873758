import styled from "@emotion/styled";
import { Tooltip } from "react-tooltip";

export const StyledTooltip = styled(Tooltip)`
  z-index: 1000 !important;
  border-radius: 8px !important;
  background: ${(p) => p.theme.colors.white} !important;
  box-shadow: 0px 0px 11px 0px #878cbd38;
  color: ${(p) => p.theme.colors["neutral-500"]} !important;
  text-align: center;
  font-family: ${(p) => p.theme.fontFamily.lato} !important;
  font-size: ${(p) => p.theme.fontSizes.xxs} !important;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 12px 8px !important;
  max-width: ${(p) => p.maxWidth || 250}px;
  white-space: wrap;
  word-break: break-word;
  opacity: 1 !important;

  & .react-tooltip-arrow {
    border-radius: 2px;
  }
`;
