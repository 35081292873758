import styled from "@emotion/styled";

export const StyledProxyTypes = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  background: ${(p) => p.theme.colors["background-color"]};
`;

export const StyledProxyTypesList = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;

export const StyledProxyTypesItem = styled.li`
  display: flex;
  align-items: center;
  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: 600;
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-family: ${(p) => p.theme.fontFamily.lato};
  line-height: 130%;
  color: ${(p) => p.theme.colors["neutral-800"]};

  &::before {
    content: "";
    display: inline-block;
    flex: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(p) => p.theme.colors["main-600"]};
    margin-right: 8px;
  }
`;
