import PT from "prop-types";

import {
  StyledAnimation,
  StyledAnimationWrapper,
  StyledContent,
  StyledDescription,
  StyledImageWrapper,
  StyledStep,
  StyledTitle,
  StyledWrapper
} from "./PortScannerStep.styled";

export const PortScannerStep = ({
  title,
  image,
  animation,
  description,
  index
}) => (
  <StyledWrapper>
    <StyledContent>
      <StyledTitle tag="h2">{title}</StyledTitle>
      <StyledStep>{`Step ${index}`}</StyledStep>
      <StyledDescription>{description}</StyledDescription>
    </StyledContent>
    {animation && (
      <StyledAnimationWrapper>
        <StyledAnimation
          src={animation}
          autoPlay
          stateMachines="State Machine 1"
        />
      </StyledAnimationWrapper>
    )}
    <StyledImageWrapper mobile={animation}>
      <img
        src={image}
        loading="lazy"
        alt={`${title} - ${index}`}
        aria-hidden="true"
      />
    </StyledImageWrapper>
  </StyledWrapper>
);

PortScannerStep.propTypes = {
  title: PT.string,
  description: PT.string,
  image: PT.node
};

PortScannerStep.defaultProps = {
  title: "",
  description: "",
  image: null
};
