export const makeWordHighlight = (title, highlightWord) => {
  const currentTitle = title?.trim() || "";
  if (!currentTitle) {
    return;
  }
  const word = highlightWord?.trim() || "";

  return word
    ? currentTitle.replace(word, `<span>${word}</span>`)
    : currentTitle;
};

export const getHighlightedWord = (highlightedWord) =>
  highlightedWord?.replace("проксі", "").replace("прокси", "").trim();
