import { useTranslation } from "react-i18next";

import {
  animationAnonymityChecker,
  animationAnonymityInfo
} from "../../../../../assets/animations";
import { Rive } from "../../../../../components/ui/New/Rive/Rive";

import {
  AnimationWrapper,
  ContentWrapper,
  Description,
  MobileImage,
  MobilePicture,
  MyAnonymitySection,
  RestyledContainer,
  RestyledTitle
} from "./MyAnonymityInfo.styled";

export const MyAnonymityInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <MyAnonymitySection variant="light">
        <RestyledContainer variant="light">
          <AnimationWrapper variant="light">
            <Rive
              src={animationAnonymityInfo}
              autoPlay
              top={-78}
              left={-80}
              bottom={-78}
              right={-80}
            />
          </AnimationWrapper>
          <MobilePicture>
            <source srcSet="img/my-anonymity/my-anonymity-info-image-mobile.svg" />
            <MobileImage
              src="img/my-anonymity/my-anonymity-info-image-mobile.svg"
              alt={t("myAnonymity.whatIsAnonymity.title")}
            />
          </MobilePicture>
          <ContentWrapper variant="light">
            <RestyledTitle tag="h2">
              {t("myAnonymity.whatIsAnonymity.title")}
            </RestyledTitle>
            <Description>
              {t("myAnonymity.whatIsAnonymity.description")}
            </Description>
          </ContentWrapper>
        </RestyledContainer>
      </MyAnonymitySection>
      <MyAnonymitySection>
        <RestyledContainer>
          <ContentWrapper>
            <RestyledTitle tag="h2">
              {t("myAnonymity.whatDoesAnonymity.title")}
            </RestyledTitle>
            <Description>
              {t("myAnonymity.whatDoesAnonymity.description")}
            </Description>
          </ContentWrapper>
          <AnimationWrapper>
            <Rive
              src={animationAnonymityChecker}
              autoPlay
              top={-75}
              right={-132}
              bottom={-75}
              left={-83}
            />
          </AnimationWrapper>
          <MobilePicture>
            <source srcSet="img/my-anonymity/my-anonymity-info-image-mobile1.svg" />
            <MobileImage
              src="img/my-anonymity/my-anonymity-info-image-mobile1.svg"
              alt={t("myAnonymity.whatDoesAnonymity.title")}
            />
          </MobilePicture>
        </RestyledContainer>
      </MyAnonymitySection>
    </>
  );
};
