import styled from "@emotion/styled";

import { Subtitle } from "../../../ui/New/Subtitle/Subtitle";

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 32px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    flex-direction: row;
    justify-content: space-between;
    gap: 64px;
    margin-bottom: 48px;
  }
`;

export const RestyledSubtitle = styled(Subtitle)`
  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    max-width: 300px;
    text-align: right;
  }
`;
