import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { SectionHead } from "../../../../../../components/common/New/SectionHead/SectionHead";

export const ProxyContentTop = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get("fpt") || "IPv4";

  return (
    <SectionHead
      title={t(`mainPage.proxy.titles.${activeTab}`)}
      description={t("mainPage.proxy.description")}
    />
  );
};
