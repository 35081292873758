import styled from "@emotion/styled";

import Modal from "../../../../ui/New/Modal/Modal";

export const RestyledModal = styled(Modal)`
  width: 100%;
  padding-right: 8px !important;
  max-height: 90vh;

  & .modal-body {
    height: 100%;
    overflow-y: auto !important;
    padding: 0 8px 0 0;
  }
  .md_title {
    margin-top: 4px;
    margin-bottom: 27px;
  }
  & .modal-close-btn {
    right: 8px;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-right: 16px !important;

    & .modal-body {
      padding: 0 16px 0 0 !important;
    }

    & .modal-close-btn {
      right: 16px;
    }
    .md_title {
      margin-top: 0;
      margin-bottom: 32px;
    }
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .review_text {
    & textarea {
      min-height: 140px !important;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > * {
    margin: 0 !important;
  }

  & textarea {
    min-height: 90px !important;
    resize: none !important;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }
`;

export const LabelStyled = styled.span`
  display: inline-block;
  color: ${(p) => p.theme.colors["neutral-800"]};

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 140%;
  text-transform: uppercase;
  margin-bottom: ${(p) => (p.noMargin ? 0 : "7px")};
`;

export const RatingBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: #f6f7f980;
`;
