import styled from "@emotion/styled";

export const StyledAddReviewContainer = styled.div`
  position: relative;
  display: ${(p) => (p.active ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    border-radius: 12px 12px 0 0;
    padding: 24px;
    box-shadow: ${(p) => p.theme.shadows.addReviewCounter};
    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${(p) => p.theme.colors.white};
      border-radius: 12px 12px 0 0;
    }
  }
`;

export const StyledAddReviewContant = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 12px;
  padding: 24px;
  gap: 16px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows.addReviewCounter};
  width: 100%;

  & > button {
    @media (max-width: 540px) {
      flex: 1 0 fit-content;
    }
    margin-left: auto;
  }
`;
