import styled from "@emotion/styled";
import { ToastContainer } from "react-toastify";

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    position: relative;

    border-radius: 8px !important;
    background: #fff !important;
    box-shadow: 0px 7px 21px 0px rgba(135, 140, 189, 0.23) !important;
    padding: 16px 24px 16px 20px !important;

    &.Toastify__toast--success {
      background-image: linear-gradient(
        90deg,
        #8fc846 0 4px,
        #fff 4px 100%
      ) !important;

      & .Toastify__toast-icon {
        color: #8fc846;
      }
    }

    &.Toastify__toast--error {
      background-image: linear-gradient(
        90deg,
        #ff5c5c 0 4px,
        #fff 4px 100%
      ) !important;

      & .Toastify__toast-icon {
        color: #ff5c5c;
      }
    }

    &.Toastify__toast--warning {
      background-image: linear-gradient(
        90deg,
        #f2994a 0 4px,
        #fff 4px 100%
      ) !important;

      & .Toastify__toast-icon {
        color: #f2994a;
      }
    }

    &.Toastify__toast--info {
      background-image: linear-gradient(
        90deg,
        #2d8dff 0 4px,
        #fff 4px 100%
      ) !important;

      & .Toastify__toast-icon {
        color: #2d8dff;
      }
    }
  }
  .Toastify__toast-body {
    display: flex;
    align-items: flex-start !important;
    padding: 0 !important;
    color: #4c5464;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 139%;

    & .Toastify__toast-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
`;
