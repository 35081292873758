import { useEffect, useRef, useState } from "react";

import Cropper from "cropperjs";
import { useTranslation } from "react-i18next";

import Button from "../../../../../components/ui/New/Button/Button";

import { useDebouncedFunction } from "../../../../../utils/helpers";

import { Loader } from "../../../../ui/Loader/Loader";

import {
  ActionsBlock,
  CropperActionBlock,
  CropperImageBlock,
  CropperPreviewBlock,
  CropperPreviewItem,
  ModalBody,
  RestyledModal
} from "./CropperImg.styled";

// const ASPECT_RATIOS = [23 / 7, 23 / 13];
const ASPECT_RATIOS = [32 / 32];
const DEBOUNCE_DELAY = 1000;

export const CropperImg = ({
  isModalOpened,
  defaultImage,
  fileObject,
  setCroppedFileObjects,
  onUpload,
  cancelModalHandler
}) => {
  const cropperImageRef = useRef(null);
  const cropperOutputImageRefs = useRef([]);

  const { t } = useTranslation();

  // **Local state
  const [isCropping, setIsCropping] = useState(false);
  const [mainCropper, setMainCropper] = useState({});
  const [index, setIndex] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const [croppedFiles, setCroppedFiles] = useState([]);

  const cropperPreview = (index, cropper) => {
    if (index >= ASPECT_RATIOS.length) {
      setIsCropping(false);
      setIndex(0);

      return;
    }

    const outputImage = cropperOutputImageRefs.current[index];

    if (outputImage) {
      outputImage.src = cropper.getCroppedCanvas().toDataURL("image/png");
      cropper.getCroppedCanvas().toBlob((blob) => {
        setCroppedFiles((prevState) => [
          ...prevState,
          new File([blob], fileObject.name, { type: blob?.type })
        ]);
      });
      const i = index + 1;

      const newCropper = new Cropper(outputImage, {
        aspectRatio: ASPECT_RATIOS[i],
        viewMode: 3,
        autoCropArea: 1,
        ready() {
          cropperPreview(i, newCropper);
          newCropper.destroy();
        }
      });
    } else {
      return null;
    }
  };

  const cropperHandle = (index, mainCropper) => {
    setIsCropping(true);
    setCroppedFiles([]);
    cropperPreview(index, mainCropper);
  };

  const debouncedCropperHandle = useDebouncedFunction(
    cropperHandle,
    DEBOUNCE_DELAY
  );

  useEffect(() => {
    if (isModalOpened) {
      setIsMounted(true);
    } else {
      setIsMounted(false);
    }
  }, [isModalOpened]);

  useEffect(() => {
    if (isModalOpened && cropperImageRef?.current) {
      setIsCropping(true);
      setCroppedFiles([]);
      let previewReady = false;
      const image = cropperImageRef.current;
      setMainCropper(
        new Cropper(image, {
          aspectRatio: ASPECT_RATIOS[index],
          viewMode: 0,
          autoCropArea: 1,
          ready() {
            cropperPreview(index, this.cropper);
            previewReady = true;
          },
          crop() {
            if (!previewReady) {
              return;
            }
            debouncedCropperHandle(index, this.cropper);
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  const saveCropHandle = () => {
    setCroppedFileObjects(croppedFiles);
    onUpload(croppedFiles);
    cancelModalHandler();
  };

  return (
    <RestyledModal
      open={isModalOpened}
      setOpen={cancelModalHandler}
      maxWidth={900}
    >
      <ModalBody>
        <CropperImageBlock>
          <img
            ref={cropperImageRef}
            src={
              fileObject && typeof fileObject !== "string"
                ? URL?.createObjectURL(fileObject)
                : defaultImage
            }
            alt="Cropper image"
          />
        </CropperImageBlock>
        <CropperActionBlock>
          <CropperPreviewBlock>
            {ASPECT_RATIOS.map((_, i) => (
              <CropperPreviewItem key={`cropper-preview-item-${i}`}>
                {isCropping && <Loader type="absolute" scale={0.5} noPadding />}

                <img
                  ref={(el) => {
                    cropperOutputImageRefs.current[i] = el;
                    return null;
                  }}
                  alt={`Cropper output image ${i}`}
                />
              </CropperPreviewItem>
            ))}
          </CropperPreviewBlock>
          <ActionsBlock>
            <Button onClick={saveCropHandle}>
              {t("proxy.logotype.modal.btn.save")}
            </Button>
            <Button variant={"secondary"} onClick={() => mainCropper.reset()}>
              {t("proxy.logotype.modal.btn.reset")}
            </Button>
          </ActionsBlock>
        </CropperActionBlock>
      </ModalBody>
    </RestyledModal>
  );
};
