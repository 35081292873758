import styled from "@emotion/styled";

export const StyledSiteNameCell = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 12px;

  ${(p) =>
    p.propMinWidth ? `min-width: ${p.propMinWidth}px; max-width: 100%;` : ""}
  ${(p) =>
    p.propWidth
      ? `min-width: ${p.propWidth}px; max-width: ${p.propWidth}px;`
      : ""}
`;

export const StyledSiteNameCellImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  flex: none;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors["background-color-image"]};
`;

export const StyledNameCellImage = styled.img`
  width: 24px;
  height: 24px;
  font-size: 0;
  object-fit: cover;
  flex: none;
`;

export const StyledSiteNameCellInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 40px - 12px);
  gap: 4px;
`;

export const StyledSiteNameCellTitle = styled.p`
  margin: 0;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-weight: 600;
  line-height: 125%;
  width: fit-content;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  color: ${(p) => p.theme.colors["dark-700"]};
`;

export const StyledSiteNameCellSubInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
