import { useMemo } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { Export } from "../../../../../../components/common/New/Export/Export";
import {
  StyledNoData,
  StyledTable,
  TableBody,
  TableCol,
  TableHeadCol,
  TableHeadRow,
  TableRow,
  TableText,
  TableWrapper
} from "../../../../../../components/common/New/Table/ToolTable.styled";
import { Title } from "../../../../../../components/ui/New/Title/Title";

import { StyledTitleWrapper } from "./PortsTable.styled";

export const PortsTable = ({ data, isLoading }) => {
  const { t } = useTranslation();

  const exportData = useMemo(
    () =>
      data.map((item) => `<div>${item.port}:${item.protocol}</div>`).join(" "),
    [data]
  );

  const txtData = useMemo(
    () => data.map((item) => `${item.port}:${item.protocol}`),
    [data]
  );

  return (
    <div>
      <StyledTitleWrapper>
        <Title tag="h2">{t("portScanner.portChecked.title")}</Title>
        <Export
          tooltip={t("portScanner.portChecked.tooltip")}
          id="portChecked"
          txtData={txtData}
          exportData={exportData}
        />
      </StyledTitleWrapper>
      <StyledTable>
        <TableWrapper>
          <TableHeadRow>
            <TableHeadCol width="88px" justifyContent="center">
              №
            </TableHeadCol>
            <TableHeadCol width="265px">
              {t("portScanner.portChecked.port")}
            </TableHeadCol>
            <TableHeadCol width="180px">
              {t("portScanner.portChecked.protocol")}
            </TableHeadCol>
            <TableHeadCol width="570px">
              {t("portScanner.portChecked.description")}
            </TableHeadCol>
          </TableHeadRow>
          <TableBody maxHeight="448px">
            {!isLoading && data.length === 0 ? (
              <StyledNoData>
                {t("proxyPage.content.table.noDataComponent")}
              </StyledNoData>
            ) : (
              data.map((item, index) => (
                <TableRow key={index} height="48px">
                  <TableCol width="88px" justifyContent="center">
                    <TableText justifyContent="center">
                      {index < 9 ? `0${index + 1}.` : `${index + 1}.`}
                    </TableText>
                  </TableCol>
                  <TableCol width="265px">
                    <TableText>{item?.port}</TableText>
                  </TableCol>
                  <TableCol width="180px">
                    <TableText>{item?.protocol}</TableText>
                  </TableCol>
                  <TableCol width="570px">
                    <TableText>{item?.description}</TableText>
                  </TableCol>
                </TableRow>
              ))
            )}
          </TableBody>
        </TableWrapper>
      </StyledTable>
    </div>
  );
};

PortsTable.propTypes = {
  data: PT.arrayOf(PT.shape({})).isRequired,
  isLoading: PT.bool
};

PortsTable.defaultProps = {
  isLoading: false
};
