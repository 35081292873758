import "moment/locale/ru";
import "moment/locale/uk";
import Moment from "react-moment";

import { StyledSkeleton } from "../../../../ui/New/Skeleton/components/SkeletonTableComponents.styled";
import Stars from "../../../../../components/ui/New/Stars/Stars";

import { useLangUrlDefault } from "../../../../../hooks";

import {
  StyledBoxTextSkeleton,
  StyledRecentReviewCard,
  StyledRecentReviewHeader,
  StyledRecentReviewInfo,
  StyledRecentReviewName,
  StyledRecentReviewNameBox,
  StyledRecentReviewStarsBox,
  StyledRecentReviewText
} from "./RecentReview.styled";

export const Review = ({
  review,
  className = "",
  itemHovered,
  siteNameFirst,
  loading
}) => {
  const [queryLang] = useLangUrlDefault();
  return (
    <StyledRecentReviewCard className={className} hovered={itemHovered}>
      <StyledRecentReviewHeader>
        <StyledRecentReviewNameBox>
          {loading ? (
            <StyledSkeleton width={100} height={14} />
          ) : (
            <StyledRecentReviewName>
              {review?.userName?.includes("@")
                ? review?.userName.split("@")[0]
                : review?.userName}
            </StyledRecentReviewName>
          )}
          {loading ? (
            <StyledSkeleton width={80} height={12} />
          ) : (
            <>
              <StyledRecentReviewInfo className={"recent_date"}>
                {siteNameFirst ? (
                  review?.siteAlias
                ) : (
                  <Moment
                    format="MMM DD, YYYY"
                    locale={queryLang === "ua" ? "uk" : queryLang}
                    suppressHydrationWarning
                  >
                    {new Date(review?.date)}
                  </Moment>
                )}
              </StyledRecentReviewInfo>
              <StyledRecentReviewInfo className="recent_site">
                {siteNameFirst ? (
                  <Moment
                    format="MMM DD, YYYY"
                    locale={queryLang === "ua" ? "uk" : queryLang}
                    suppressHydrationWarning
                  >
                    {new Date(review?.date)}
                  </Moment>
                ) : (
                  review?.siteAlias
                )}
              </StyledRecentReviewInfo>
            </>
          )}
        </StyledRecentReviewNameBox>
        <StyledRecentReviewStarsBox>
          {loading ? (
            <StyledSkeleton width={90} height={21} />
          ) : (
            <Stars value={review?.rating} />
          )}
        </StyledRecentReviewStarsBox>
      </StyledRecentReviewHeader>
      {loading ? (
        <StyledBoxTextSkeleton>
          <StyledSkeleton height={14} count={2} />
        </StyledBoxTextSkeleton>
      ) : (
        <StyledRecentReviewText>
          {review?.translation?.[queryLang]?.reviewBody || review?.reviewBody}
        </StyledRecentReviewText>
      )}
    </StyledRecentReviewCard>
  );
};
