import { SkeletonTheme } from "react-loading-skeleton";

import { SkeletonSiteNameCell } from "../components/SkeletonSiteNameCell";
import {
  StyledCell,
  StyledRow,
  StyledSceletonTable,
  StyledSkeleton
} from "../components/SkeletonTableComponents.styled";

export const SkeletonDashboardServiceTable = ({ cells }) => (
  <SkeletonTheme inline>
    <StyledSceletonTable>
      <StyledRow rowHeight={52}>
        <StyledCell cellWidth={48} justify="center">
          <StyledSkeleton width={24} height={24} />
        </StyledCell>
        <StyledCell cellMinWidth={230}>
          <StyledSkeleton width={120} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={140}>
          <StyledSkeleton width={80} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={160}>
          <StyledSkeleton width={80} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={100}>
          <StyledSkeleton width={80} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={100}>
          <StyledSkeleton width={80} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={120}>
          <StyledSkeleton width={100} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={24} />
      </StyledRow>
      {Array(cells)
        .fill(0)
        .map((_, i) => (
          <StyledRow rowHeight={64} key={`item-skeleton-${i}`}>
            <StyledCell cellWidth={48} justify="center">
              <StyledSkeleton width={24} height={24} />
            </StyledCell>
            <SkeletonSiteNameCell width={230} subInfoWidth={40} />
            <StyledCell cellMinWidth={140}>
              <StyledSkeleton width={80} height={14} />
            </StyledCell>
            <StyledCell cellMinWidth={160}>
              <StyledSkeleton width={100} height={36} />
            </StyledCell>
            <StyledCell cellMinWidth={100}>
              <StyledSkeleton width={50} height={14} />
            </StyledCell>
            <StyledCell cellMinWidth={100}>
              <StyledSkeleton width={50} height={14} />
            </StyledCell>
            <StyledCell cellMinWidth={120}>
              <StyledSkeleton width={50} height={14} />
            </StyledCell>
            <StyledCell cellMinWidth={24} justify="flex-end">
              <StyledSkeleton width={24} height={24} />
            </StyledCell>
          </StyledRow>
        ))}
    </StyledSceletonTable>
  </SkeletonTheme>
);
