import { useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { $api } from "../../../services/AxiosInstance";

import { AuthInput } from "../../../components/forms/AuthInput/AuthInput";
import { Button } from "../../../components/ui/Button/Button";
import { Loader } from "../../../components/ui/Loader/Loader";
import { ApiService } from "../../../services";
import { AllActions } from "../../../store/reducers/AllActions";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { RecoverySchema } from "../../../utils/validation";
import { Authorization } from "../components/Authorization/Authorization";

import "../Auth.scss";

export const RecoveryPage = () => {
  const captchaRef = useRef(null);

  // **Props
  const { t } = useTranslation();

  // **Redux state
  const {
    pageContent: { current },
    captchaConfig
  } = useSelector(getAllContent);

  // **Local state
  const [isLoading, setIsLoading] = useState(false);
  const [, setTokenCaptcha] = useState(null);

  const { enable, clientKey, headerName } = captchaConfig.data;

  // Form
  const methods = useForm({
    resolver: yupResolver(RecoverySchema(t("forms", { returnObjects: true })))
  });

  const onSubmit = async (data) => {
    let header = {};

    if (enable) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
    }

    try {
      setIsLoading(true);

      const response = await ApiService.sendPasswordLink(data?.email, header);

      if (response && response.status !== 200) {
        throw response;
      }

      methods.reset();
      toast.success(t("notifications.emailSent"));
    } catch (err) {
      toast.error(err?.message || t("notifications.apiError"));
    } finally {
      if (enable) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <Authorization title={current?.data?.h1}>
        <div className="auth__form-wrapper">
          <FormProvider {...methods}>
            <form
              className="auth__form"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <AuthInput
                placeholder={t("forms.email.label")}
                type="email"
                name="email"
                showError
              />
              {enable && clientKey && (
                <HCaptcha
                  ref={captchaRef}
                  sitekey={clientKey}
                  onVerify={setTokenCaptcha}
                  onExpire={() => setTokenCaptcha(null)}
                  onError={(err) => toast.error(err?.message)}
                  size="invisible"
                />
              )}
              <Button
                disabled={isLoading}
                size="auth"
                position="center"
                type="solid"
              >
                {!isLoading ? (
                  t("authPages.recovery.btn")
                ) : (
                  <Loader
                    type="absolute"
                    scale={0.6}
                    color="var(--clr-default-900)"
                  />
                )}
              </Button>
            </form>
          </FormProvider>
        </div>
      </Authorization>
    </>
  );
};

export default RecoveryPage;

RecoveryPage.getServerSideState = async (store, params) => {
  const { data: pageContent } = await $api.post("/api/page/front/content", {
    ...params,
    languageCode: params.locale
  });

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.locale,
      innerTag: params.location
    })
  );
};
