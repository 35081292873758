import styled from "@emotion/styled";
import DataTable from "react-data-table-component";

import { Title } from "../../../../../../components/ui/New/Title/Title";

export const StyledUserChoiceSpase = styled.div`
  padding: 0 16px;
  width: 100%;
  margin-bottom: 48px;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    padding: 0;
    width: 50%;
    margin-bottom: 0;
  }
`;

export const StyledUserChoiceTable = styled(DataTable)`
  font-family: ${(p) => p.theme.fontFamily.lato};
  background: ${(p) => p.theme.colors.white};
  border-radius: 12px !important;
  box-shadow: ${(p) => p.theme.shadows.card};
  @media (max-width: 614px) {
    padding-bottom: 20px;
  }

  .rdt_Table {
    font-weight: 400;
    font-size: 14px;
    background: transparent;
    border-radius: 0 0 12px 12px;
  }

  .rdt_TableHead {
    border: 0;
    color: ${(props) => props.theme.colors["neutral-400"]};
    font-size: 14px;
  }

  .rdt_TableHeadRow {
    display: flex;
    padding: 0px 8px;
    text-transform: uppercase;

    border-bottom: ${(props) => props.theme.borders.border};
    height: 54px;

    .rdt_TableCol {
      display: flex;
      align-items: center;
      line-height: 20px;
      padding: 0 8px;
      & > div {
        display: inline-flex;
      }
    }
  }

  .rdt_TableRow {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    min-height: 64px;
    background-color: transparent;
    transition: background-color 0.3s ease;
    &:not(:last-child) {
      border-bottom: ${(props) => props.theme.borders.border};
    }

    &:last-child {
      border-radius: 0 0 12px 12px;
    }

    &:hover {
      background-color: rgba(246, 247, 249, 0.8);
      transition: background-color 0.3s ease;
    }
  }

  .rdt_TableCell {
    padding: 0 8px;

    & > div[data-tag="allowRowEvents"] {
      display: inline-flex;
      width: 100%;
      height: 100%;
    }
  }
`;

export const StyledUserChoiceTitle = styled(Title)`
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    margin-bottom: 48px;
  }
`;
