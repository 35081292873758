import { useState } from "react";

// import { yupResolver } from "@hookform/resolvers/yup";
// import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Alert } from "../../../../../components/ui/New/Alert/Alert";
// import { toast } from "react-toastify";
import Button from "../../../../../components/ui/New/Button/Button";
import Modal from "../../../../../components/ui/New/Modal/Modal";

import { ApiService } from "../../../../../services";

import {
  StyledBoxModal,
  StyledDescription
} from "./SubscribePromoModal.styled";

const RES_STATUS = {
  success: "success",
  info: "info"
};

export const SubscribePromoModal = ({ open, onClose, siteId }) => {
  const { t } = useTranslation();

  // const methods = useForm({
  //   resolver: yupResolver(
  //     SubscribeValidation(t("forms", { returnObjects: true }))
  //   ),
  //   defaultValues: {
  //     name: "",
  //     email: ""
  //   }
  // });

  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState(null);

  const handlerClose = () => {
    onClose?.();
    setMessageType(null);
  };

  const handleSubscribe = () => {
    setLoading(true);
    ApiService.subscribeToPromo({
      ownerId: siteId
    })
      .then((res) => {
        if (res?.status === 200) {
          setMessageType(RES_STATUS.success);
        }

        if (res?.request?.status === 409) {
          setMessageType(RES_STATUS.info);
        }
      })
      .catch(() => {
        toast.error(t("notifications.apiError"));
      })
      .finally(() => {
        setLoading(false);
        // handlerClose();
      });
  };

  // const onSubmit = (data) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     // eslint-disable-next-line no-console
  //     console.log(data);
  //     toast.success(`MOCK MESSAGE  ${t("modals.subscribe.success")}`);
  //     setLoading(false);
  //     methods.reset({
  //       name: "",
  //       email: ""
  //     });
  //     handlerClose();
  //   }, 2000);
  // };

  return (
    <Modal
      open={open}
      setOpen={handlerClose}
      title={t("modals.subscribe.title")}
      maxWidth={480}
      mobilePosition={"bottom"}
      // className={styles.modal}
      // classNameBody={styles.modal_body}
    >
      <StyledBoxModal>
        {messageType ? (
          <Alert type={messageType} closable={false}>
            {t(`modals.subscribe.${messageType}`)}
          </Alert>
        ) : (
          <StyledDescription>
            {t("modals.subscribe.description")}
          </StyledDescription>
        )}
        {/* <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
        >
          <Input
            label={t("modals.subscribe.form.name.title")}
            name="name"
            showError
            placeholder={t("modals.subscribe.form.name.placeholder")}
          />
          <Input
            label={t("modals.subscribe.form.email.title")}
            name="email"
            showError
            placeholder={t("modals.subscribe.form.email.placeholder")}
          /> */}
        {!messageType ? (
          <Button onClick={handleSubscribe} fullWidth loading={loading}>
            {t("modals.subscribe.form.btn")}
          </Button>
        ) : (
          <Button onClick={handlerClose} fullWidth variant={"secondary"}>
            {t("modals.subscribe.form.close")}
          </Button>
        )}
      </StyledBoxModal>
      {/* </form>
      </FormProvider> */}
    </Modal>
  );
};
