import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";

import { Feedback } from "./Feedback/Feedback";

import "./FeedbackPage.scss";

const FeedbackPage = () => (
  <>
    <Feedback />
  </>
);

export default FeedbackPage;

FeedbackPage.getServerSideState = async (store, params) => {
  const { data: goals } = await $api.get("/api/goal/front/all", {
    params: { params: { languageCode: params.locale } }
  });
  const { data: goalsByGroups } = await $api.get(
    "/api/front/goal/group/all",
    { params: { languageCode: params.locale } }
  );
  const { data: sidebar } = await $api.get(
    "/api/front/site/blog/sidebar",
    { params: { languageCode: params.locale } }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setGoals(goals));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(
    AllActions.setBlogSidebarContent({
      ...sidebar,
      languageCode: params.locale
    })
  );
};
