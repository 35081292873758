import styled from "@emotion/styled";

export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 24px 0;
  }
`;

export const StyledModalImage = styled.picture`
  margin-top: -10px;
  max-width: ${(p) => (p.pictureVariant === "promocode" ? "320px" : "263px")};
  margin-bottom: ${(p) =>
    p.pictureVariant === "promocode" ? "-16px" : "-34px"};
`;

export const StyledModalSubtitle = styled.p`
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0 20px;

  color: ${(p) => p.theme.colors["neutral-800"]};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-align: center;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: 28px;
  }
`;

export const StyledModalButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  & > * {
    min-width: 150px;
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      width: fit-content !important;
    }
  }
`;
