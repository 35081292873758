import { useTranslation } from "react-i18next";

import {
  animationWebRTCLeak,
  animationWebRTCLeakIp
} from "../../../../../assets/animations";
import { Rive } from "../../../../../components/ui/New/Rive/Rive";

import {
  AnimmationWrapper,
  Description,
  RestyledContainer,
  RestyledTitle,
  StyledContent,
  StyledImage,
  StyledPicture,
  StyledSection
} from "./WebRTCLeakTestInfo.styled";

export const WebRTCLeakTestInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledSection>
        <RestyledContainer>
          <StyledContent>
            <RestyledTitle tag="h2">
              {t("webRTCLeakTest.whatIsWebRTC.title")}
            </RestyledTitle>
            <Description>
              {t("webRTCLeakTest.whatIsWebRTC.description")}
            </Description>
          </StyledContent>
          <AnimmationWrapper>
            <Rive
              src={animationWebRTCLeak}
              autoPlay
              top={-74}
              right={-80}
              left={-80}
              bottom={-74}
            />
          </AnimmationWrapper>
          <StyledPicture>
            <source
              srcSet="img/webRTC-leak-test/webRTC-leak-info-image-mobile.svg"
              media="(max-width: 992px)"
            />
            <StyledImage
              src="img/webRTC-leak-test/webRTC-leak-info-image-mobile.svg"
              alt={t("webRTCLeakTest.whatIsWebRTC.title")}
            />
          </StyledPicture>
        </RestyledContainer>
      </StyledSection>
      <StyledSection variant="light">
        <RestyledContainer variant="light">
          <AnimmationWrapper variant="light">
            <Rive
              src={animationWebRTCLeakIp}
              autoPlay
              top={-85}
              right={-71}
              bottom={-58}
              left={-70}
            />
          </AnimmationWrapper>
          <StyledPicture>
            <source srcSet="img/webRTC-leak-test/webRTC-leak-info-image-mobile1.svg" />
            <StyledImage
              src="img/webRTC-leak-test/webRTC-leak-info-image-mobile1.svg"
              alt={t("webRTCLeakTest.whatIsWebRTC.title")}
            />
          </StyledPicture>
          <StyledContent variant="light">
            <RestyledTitle tag="h2">
              {t("webRTCLeakTest.whatDoesWebRTC.title")}
            </RestyledTitle>
            <Description>
              {t("webRTCLeakTest.whatDoesWebRTC.description")}
            </Description>
          </StyledContent>
        </RestyledContainer>
      </StyledSection>
    </>
  );
};
