import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";

import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";

export const ProxyContentTop = () => {
  const { t } = useTranslation();

  // **Redux state
  const { site } = useSelector(getAllProxySite);
  const {
    pageContent: { current }
  } = useSelector(getAllContent);
  // const metaData = useMetaData();

  return (
    <div className="proxy-site__top">
      <div className="proxy-site__top-title">
        <h1>{current?.data?.h1 || site.data?.name}</h1>
      </div>
      <div className="smMin">
        <a
          href={`/open-site/${site.data?.id}`}
          rel="noreferrer"
          target="_blank"
          className="button button__type--solid button__size--default"
        >
          {t("proxySitePage.content.top.btn")}
        </a>
      </div>
    </div>
  );
};
