import { createSlice } from "@reduxjs/toolkit";

// Thunks
import { getBlogContent } from "./Blog.thunks";

const initialState = {
  content: {
    data: {
      totalElements: 0,
      totalPages: 0,
      content: [],
      params: {}
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  }
};

const BlogReducer = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setBlogContent(state, action) {
      state.content.data = action.payload;
      state.content.isDataLoaded = true;
    }
  },
  extraReducers: (builder) => {
    // getBlogContent
    builder.addCase(getBlogContent.pending, (state) => {
      state.content.isError = null;
      state.content.isLoading = true;
    });
    builder.addCase(getBlogContent.fulfilled, (state, action) => {
      state.content.isError = null;
      state.content.data = action.payload;
      state.content.isLoading = false;
      state.content.isDataLoaded = true;
    });
    builder.addCase(getBlogContent.rejected, (state, action) => {
      state.content.isError = action.payload;
      state.content.isLoading = false;
    });
  }
});

export const BlogReducerActions = {
  ...BlogReducer.actions,
  getBlogContent
};
export default BlogReducer.reducer;
