import styled from "@emotion/styled";

import Icon from "../../../../ui/New/Icon/Icon";

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;

  span {
    color: rgba(255, 255, 255, 0.8);
    font-family: var(--font-family-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 12px;
  transform: rotate(180deg);
`;
