import PT from "prop-types";

import Dropdown from "../../../../ui/New/Dropdown/Dropdown";
import Icon from "../../../../ui/New/Icon/Icon";

import { StyledIcon } from "./ActionCell.styled";

export const ActionCell = ({ id, actions, mlauto, vertical }) => {
  if (!actions || actions.length === 0 || !id) {
    return null;
  }
  return (
    <Dropdown
      label={<StyledIcon name="dots" vertical={vertical.toString()} />}
      align={"start"}
      direction={"left"}
      position={"anchor"}
      portal
      variant={"table"}
      mlauto={mlauto}
    >
      {actions.map((action, index) => (
        <Dropdown.Item
          linkTo={action.linkTo}
          onClick={action.onClick}
          key={`action-${index}-${id}`}
          variant={action.variant}
          disabled={action.disabled}
        >
          {action?.icon ? <Icon name={action.icon} /> : null}
          {action.label}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

ActionCell.propTypes = {
  id: PT.string.isRequired,
  actions: PT.arrayOf(
    PT.shape({
      linkTo: PT.string,
      onClick: PT.func,
      variant: PT.string,
      disabled: PT.bool,
      icon: PT.string,
      label: PT.string.isRequired
    })
  ).isRequired,
  mlauto: PT.bool,
  vertical: PT.bool
};

ActionCell.defaultProps = {
  mlauto: true,
  vertical: false
};
