import styled from "@emotion/styled";

export const RowsInfoStyled = styled.span`
  margin: 0 4px;
  text-align: center;
  min-width: 100px;
  color: ${({ theme }) => theme.colors["neutral-500"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  line-height: 170%;
`;
