import styled from "@emotion/styled";

export const StyledBlockContactsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    gap: 16px;
  }
`;
