import styled from "@emotion/styled";

export const StyledSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(218, 218, 225, 0.4);
  margin: ${(p) => (p.margin ? `${p.margin}px 0` : "0")};

  ${(p) =>
    p.marginLg
      ? `
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      margin: ${p.marginLg}px 0;
    }`
      : ""}
`;
