import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";

import { useLangUrlDefault } from "../../../hooks";

import { Container } from "../../ui";

import { BreadcrumbsItem } from "./BreadcrumbsItem/BreadcrumbsItem";

import "./Breadcrumbs.scss";

export const Breadcrumbs = ({ items = [] }) => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Redux state
  const { routeContent } = useSelector(getAllContent);

  return (
    <div className="breadcrumbs">
      <Container isFluid={routeContent.isContainerFluid}>
        <ul className="breadcrumbs__list">
          <li>
            <Link to={`${hrefLang}/`}>{t("breadcrumbs.main")}</Link>
          </li>
          {items.map((item) => (
            <BreadcrumbsItem key={item.title} item={item} />
          ))}
        </ul>
      </Container>
    </div>
  );
};
