import PT from "prop-types";

import { IconStyled } from "./Icon.styled";

const Icon = ({ name, className, color, size, disabled, onClick, style }) => (
  <IconStyled
    name={name}
    className={className}
    style={style}
    color={color}
    size={size}
    disabled={disabled}
    {...(onClick && {
      onClick,
      role: "button"
    })}
  />
);

Icon.propTypes = {
  className: PT.string,
  color: PT.oneOf(["", "primary", "secondary"]),
  name: PT.string.isRequired,
  size: PT.oneOf(["sm", "md", "lg"]),
  disabled: PT.bool,
  style: PT.shape({}),
  onClick: PT.func
};

Icon.defaultProps = {
  className: "",
  color: "",
  size: "md",
  style: {}
};

export default Icon;
