import styled from "@emotion/styled";

export const StyledDesctiption = styled.p`
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};

  color: ${(p) => p.theme.colors["neutral-400"]};
  font-size: ${(p) => p.theme.fontSizes.s};
  line-height: 140%;
  margin: 12px 0 0 0;
`;
