import styled from "@emotion/styled";

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchLabel = styled.label`
  margin-left: 12px;

  color: ${({ theme }) => theme.colors["neutral-500"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  line-height: 125%;

  &[for] {
    cursor: pointer;
  }
`;

export const SwitchInput = styled.input`
  position: relative;
  display: flex;
  width: 48px;
  height: 24px;
  cursor: pointer;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors["neutral-75"]};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.3s ease;

  &::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: ${({ theme }) => theme.colors["neutral-75"]};
    border-radius: 50%;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    transition: all 0.3s ease;
  }

  &:hover,
  &:focus {
    border-color: #c5cdd9;
    transition: all 0.3s ease;

    &::after {
      background-color: #c5cdd9;
      transition: all 0.3s ease;
    }
  }

  &:checked {
    background-color: ${({ theme }) => theme.colors["main-700"]};
    border-color: ${({ theme }) => theme.colors["main-700"]};

    transition: all 0.3s ease;

    &::after {
      background-color: ${({ theme }) => theme.colors.white};
      transform: translateX(22px) translateY(-50%);
      transition: all 0.3s ease;
    }

    &:hover,
    &:focus {
      border-color: #79b22f;
      background-color: #79b22f;
      transition: all 0.3s ease;
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors["neutral-75"]} !important;
    border-color: ${({ theme }) => theme.colors["neutral-75"]} !important;

    &::after {
      background-color: ${({ theme }) => theme.colors.white} !important;
    }

    & + label {
      color: #c5cdd9 !important;
    }
  }
`;
