import {
  Flag,
  Point,
  SocialIcon
} from "../../pages/New/ToolsPages/MyAnonymityPage/IpDetails/IpDetails.styled";

export const getIpDetailsData = (userIp, t) => [
  {
    id: "location",
    title: t("myAnonymity.details.location"),
    image: "img/ui/location.svg",
    listData: [
      {
        key: "Country",
        value:
          (
            <>
              <Flag className={`fi fi-${userIp?.countryA2?.toLowerCase()}`} />
              {userIp?.country}
            </>
          ) || "N/A"
      },
      {
        key: "Region",
        value: userIp?.region || "N/A"
      },
      {
        key: "City",
        value: userIp?.city || "N/A"
      },
      {
        key: "ZIP",
        value: userIp?.zip || "N/A"
      },
      {
        key: "Hostname",
        value: userIp?.hostname || "N/A"
      },
      {
        key: "Reversed",
        value: userIp?.reversed || "N/A"
      },
      {
        key: "IP range",
        value: userIp?.range || "N/A"
      },
      {
        key: "ISP",
        value: userIp?.isp || "N/A"
      },
      {
        key: "Organization",
        value: userIp?.organization || "N/A"
      },
      {
        key: "AS Organization",
        value: userIp?.autonomousSystemOrganization || "N/A"
      },
      {
        key: "AS Number",
        value: userIp?.autonomousSystemNumber || "N/A"
      }
    ]
  },
  {
    id: "language",
    title: t("myAnonymity.details.language"),
    image: "img/ui/language.svg",
    listData: [
      {
        value:
          (
            <>
              <Flag className={`fi fi-${userIp?.countryA2?.toLowerCase()}`} />
              {userIp?.countryA2}
            </>
          ) || "N/A"
      },
      {
        value:
          (
            <>
              <Flag className={`fi fi-${userIp?.countryA2?.toLowerCase()}`} />
              {userIp?.countryA2}
            </>
          ) || "N/A"
      }
    ]
  },
  {
    id: "browser",
    title: t("myAnonymity.details.browser"),
    image: "img/ui/browser.svg",
    listData: [
      { key: "Headers", value: userIp?.userAgent || "N/A" },
      { key: "JavaScript", value: userIp?.userAgent || "N/A" }
    ]
  },
  {
    id: "time",
    title: "Time",
    image: "img/ui/time.svg",
    listData: [
      { key: "Zone", value: userIp?.zone || "N/A" },
      { key: "Local", value: userIp?.local || "N/A" },
      { key: "System", value: userIp?.system || "N/A" }
    ]
  },
  {
    id: "social Media",
    title: t("myAnonymity.details.socialMedia"),
    image: "img/ui/language.svg",
    listData: [
      {
        value: (
          <>
            <SocialIcon src="img/icons/gmail.svg" alt="gmail" />
            Gmail
          </>
        )
      },
      {
        value: (
          <>
            <SocialIcon src="img/icons/facebook.svg" alt="facebook" />
            Facebook
          </>
        )
      }
    ]
  },
  {
    id: "interactive detection",
    title: t("myAnonymity.details.interactiveDetection"),
    image: "img/ui/interactive-detection.svg",
    listData: [
      {
        keyTitle: "Ip address",
        keyValue: [userIp?.ipAddress, userIp?.userAgent]
      },
      { key: "Flash", value: userIp?.flash || "N/A" },
      { key: "WebRTC", value: userIp?.system || "N/A" },
      { key: "Java (TCP)", value: userIp?.system || "N/A" },
      { key: "Java (UDP)", value: userIp?.system || "N/A" },
      { key: "Java (system)", value: userIp?.system || "N/A" },
      { keyTitle: "DNS" },
      {
        key: "Browser",
        value: [
          <>
            <Flag className={`fi fi-${userIp?.countryA2?.toLowerCase()}`} />
            {userIp?.countryA2}
          </>,
          userIp?.ipAddress,
          userIp?.ipAddress
        ]
      },
      { key: "Flash", value: userIp?.system || "N/A" },
      { key: "Java (request)", value: userIp?.system || "N/A" },
      { key: "Java (system)", value: userIp?.system || "N/A" },
      { keyTitle: "OS" },
      { key: "Headers", value: userIp?.userAgent || "N/A" },
      { key: "javaScript", value: userIp?.system || "N/A" },
      { key: "Java (request)", value: userIp?.system || "N/A" },
      { key: "Java (system)", value: userIp?.system || "N/A" },
      { keyTitle: "Ports" },
      { key: "Open ports", value: userIp?.port || "N/A" }
    ]
  },
  {
    id: "navigator",
    title: t("myAnonymity.details.navigator"),
    image: "img/ui/navigator.svg",
    listData: [
      { key: "productSub", value: userIp?.productSub || "N/A" },
      { key: "vendor", value: userIp?.vendor || "N/A" },
      { key: "maxTouchPoints", value: userIp?.maxTouchPoints || "N/A" },
      { key: "pdfViewerEnabled", value: userIp?.pdfViewerEnabled || "N/A" },
      { key: "hardwareConcurrency", value: userIp?.maxTouchPoints || "N/A" },
      { key: "cookieEnabled", value: userIp?.maxTouchPoints || "N/A" },
      { key: "appCodeName", value: userIp?.app || "N/A" },
      { key: "appName", value: userIp?.appType || "N/A" },
      { key: "appVersion", value: userIp?.appMajorVersion || "N/A" },
      { key: "platform", value: userIp?.platform || "N/A" },
      { key: "userAgent", value: userIp?.userAgent || "N/A" },
      { key: "language", value: userIp?.countryA2 || "N/A" },
      { key: "onLine", value: userIp?.onLine || "N/A" },
      { key: "webdriver", value: userIp?.webdriver || "N/A" },
      { key: "deviceMemory", value: userIp?.deviceMemory || "N/A" }
    ]
  },
  {
    id: "screen",
    title: t("myAnonymity.details.screen"),
    image: "img/ui/screen.svg",
    listData: [
      { key: "colorDepth", value: userIp?.colorDepth || "N/A" },
      { key: "pixelDepth", value: userIp?.pixelDepth || "N/A" },
      { key: "height", value: userIp?.height || "N/A" },
      { key: "width", value: userIp?.width || "N/A" },
      { key: "availHeight", value: userIp?.availHeight || "N/A" },
      { key: "availWidth", value: userIp?.availWidth || "N/A" },
      { key: "top", value: userIp?.top || "N/A" },
      { key: "left", value: userIp?.left || "N/A" },
      { key: "availTop", value: userIp?.availTop || "N/A" },
      { key: "availLeft", value: userIp?.availLeft || "N/A" },
      { key: "window size", value: userIp?.windowSize || "N/A" }
    ]
  },
  {
    id: "scripts",
    title: t("myAnonymity.details.scripts"),
    image: "img/ui/scripts.svg",
    listData: [
      {
        key: "JavaScript",
        value: userIp?.javaScript ? (
          <>
            <Point color="green" /> enabled
          </>
        ) : (
          <>
            <Point color="red" /> disabled
          </>
        )
      },
      {
        key: "Flash",
        value: userIp?.flash ? (
          <>
            <Point color="green" /> enabled
          </>
        ) : (
          <>
            <Point color="red" /> disabled
          </>
        )
      },
      {
        key: "Java",
        value: userIp?.java ? (
          <>
            <Point color="green" /> enabled
          </>
        ) : (
          <>
            <Point color="red" /> disabled
          </>
        )
      },
      {
        key: "ActiveX",
        value: userIp?.activeX ? (
          <>
            <Point color="green" /> enabled
          </>
        ) : (
          <>
            <Point color="red" /> disabled
          </>
        )
      },
      {
        key: "WebRTC",
        value: userIp?.webRTC ? (
          <>
            <Point color="green" />
            <span>enabled</span>
          </>
        ) : (
          <>
            <Point color="red" />
            <span>disabled</span>
          </>
        )
      },
      {
        key: "VBScript",
        value: userIp?.vbsScript ? (
          <>
            <Point color="green" /> enabled
          </>
        ) : (
          <>
            <Point color="red" /> disabled
          </>
        )
      },
      {
        key: "AdBlock",
        value: userIp?.adBlock ? (
          <>
            <Point color="green" /> enabled
          </>
        ) : (
          <>
            <Point color="red" /> disabled
          </>
        )
      }
    ]
  },
  {
    id: "plugins",
    title: t("myAnonymity.details.plugins"),
    image: "img/ui/plugins.svg",
    listData: [
      { key: "PDF Viewer", value: "internal-pdf-viewer" },
      { key: "Chrome PDF Viewer", value: "internal-pdf-viewer" },
      { key: "Chromium PDF Viewer", value: "internal-pdf-viewer" },
      { key: "Microsoft Edge PDF Viewer", value: "internal-pdf-viewer" },
      { key: "WebKit built-in PDF", value: "internal-pdf-viewer" }
    ]
  }
];
