import { Suspense, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  Outlet, // useLoaderData,
  useNavigate
} from "react-router-dom";

import { DashboardHeader } from "../../../components/common/New/Dashboard/Header/Header";
import { DashboardMenu } from "../../../components/common/New/Dashboard/Menu/Menu";
import { Loader } from "../../../components/ui/Loader/Loader";
import { useLangUrlDefault, useMatchedRoute } from "../../../hooks";
import { getAllUser } from "../../../store/reducers/UserReducer/User.selectors";

import {
  DashboardLayoutContent,
  DashboardLayoutStyled,
  SpaceContent
} from "./DashboardLayout.styled";

export const DashboardLayout = () => {
  // const { isAuthRequired } = useLoaderData();
  // console.log("isAuthRequired", isAuthRequired);

  // **Props
  const route = useMatchedRoute();
  const navigate = useNavigate();
  const [, hrefLang] = useLangUrlDefault();

  const [menuOpenType, setMenuOpenType] = useState("default");

  const { isAuthfetched, isUserAuthenticated, isLogout } =
    useSelector(getAllUser);

  // Check auth
  useEffect(() => {
    if (
      !isUserAuthenticated &&
      route?.isAuthRequired &&
      isAuthfetched &&
      !isLogout
    ) {
      navigate(`${hrefLang}/new/login/`, {
        replace: true
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated, route?.isAuthRequired, isAuthfetched]);

  return !isAuthfetched ? (
    <Loader type={"absolute"} />
  ) : (
    <DashboardLayoutStyled>
      <DashboardMenu type={menuOpenType} setType={setMenuOpenType} />
      <DashboardLayoutContent>
        <DashboardHeader onOpenMenu={setMenuOpenType} />
        <SpaceContent>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </SpaceContent>
      </DashboardLayoutContent>
    </DashboardLayoutStyled>
  );
};
