import styled from "@emotion/styled";

export const StyledProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
  border-radius: 12px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows.card};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 32px;
  }
`;
