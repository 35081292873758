import { useEffect, useId, useRef, useState } from "react";

import { StyledTooltip } from "../../../../../../components/ui/New/Tooltip/Tooltip.styled";

import { StyledContactLink } from "./Social.styled";

export const SocialLink = ({ code, link, name }) => {
  const ref = useRef();
  const id = useId();

  const [needTooltip, setNeedTooltip] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setNeedTooltip(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref]);

  return (
    <>
      <StyledContactLink
        to={code === "email" ? `mailto:${link}` : link}
        target="_blank"
        rel="noreferrer"
      >
        <img src={`/img/social/${code}.svg`} alt={code} />
        <span ref={ref} data-tooltip-id={id}>
          {name}
        </span>
      </StyledContactLink>
      {needTooltip ? (
        <StyledTooltip id={id} target={ref}>
          {link}
        </StyledTooltip>
      ) : null}
    </>
  );
};
