import styled from "@emotion/styled";

export const BreadcrumbsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 0;
`;

export const BreadcrumbsItemStyled = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 170%;

  &:not(:last-child) {
    color: ${({ theme }) => theme.colors["neutral-800"]};
  }

  &:last-child {
    color: #4c5464;
  }

  & span {
    display: inline-block;
    padding: 0 8px;
  }
`;
