import styled from "@emotion/styled";

export const FormFieldWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 5px;

  color: ${(p) => p.theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;

  transition: color 0.5s ease;
  &:focus-within {
    color: ${(p) =>
      p.isError ? p.theme.colors["neutral-400"] : p.theme.colors["main-700"]};
    transition: color 0.5s ease;
  }

  & + .form-field {
    margin-top: 16px;
  }
`;
