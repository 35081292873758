import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import {
  animationFreeProxyDifference,
  animationFreeProxyDownload,
  animationFreeProxyList
} from "../../../../../assets/animations";
import { Rive } from "../../../../../components/ui/New/Rive/Rive";
import { useLangUrlDefault } from "../../../../../hooks";
import {
  mockListData,
  mockStepsData,
  mockTableData
} from "../../../../../utils/constants/mockFreeProxyData";
import { ProxyDifferenceTable } from "../DataTable/ProxyDifferenceTable/ProxyDifferenceTable";

import {
  AnimationContainer,
  ContentWrapper,
  Description,
  DottedList,
  DottedListItem,
  ListTitle,
  MobileImage,
  MobilePicture,
  RestyledContainer,
  RestyledTitle,
  SectionInfo,
  StepDescription,
  StepItem,
  StepTitle,
  TableBox,
  TableContainer
} from "./FreeProxyInfo.styled";

export const FreeProxyInfo = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const dottedList = useMemo(
    () => (
      <DottedList>
        {queryLang &&
          mockListData[queryLang].map((item, index) => (
            <DottedListItem key={index}>
              <div>
                <img src="img/icons/dot.svg" alt="Dot icon" />
              </div>
              {item}
            </DottedListItem>
          ))}
      </DottedList>
    ),
    [queryLang]
  );

  return (
    <>
      <SectionInfo>
        <RestyledContainer>
          <AnimationContainer>
            <Rive
              src={animationFreeProxyList}
              autoPlay
              top={-136}
              right={-110}
              bottom={-136}
              left={-110}
            />
          </AnimationContainer>
          <MobilePicture>
            <source
              srcSet="img/free-proxy/free-proxy-info-image-mobile.svg"
              media="(max-width: 992px)"
            />
            <MobileImage
              src="img/free-proxy/free-proxy-info-image-mobile.svg"
              alt={t("freeProxy.howUseProxy.title")}
            />
          </MobilePicture>
          <ContentWrapper>
            <RestyledTitle tag="h2">
              {t("freeProxy.howUseProxy.title")}
            </RestyledTitle>
            <Description>{t("freeProxy.howUseProxy.description")}</Description>
            <ListTitle>{t("freeProxy.howUseProxy.listTitle")}</ListTitle>
            {dottedList}
          </ContentWrapper>
        </RestyledContainer>
      </SectionInfo>
      <SectionInfo variant={"light"}>
        <RestyledContainer variant="light">
          <ContentWrapper variant="light">
            <RestyledTitle tag="h2">
              {t("freeProxy.differenceBetween.title")}
            </RestyledTitle>
            <Description>
              {t("freeProxy.differenceBetween.description")}
            </Description>
          </ContentWrapper>
          <AnimationContainer variant="light">
            <Rive
              src={animationFreeProxyDifference}
              autoPlay
              top={-85}
              right={-120}
              bottom={-85}
              left={-120}
            />
          </AnimationContainer>
          <MobilePicture>
            <source
              srcSet="img/free-proxy/free-proxy-info-image-mobile1.svg"
              media="(max-width: 992px)"
            />
            <MobileImage
              src="img/free-proxy/free-proxy-info-image-mobile1.svg"
              alt={t("freeProxy.differenceBetween.title")}
            />
          </MobilePicture>
        </RestyledContainer>
        <TableContainer>
          <TableBox>
            <ProxyDifferenceTable
              data={queryLang ? mockTableData[queryLang] : []}
            />
          </TableBox>
        </TableContainer>
      </SectionInfo>
      <SectionInfo variant={"white"}>
        <RestyledContainer variant="white">
          <AnimationContainer variant="white">
            <Rive
              src={animationFreeProxyDownload}
              autoPlay
              top={-41}
              right={-69}
              bottom={-13}
              left={-69}
            />
          </AnimationContainer>
          <MobilePicture>
            <source
              srcSet="img/free-proxy/free-proxy-info-image-mobile2.svg"
              media="(max-width: 992px)"
            />
            <MobileImage
              src="img/free-proxy/free-proxy-info-image-mobile2.svg"
              alt={t("freeProxy.howDownloadProxy.title")}
            />
          </MobilePicture>
          <ContentWrapper>
            <RestyledTitle tag="h2" variant="white">
              {t("freeProxy.howDownloadProxy.title")}
            </RestyledTitle>
            {queryLang &&
              mockStepsData[queryLang].map((step, index) => (
                <StepItem key={step}>
                  <StepTitle>{`${t("ui.step")} ${index + 1}`}</StepTitle>
                  <StepDescription>{step}</StepDescription>
                </StepItem>
              ))}
          </ContentWrapper>
        </RestyledContainer>
      </SectionInfo>
    </>
  );
};
