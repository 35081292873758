import { $api } from "../../../../../services/AxiosInstance";
import { AllActions } from "../../../../../store/reducers/AllActions";

import { PromocodesList } from "./PromocodesList/PromocodesList";

const PromocodesListPage = () => (
  <>
    <PromocodesList />
  </>
);

export default PromocodesListPage;

PromocodesListPage.getServerSideState = async (store, params) => {
  const { data: pageContent } = await $api.post(
    "/api/page/front/content",
    {
      ...params,
      languageCode: params.locale
    }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.locale,
      innerTag: params.location
    })
  );
};
