import { useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Container from "../../../../../components/common/New/Container/Container";

import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";

import { Article } from "./Article/Article";

export const Content = () => {
  // **Redux state
  const { captchaConfig } = useSelector(getAllContent);

  // **Local state
  const [, setTokenCaptcha] = useState(null);

  // **Ref
  const captchaRef = useRef(null);

  return (
    <Container>
      <Article />
      {captchaConfig.data.enable && captchaConfig.data.clientKey && <HCaptcha
        ref={captchaRef}
        sitekey={captchaConfig.data.clientKey}
        onLoad={() => captchaRef.current.removeCaptcha()}
        onVerify={setTokenCaptcha}
        onExpire={() => setTokenCaptcha(null)}
        onError={(err) => toast.error(err?.message)}
        size="invisible"
      />}
    </Container>
  );
};
