import styled from "@emotion/styled";

import Logo from "../../components/ui/New/Logo/Logo";
import { Subtitle } from "../../components/ui/New/Subtitle/Subtitle";
import { Title } from "../../components/ui/New/Title/Title";

export const AuthLayoutStyled = styled.main`
  background-color: ${({ theme }) => theme.colors["background-color-black"]};
  width: 100%;
  min-height: 100vh;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: flex;
  }
`;

export const RestyledLogo = styled(Logo)`
  position: absolute;
  z-index: 10;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    top: 28px;
    left: 48px;
    transform: none;
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const StyledAside = styled.aside`
  display: none;
  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    z-index: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    min-width: 550px;
    max-width: 550px;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.colors["background-color-black"]};
    padding: 190px 48px 0 48px;
  }
`;

export const RestyledTitle = styled(Title)`
  font-size: 32px !important;
  line-height: 120% !important;
  margin-bottom: 16px !important;
`;

export const RestyledSubtitle = styled(Subtitle)`
  position: relative;
  z-index: 1;
  margin-bottom: 64px !important;
`;

export const AnimationWrapper = styled.div`
  ${({ variant }) =>
    variant === "feedback"
      ? `
        margin-left: -8px;
        width: 455px;
        height: 294px;`
      : `
        margin: 0 auto;
        width: 404px;
        height: 321px;`}
`;

export const StyledSectionWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 16px 25px 16px;
  width: 100%;
  min-height: 100vh;

  background: ${({ theme }) => theme.colors["background-color-seo"]};
  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    padding: 0px 48px 48px 48px;
    margin: 0 auto;
  }
`;
