import { useFormContext, useFormState } from "react-hook-form";

import { FormError } from "../../../../../../../../components/forms/New/FormError/FormError";
import { FormCheckbox } from "../../../../../../../../components/forms/New/FormCheckbox/FormCheckbox";

import { StyledProxyTypesBox } from "./ProxyTypes.styled";

export const ProxyTypes = ({ data }) => {
  const { errors } = useFormState();
  const { watch } = useFormContext();
  const proxyTypes = watch("proxyTypes");
  return (
    <div>
      <StyledProxyTypesBox>
        {data?.map(({ type, name }) => (
          <FormCheckbox
            key={type}
            name={`proxyTypes.${type}`}
            label={name}
            defaultValue={false}
          />
        ))}
      </StyledProxyTypesBox>
      {errors?.proxyTypes && Object.values(proxyTypes).every((i) => !i) && (
        <FormError message={errors?.proxyTypes?.message} />
      )}
    </div>
  );
};
