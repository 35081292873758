import { $api } from "../../../../../services/AxiosInstance";
import { AllActions } from "../../../../../store/reducers/AllActions";

import { ProxyList } from "./ProxyList/ProxyList";

const ProxyListPage = () => (
  <>
    <ProxyList />
  </>
);

export default ProxyListPage;

ProxyListPage.getServerSideState = async (store, params) => {
  const { data: pageContent } = await $api.post(
    "/api/page/front/content",
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.locale,
      innerTag: params.location
    })
  );
};
