import { useTranslation } from "react-i18next";

import { ItemsOnPageStyled, RestyledSelect } from "./ItemsOnPage.styled";

export const ItemsOnPage = ({
  rowsPerPage,
  totalRows,
  onChangeRowsPerPage,
  className
}) => {
  const { t } = useTranslation();

  const options = [10, 25, 50, 100].filter(
    (item) => totalRows / item > 0.5 || item === rowsPerPage
  );

  return (
    <ItemsOnPageStyled className={className}>
      {t("pagination.table.rowsCount")}:
      <RestyledSelect
        options={options.map((item) => ({
          value: item,
          label: item
        }))}
        value={rowsPerPage}
        onChange={onChangeRowsPerPage}
      />
    </ItemsOnPageStyled>
  );
};
