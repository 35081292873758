import styled from "@emotion/styled";

export const StyledPromosContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 100%;

  &::after {
    content: "";
    display: block;
    width: calc(100% - 12px);
    height: 41px;
    background: linear-gradient(
      358deg,
      #fff 14.52%,
      rgba(255, 255, 255, 0) 103.01%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
`;

export const StyledPromosTitle = styled.p`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 24px;
`;

export const StyledPromosBox = styled.div`
  position: relative;
  display: block;
  height: 100%;
  overflow-y: auto;
`;

export const StyledPromosList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  position: absolute;
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  flex-direction: column;
  gap: 16px;

  & > li {
    display: flex;
    width: 100%;
  }
`;
