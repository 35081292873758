import styled from "@emotion/styled";

import Burger from "../../../ui/New/Burger/Burger";
import Button from "../../../ui/New/Button/Button";

export const StyledHeader = styled.header`
  width: 100%;
  position: relative;
  z-index: 50;
  padding: 20px 15px;
  background-color: ${(props) =>
    props.variant === "dark"
      ? props.theme.colors["background-color-black"]
      : "rgba(252, 252, 252, 1)"};
  backdrop-filter: ${(props) =>
    props.variant === "dark" ? "none" : "blur(5.5px)"};
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    backdrop-filter: ${(props) =>
      props.variant === "dark" ? "none" : "blur(10.300000190734863px)"};
  }
  @media (min-width: 1230px) {
    padding: 20px 32px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: none;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: flex;
    align-items: center;
    margin-left: 7.2rem;
    margin-right: auto;
    gap: 1.2rem;
  }
`;

export const BurgerDesktop = styled(Burger)`
  display: none !important;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: flex !important;
  }
`;

export const BurgerMobile = styled(Burger)`
  display: flex;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none !important;
  }
`;

export const LanguageWrapper = styled.div`
  display: none;
  @media (min-width: 993px) {
    display: flex;
    gap: 1.2rem;
    margin-left: auto;
  }
`;

export const AddSiteButton = styled(Button)`
  background-color: ${(props) =>
    props.color === "dark"
      ? "#ffffff14 !important"
      : "rgba(255, 255, 255, 0.5) !important"};
  background-image: none !important;
  color: ${(props) =>
    props.color === "dark"
      ? "white !important"
      : `${props.theme.colors["neutral-800"]} !important`};
  height: 44px !important;
  padding: 14px 24px !important;
  min-width: 135px !important;
  outline: ${(props) =>
    props.color === "dark" ? "1px solid transparent" : "none"} !important;
  box-shadow: ${(props) =>
    props.color === "dark"
      ? "none"
      : "0px 0px 21.1px 0px rgba(0, 0, 0, 0.05)"} !important;
  backdrop-filter: ${(props) =>
    props.color === "dark" ? "none" : "blur(7.5px)"} !important;

  svg {
    color: ${(props) =>
      props.color === "dark"
        ? props.theme.colors["main-500"]
        : `${props.theme.colors["main-700"]} !important`};
  }

  &:hover,
  &:focus {
    color: ${(props) =>
      props.color === "dark"
      ? `${props.theme.colors["main-500"]} !important`
      : `${props.theme.colors["main-700"]} !important`};
    background-color: ${(props) =>
      props.color === "dark" ? "#ffffff14" : "white !important"};
    box-shadow: ${(props) =>
      props.color === "dark"
        ? "none"
        : "0px 0px 21.1px 0px rgba(0, 0, 0, 0.1)"} !important;
    outline: ${(props) =>
      props.color === "dark"
        ? "1px solid transparent !important"
        : "none !important"};
  }

  &:active {
    background: ${(props) =>
      props.color === "dark"
        ? "rgba(255, 255, 255, 0.08)"
        : "white"} !important;
    box-shadow: ${(props) =>
      props.color === "dark"
        ? "0px 0px 54px 0px rgba(255, 255, 255, 0.08) inset"
        : "0px 0px 21.1px 0px rgba(0, 0, 0, 0.1)"} !important;
    backdrop-filter: blur(7.5px);
    outline: ${(props) =>
      props.color === "dark"
        ? "1px solid rgba(255, 255, 255, 0.25) !important"
        : "none !important"};
  }
`;

export const ServiceButton = styled(Button)`
  height: 44px !important;
  min-width: 205px !important;
  padding: 14px 24px !important;

  &:hover {
    box-shadow: 0px 111px 31px 0px rgba(200, 238, 133, 0),
      0px 71px 29px 0px rgba(200, 238, 133, 0.01),
      0px 40px 24px 0px rgba(200, 238, 133, 0.05),
      0px 18px 18px 0px rgba(200, 238, 133, 0.09),
      0px 4px 10px 0px rgba(200, 238, 133, 0.1);
  }

  svg {
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
    transition: all 0.3s ease;
  }
`;
