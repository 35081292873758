import { createSlice } from "@reduxjs/toolkit";

// Thunks
import {
  getComparisonWidgetInfo,
  updateComparsionSites
} from "./Comparison.thunks";

const initialState = {
  uuid: "",
  sessionId: "",
  isOwner: false,
  isDeleting: false,
  sites: [],
  prevSites: [],
  sitesFull: [],
  isError: false,
  isLoading: false,
  isDataLoaded: false
};

const ComparisonReducer = createSlice({
  name: "comparison",
  initialState,
  reducers: {
    setFullSites: (state, action) => {
      state.sitesFull = action.payload.proxyList;
      state.isOwner = action.payload.isOwner;
    },
    resetFullSites: (state) => {
      state.sitesFull = [];
    },
    setPrevSites: (state, action) => {
      state.prevSites = action.payload;
    },
    deleteComparisonSite: (state, action) => {
      const comparisonId = action.payload;

      state.sites = state.sites.filter(({ siteId }) => siteId !== comparisonId);
      state.prevSites = state.prevSites.filter(
        ({ siteId }) => siteId !== comparisonId
      );
      state.sitesFull = state.sitesFull.filter(({ id }) => id !== comparisonId);
    },
    clearComparisonState: (state) => {
      state.uuid = "";
      state.sessionId = "";
      state.sites = [];
      state.prevSites = [];
      state.sitesFull = [];
    },
    setComparisonWidgetInfo: (state, action) => {
      state.uuid = action.payload?.uuid;
      state.sites = action.payload?.siteList;
      state.isDataLoaded = true;
    }
  },
  extraReducers: (builder) => {
    // getComparisonWidgetInfo
    builder.addCase(getComparisonWidgetInfo.pending, (state) => {
      state.isError = null;
      state.isLoading = true;
    });
    builder.addCase(getComparisonWidgetInfo.fulfilled, (state, action) => {
      state.isError = null;
      state.uuid = action.payload?.uuid;
      state.sites = action.payload?.siteList;
      state.isDataLoaded = true;
      state.isLoading = false;
    });
    builder.addCase(getComparisonWidgetInfo.rejected, (state, action) => {
      state.isError = action.payload;
      state.isLoading = false;
    });
    // updateComparsionSites
    builder.addCase(updateComparsionSites.pending, (state) => {
      state.isDeleting = true;
    });
    builder.addCase(updateComparsionSites.fulfilled, (state, action) => {
      state.uuid = action.payload?.uuid;
      state.sessionId = action.payload?.sessionId;
      state.sites = action.payload?.siteList;
      if (action.payload?.siteList?.length === 0) {
        state.uuid = "";
        state.sessionId = "";
        state.sites = [];
        state.prevSites = [];
        state.sitesFull = [];
      }
      state.isDeleting = false;
    });
    builder.addCase(updateComparsionSites.rejected, (state) => {
      state.isDeleting = false;
    });
  }
});

export const ComparisonReducerActions = {
  ...ComparisonReducer.actions,
  getComparisonWidgetInfo,
  updateComparsionSites
};
export default ComparisonReducer.reducer;
