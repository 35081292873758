import { useTranslation } from "react-i18next";

import {
  animationDnsLeakCheck,
  animationDnsLeakTest
} from "../../../../../assets/animations";
import { Rive } from "../../../../../components/ui/New/Rive/Rive";
import { Title } from "../../../../../components/ui/New/Title/Title";

import {
  AnimationContainer,
  Content,
  Description,
  DnsInfoSection,
  Image,
  Picture,
  RestylredContainer
} from "./DnsLeakTestInfo.styled";

export const DnsLeakTestInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <DnsInfoSection>
        <RestylredContainer>
          <Content>
            <Title tag="h2">{t("dnsLeak.whatIsDns.title")}</Title>
            <Description>{t("dnsLeak.whatIsDns.description")}</Description>
          </Content>
          <AnimationContainer>
            <Rive
              src={animationDnsLeakTest}
              autoPlay
              top={-59}
              right={-84}
              bottom={-50}
              left={-56}
            />
          </AnimationContainer>
          <Picture>
            <source
              srcSet="img/dns-leak-test/dns-leak-test-info-image.svg"
              media="(min-width: 993px)"
            />
            <source
              srcSet="img/dns-leak-test/dns-leak-test-info-image-mobile.svg"
              media="(max-width: 992px)"
            />
            <Image
              src="img/dns-leak-test/dns-leak-test-info-image.svg"
              alt={t("dnsLeak.whatIsDns.title")}
            />
          </Picture>
        </RestylredContainer>
      </DnsInfoSection>
      <DnsInfoSection light>
        <RestylredContainer light>
          <Picture>
            <source
              srcSet="img/dns-leak-test/dns-leak-test-info-image2.png"
              media="(min-width: 993px)"
            />
            <source
              srcSet="img/dns-leak-test/dns-leak-test-info-image-mobile2.png"
              media="(max-width: 992px)"
            />
            <Image
              src="img/dns-leak-test/dns-leak-test-info-image2.png"
              alt={t("dnsLeak.whatIsDns.title")}
            />
          </Picture>
          <AnimationContainer light>
            <Rive
              src={animationDnsLeakCheck}
              autoPlay
              top={-55}
              right={-75}
              bottom={-55}
              left={-75}
            />
          </AnimationContainer>
          <Content light>
            <Title tag="h2">{t("dnsLeak.whatDoesDns.title")}</Title>
            <Description>{t("dnsLeak.whatDoesDns.description")}</Description>
          </Content>
        </RestylredContainer>
      </DnsInfoSection>
    </>
  );
};
