import styled from "@emotion/styled";

export const StyledServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  padding: 16px;
  border-radius: 12px;
  box-shadow: ${(p) => p.theme.shadows.alterSiteMobile};
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    box-shadow: ${(p) => p.theme.shadows.alterSiteDesktop};
  }
`;
