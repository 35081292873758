import styled from "@emotion/styled";

import Container from "../Container/Container";

export const StyledSection = styled.div`
  width: 100%;
  background: ${(p) => p.theme.colors["background-color"]};
`;

export const StyledContent = styled.div`
  margin-right: 64px;
`;

export const StyledHeading = styled.h2`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.08;
  margin-bottom: 16px;
`;

export const StyledDescription = styled.p`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 32px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 60px;
  }

  a {
    text-decoration-line: underline;
  }
`;

export const StyledList = styled.div`
  width: 100%;
  margin-bottom: 36px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 80%;
    margin-bottom: 0;
  }
`;

export const StyledAnimationWrapper = styled.div`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 100%;
    max-width: ${(props) => (props.tools ? "478px" : "327px")};
    height: ${(props) => (props.tools ? "256px" : "537px")};
  }
  > div {
    @media (min-width: 1350px) {
      left: ${(props) => (props.tools ? "-66px" : "auto")};
    }
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px !important;
  padding-bottom: 64px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: flex-start;
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
`;

export const StyledImage = styled.picture`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const StyledCard = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const StyledCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  border-radius: 12px;
  background-color: ${(props) =>
    props.opened
      ? props.theme.colors["background-color"]
      : "rgba(255, 255, 255, 0.6)"};
  cursor: pointer;
  transition: all 0.3s ease;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 24px;
  }
`;

export const StyledQuestion = styled.div`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.66;
  margin-right: 8px;
`;

export const StyledAnswer = styled.div`
  max-height: ${(props) => (props.opened ? "1000px" : "0")};
  padding-top: 0;
  padding-bottom: ${(props) => (props.opened ? "24px" : "0")};
  padding-right: 64px;
  padding-left: 24px;
  color: ${(p) => p.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  visibility: ${(props) => (props.opened ? "visible" : "hidden")};
  overflow: hidden;
  transition: max-height 0.3s ease, padding-bottom 0.3s ease,
    visibility 0.3s ease;
`;

export const StyledIcon = styled.div`
  width: 24px;
  height: 24px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  position: relative;
  flex-shrink: 0;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    min-height: 2px;
    min-width: 16px;
    width: 16px;
    background: ${(p) => p.theme.colors["neutral-800"]};
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
  }
  &:before {
    transform: ${(props) =>
      props.opened
        ? "translateY(-50%) rotate(180deg)"
        : "translateY(-50%) rotate(90deg)"};
    transition: all 0.3s ease;
  }
`;
