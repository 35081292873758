import { useEffect, useState } from "react";

import moment from "moment";
import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { StyledBoxHidden, StyledSelect } from "../../../AddEditPromocode/Form/BlockDescriptions/BlockDescriptions.styled";
import { FormInput } from "../../../../../../components/forms/New/FormInput/FormInput";
import { FormCheckbox } from "../../../../../../components/forms/New/FormCheckbox/FormCheckbox";
import { FormDate } from "../../../../../../components/forms/New/FormDate/FormDate";
import { FormSwitch } from "../../../../../../components/forms/New/FormSwitch/FormSwitch";
import LabelCountry from "../../../../../../components/ui/New/LabelContry/LabelCountry";

import { useLangUrlDefault } from "../../../../../../hooks";
import FormBlock from "../../../components/FormBlock/FormBlock";
import {
  StyledBox,
  StyledCheckboxes,
  StyledDateBox,
  StyledLabel
} from "../../../AddEditPromocode/Form/BlockPeriod/BlockPeriod.styled";

import {
  StyledButtonText,
  StyledPromoBlockBody,
  StyledPromoReviewImage
} from "./BlockPromocode.styled";

// import styles from "./BlockPromocode.module.scss";

export const BlockPromocode = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const [lang, setLang] = useState(queryLang);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { errors } = useFormState();
  const { watch, setValue, getValues, clearErrors } = useFormContext();
  const { eternal, date, promocode, promocodeDescription } = watch();
  const handlerOpen = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  const options = [
    { value: "en", label: <LabelCountry code="en">English</LabelCountry> },
    { value: "ru", label: <LabelCountry code="ru">Russian</LabelCountry> },
    { value: "ua", label: <LabelCountry code="ua">Ukrainian</LabelCountry> }
  ];

  useEffect(() => {
    if (eternal === "eternal") {
      setDisabled(false);
      return;
    }
    if (date && eternal === "date") {
      const [, end] = date;
      if (end && end < new Date()) {
        if (getValues("isActive")) {
          setValue("isActive", false);
        }
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, eternal]);

  useEffect(() => {
    if (promocode?.length > 0 && !eternal) {
      setValue("eternal", "eternal");
      setValue("isActive", true);
      setValue("dateRange", [
        new Date(moment.utc().format()),
        new Date(moment.utc().add(7, "d").format())
      ]);
    } else if (promocode?.length === 0 && eternal) {
      setValue("eternal", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promocode]);

  useEffect(() => {
    const { promocodeDescription } = getValues();
    if (errors?.promocodeDescription?.type === "description-test") {
      const lang = options.find(
        (option) => !promocodeDescription?.[option.value]
      )?.value;
      if (lang) {
        setLang(lang);
      }
      return;
    }
    if (errors?.promocodeDescription?.type === "description-min") {
      const lang = options.find(
        (option) => promocodeDescription[option.value].length < 50
      )?.value;
      if (lang) {
        setLang(lang);
      }
      return;
    }
    if (errors?.promocodeDescription?.type === "description-max") {
      const lang = options.find(
        (option) => promocodeDescription[option.value].length > 100
      )?.value;
      if (lang) {
        setLang(lang);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (promocodeDescription) {
      const keys = Object.keys(promocodeDescription);
      if (
        (keys.every((key) => promocodeDescription[key].length > 0) &&
          errors?.promocodeDescription?.type === "description-test") ||
        (keys.every((key) => promocodeDescription[key].length >= 50) &&
          errors?.promocodeDescription?.type === "description-min") ||
        (keys.every((key) => promocodeDescription[key].length <= 100) &&
          errors?.promocodeDescription?.type === "description-max")
      ) {
        clearErrors("promocodeDescription");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promocodeDescription?.[lang], errors]);
  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.promocode.title")}
      subtitle={t("dashboard.services.addEdit.blocks.promocode.subtitle")}
    >
      <StyledPromoBlockBody>
        <FormInput
          name="promocode"
          label={t("forms.promocode.label")}
          placeholder={t("forms.promocode.placeholder")}
        />
        {promocode?.length > 0 && (
          <>
            <StyledSelect
              defaultValue={queryLang}
              value={lang}
              onChange={setLang}
              options={options}
              noMargin
              size={"sm"}
            />

            {options.map((option) => (
              <StyledBoxHidden
                hidden={lang !== option.value}
                key={option.value}
              >
                <FormInput
                  name={`promocodeDescription.${option.value}`}
                  label={t("forms.promocodeDescription.label")}
                  placeholder={t("forms.promocodeDescription.placeholder")}
                  type={"textarea"}
                  showMaxLenght={100}
                  showError
                  errorPath={"promocodeDescription"}
                />
              </StyledBoxHidden>
            ))}
            <StyledPromoBlockBody noGap>
              <StyledLabel>
                {t("dashboard.promocodes.addEdit.blocks.period.title")}
              </StyledLabel>
              <StyledBox>
                <StyledCheckboxes>
                  <FormCheckbox
                    name={"eternal"}
                    type={"radio"}
                    iconType={"checkbox"}
                    label={t("promocodes.eternal.label")}
                    radioValue={"eternal"}
                  />
                  <FormCheckbox
                    name={"eternal"}
                    label={t("dashboard.promocodes.addEdit.blocks.period.date")}
                    type={"radio"}
                    iconType={"checkbox"}
                    radioValue={"date"}
                  />
                </StyledCheckboxes>

                {eternal !== "eternal" && (
                  <StyledDateBox>
                    <FormDate name={"dateRange"} showError defa />
                  </StyledDateBox>
                )}
              </StyledBox>
            </StyledPromoBlockBody>
            <FormSwitch
              name={"isActive"}
              label={t("promocodes.isActive.label")}
              showError
              disabled={disabled}
            />
          </>
        )}
        <StyledButtonText
          color={"secondary"}
          open={open}
          iconRight={"arrowDown"}
          onClick={handlerOpen}
        >
          {t("dashboard.services.addEdit.blocks.promocode.show")}
        </StyledButtonText>
      </StyledPromoBlockBody>
      <StyledPromoReviewImage open={open}>
        <source
          srcSet={
            "/img/dashboard/promo-example-x1.png 1x, /img/dashboard/promo-example-x2.png 2x"
          }
          type="image/png"
        />
        <img src={"/img/dashboard/promo-example-x1.png"} alt={"no data"} />
      </StyledPromoReviewImage>
    </FormBlock>
  );
};
