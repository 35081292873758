import styled from "@emotion/styled";

export const StyledAlternativeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  margin: 0 -16px 0;
  width: calc(100% + 32px);

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    min-width: 350px;
    width: 420px;
    position: relative;

    border-radius: 12px 0 0 12px;

    margin: 0;
    padding: 0 0 16px 0;
    background: ${(p) => p.theme.colors.white};
    box-shadow: ${(p) => p.theme.shadows.card};

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 32px;
      right: 46px;

      height: 60px;

      background: linear-gradient(
        8deg,
        #fff 6.47%,
        rgba(255, 255, 255, 0) 94.04%
      );
    }
  }
`;

export const StyledAlternativeTitle = styled.h3`
  margin: 0 16px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 121%;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    margin: 32px 32px 0;
    font-size: ${(p) => p.theme.fontSizes.xll};
    line-height: 119%;
  }
`;

export const StyledServiceMobileBox = styled.div`
  display: block;
  & > div[class*="swiper"] {
    padding: 16px 16px 64px 16px !important;
  }
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    display: none;
  }
  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

export const StyledServiceDesktopBox = styled.div`
  display: none;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    display: block;
    position: relative;
    overflow-y: auto;
    height: 100%;
    margin-right: 16px;
  }
`;

export const StyledServiceDesktopList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 24px;

  list-style: none;
  margin: 0;
  position: absolute;
  width: 100%;
  padding: 0;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    padding: 0 14px 0 32px;
  }
`;
