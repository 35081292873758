import styled from "@emotion/styled";

export const StyledStatusCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  max-width: 100%;

  & svg {
    width: 24px;
    height: 24px;
    color: #c5cdd9;

    &:hover,
    &:focus {
      color: ${(p) => p.theme.colors["main-700"]};
    }
  }
`;
