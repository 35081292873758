import styled from "@emotion/styled";

import { SpriteSVG } from "./SpriteSVG";

const SIZE = {
  sm: 16,
  md: 20,
  lg: 24
};

export const IconStyled = styled(SpriteSVG)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  ${(p) =>
    p.size && SIZE?.[p.size]
      ? `width: ${SIZE[p.size]}px; height: ${SIZE[p.size]}px;`
      : ""}

  ${(p) => (p.disabled ? "pointer-events: none;" : "")}

${(p) => {
    if (p.color === "primary") {
      return `
        path {
          fill: #C8EE85;
      stroke: #C8EE85;
        }
      `;
    }
    if (p.color === "secondary") {
      return `
        path {
          fill: #2A2A2D;
        }
      `;
    }
    return "";
  }}
`;
