import { useEffect } from "react";

import { useSelector } from "react-redux";

import { getAllSite } from "../../../../store/reducers/SiteReducer/Site.selectors";

import { useDispatchedActions } from "../../../../hooks";

import { throttle } from "../../../../utils/helpers";

import BlogNavItem from "./BlogNavItem/BlogNavItem";
import { Overlay } from "./Overlay/Overlay";
import { ProxiesNavigation } from "./ProxiesNavigation/ProxiesNavigation";
import { StyledNavDesktop } from "./BurgerMenu.styled";

export const ServiceMenu = () => {
  const { isServiceMenuOpened } = useSelector(getAllSite);

  // Dispatch
  const { setServiceMenu } = useDispatchedActions();

  useEffect(() => {
    const handleResize = throttle(() => {
      if (window.innerWidth < 993) {
        setServiceMenu(false);
      }
    }, 500);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setServiceMenu]);

  return (
    <Overlay isOpened={isServiceMenuOpened} setIsOpened={setServiceMenu}>
      <StyledNavDesktop>
        <ProxiesNavigation />
        <BlogNavItem />
      </StyledNavDesktop>
    </Overlay>
  );
};
