import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "../../../../../../components/ui/New/Button/Button";
import { StyledSkeleton } from "../../../../../../components/ui/New/Skeleton/components/SkeletonTableComponents.styled";

import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";

import { useLangUrlDefault } from "../../../../../../hooks";

import { getSiteDescription } from "../../../../../../utils/helpers";

import {
  StyledAboutButtonBox,
  StyledAboutContainer,
  StyledAboutDescription,
  StyledAboutTitle,
  StyledAboutTitleBox
} from "./HeadAbout.styled";

export const HeadAbout = ({ onChange }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const {
    site: { data, isDataLoaded }
  } = useSelector(getAllProxySite);

  const handleReadReview = () => onChange?.("reviews");

  return (
    <StyledAboutContainer>
      <StyledAboutTitleBox>
        <StyledAboutTitle>
          {isDataLoaded ? (
            `${t("proxySitePage.about.title")} ${data?.title}`
          ) : (
            <StyledSkeleton width={250} />
          )}
        </StyledAboutTitle>
        <StyledAboutButtonBox>
          {isDataLoaded ? (
            <>
              <Button
                variant={"outlined"}
                size={"md"}
                onClick={handleReadReview}
              >
                {t("proxySitePage.about.readReview")}
              </Button>
              <Button size={"md"} linkTo={data?.url} target="_blank">
                {t("proxySitePage.about.visit")}
              </Button>
            </>
          ) : (
            <>
              <StyledSkeleton width={140} height={40} />
              <StyledSkeleton width={140} height={40} />
            </>
          )}
        </StyledAboutButtonBox>
      </StyledAboutTitleBox>

      {isDataLoaded ? (
        <>
          {getSiteDescription(data?.description, queryLang) ? (
            <StyledAboutDescription>
              {getSiteDescription(data?.description, queryLang)}
            </StyledAboutDescription>
          ) : null}
        </>
      ) : (
        <StyledAboutDescription>
          <StyledSkeleton count={3} />
        </StyledAboutDescription>
      )}
    </StyledAboutContainer>
  );
};
