import { createSlice } from "@reduxjs/toolkit";

// Thunks
import {
  getAllPromocodes,
  getAlternatePromocodes,
  getPromocode,
  getSpecialPromocodes
} from "./Promocodes.thunks";

const initialState = {
  special: {
    data: [],
    isLoading: false,
    isError: null,
    isDataLoaded: false
  },
  promocodes: {
    data: {
      totalPages: 0,
      totalElements: 0,
      content: []
    },
    isLoading: false,
    isError: null,
    prevParams: null
  },
  promocode: {
    data: {
      alternatePromocodes: [],
      description: null,
      endDate: null,
      eternal: false,
      id: "",
      imageUrl: "",
      isActive: true,
      promocode: "",
      siteId: "",
      siteName: "",
      startDate: ""
    },
    isLoading: false,
    isError: null
  },
  alternatePromocodes: {
    data: [],
    isLoading: false,
    isError: null,
    isDataLoaded: false
  }
};

const PromocodesReducer = createSlice({
  name: "promocodes",
  initialState,
  reducers: {
    setPromocodesPrevParams(state, action) {
      state.promocodes.prevParams = action.payload;
    },
    setAllPromocodes(state, action) {
      state.promocodes.data = action.payload;
    },
    setAlternatePromocodes(state, action) {
      state.alternatePromocodes.data = action.payload;
      state.alternatePromocodes.isDataLoaded = true;
    },
    setPromocode(state, action) {
      state.promocode.data = action.payload;
    },
    setSpecialPromocodes(state, action) {
      state.special.data = action.payload;
      state.special.isDataLoaded = true;
    }
  },
  extraReducers: (builder) => {
    // getAllPromocodes
    builder.addCase(getAllPromocodes.pending, (state) => {
      state.promocodes.isError = null;
      state.promocodes.isLoading = true;
    });
    builder.addCase(getAllPromocodes.fulfilled, (state, action) => {
      state.promocodes.isError = null;
      state.promocodes.data = action.payload;
      state.promocodes.isLoading = false;
    });
    builder.addCase(getAllPromocodes.rejected, (state, action) => {
      state.promocodes.isError = action.payload;
      state.promocodes.isLoading = false;
    });
    // getPromocode
    builder.addCase(getPromocode.pending, (state) => {
      state.promocode.isError = null;
      state.promocode.isLoading = true;
    });
    builder.addCase(getPromocode.fulfilled, (state, action) => {
      state.promocode.isError = null;
      state.promocode.data = action.payload;
      state.promocode.isLoading = false;
    });
    builder.addCase(getPromocode.rejected, (state, action) => {
      state.promocode.isError = action.payload;
      state.promocode.isLoading = false;
    });
    // getAlternatePromocodes
    builder.addCase(getAlternatePromocodes.pending, (state) => {
      state.alternatePromocodes.isError = null;
      state.alternatePromocodes.isLoading = true;
    });
    builder.addCase(getAlternatePromocodes.fulfilled, (state, action) => {
      state.alternatePromocodes.isError = null;
      state.alternatePromocodes.data = action.payload;
      state.alternatePromocodes.isLoading = false;
    });
    builder.addCase(getAlternatePromocodes.rejected, (state, action) => {
      state.alternatePromocodes.isError = action.payload;
      state.alternatePromocodes.isLoading = false;
    });
    // getSpecialPromocodes
    builder.addCase(getSpecialPromocodes.pending, (state) => {
      state.special.isError = null;
      state.special.isLoading = true;
    });
    builder.addCase(getSpecialPromocodes.fulfilled, (state, action) => {
      state.special.isError = null;
      state.special.data = action.payload;
      state.special.isLoading = false;
      state.special.isDataLoaded = true;
    });
    builder.addCase(getSpecialPromocodes.rejected, (state, action) => {
      state.special.isError = action.payload;
      state.special.isLoading = false;
    });
  }
});

export const PromocodesReducerActions = {
  ...PromocodesReducer.actions,
  getAllPromocodes,
  getPromocode,
  getAlternatePromocodes,
  getSpecialPromocodes
};
export default PromocodesReducer.reducer;
