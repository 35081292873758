import { useTranslation } from "react-i18next";

import { FormInput } from "../../../../../components/forms/New/FormInput/FormInput";

import FormBlock from "../../components/FormBlock/FormBlock";

export const BlockPromocode = () => {
  const { t } = useTranslation();

  return (
    <FormBlock
      title={t("dashboard.promocodes.addEdit.blocks.promocode.title")}
      subtitle={t("dashboard.promocodes.addEdit.blocks.promocode.subtitle")}
    >
      <FormInput
        name="promocode"
        label={t("promocodes.promocode.label")}
        showError
        placeholder={t("promocodes.promocode.placeholder")}
      />
    </FormBlock>
  );
};
