import PT from "prop-types";
import { useTranslation } from "react-i18next";

import Icon from "../../../../ui/New/Icon/Icon";

import { StyledSidebarButton } from "./SidebarButton.styled";

export const SidebarButton = ({ isActive, onClick }) => {
  const { t } = useTranslation();

  return (
    <StyledSidebarButton isOpen={isActive} onClick={onClick} type="button">
      {t("proxyPage.sidebar.promocodes.title")}
      <Icon name="arrowDown" />
    </StyledSidebarButton>
  );
};

SidebarButton.propTypes = {
  isActive: PT.bool,
  onClick: PT.func
};

SidebarButton.defaultProps = {
  isActive: false,
  onClick: () => null
};

export default SidebarButton;
