import styled from "@emotion/styled";

import Container from "../../../../../../components/common/New/Container/Container";

export const StyledSection = styled.div`
  background-color: white;
`;

export const StyledContainer = styled(Container)`
  padding-top: 64px !important;
  padding-bottom: 64px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
`;
