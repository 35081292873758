import FeedbackPage from "../../pages/SidebarPages/FeedbackPage/FeedbackPage";
import ProxyPage from "../../pages/SidebarPages/ProxyPage/ProxyPage";
import ProxySitePage from "../../pages/SidebarPages/ProxySitePage/ProxySitePage";

export const SidebarRoutesOLD = (ssr) => [
  {
    path: "/:lang?/feedback/",
    tag: "feedback",
    type: "sidebar",
    component: ssr ? FeedbackPage : () => import("../../pages/SidebarPages/FeedbackPage/FeedbackPage"),
    isHeaderFull: true,
    isAuthRequired: false,
    isSsr: true
  },

  {
    path: "/:lang?/proxy/",
    tag: "proxy",
    type: "sidebar",
    component: ssr ? ProxyPage : () => import("../../pages/SidebarPages/ProxyPage/ProxyPage"),
    isHeaderFull: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/proxy/:alias/",
    tag: "proxy-site",
    type: "sidebar",
    component: ssr ? ProxySitePage : () => import("../../pages/SidebarPages/ProxySitePage/ProxySitePage"),
    isHeaderFull: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/proxy/:alias/promocodes/",
    tag: "proxy-site-promo",
    type: "sidebar",
    component: ssr ? ProxySitePage : () => import("../../pages/SidebarPages/ProxySitePage/ProxySitePage"),
    isHeaderFull: true,
    isAuthRequired: false,
    isSsr: true
  }
];
