import { useTranslation } from "react-i18next";

import { animationTestPort } from "../../../../../../assets/animations";
import {
  portScannerCTAImageMobile,
  portScannerCTAImageMobile2x
} from "../../../../../../assets/img";
import Button from "../../../../../../components/ui/New/Button/Button";

import {
  StyledAnimation,
  StyledAnimationWrapper,
  StyledContainer,
  StyledContentWrapper,
  StyledDescription,
  StyledImage,
  StyledTitle
} from "./CTA.styled";

export const CTA = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledAnimationWrapper>
        <StyledAnimation
          src={animationTestPort}
          autoPlay
          stateMachines="State Machine 1"
        />
      </StyledAnimationWrapper>
      <picture>
        {/* <source
          srcSet={`${portScannerCTAImage} 1x, ${portScannerCTAImage2x} 2x`}
          media="(min-width: 993px)"
        /> */}
        <source
          srcSet={`${portScannerCTAImageMobile} 1x, ${portScannerCTAImageMobile2x} 2x`}
          // media="(max-width: 992px)"
        />
        <StyledImage
          src={portScannerCTAImageMobile}
          alt={t("portScanner.cta.title")}
        />
      </picture>
      <StyledContentWrapper>
        <StyledTitle tag="h2">{t("portScanner.cta.title")}</StyledTitle>
        <StyledDescription>
          {t("portScanner.cta.description")}
        </StyledDescription>
        <Button>{t("portScanner.cta.buttonLabel")}</Button>
      </StyledContentWrapper>
    </StyledContainer>
  );
};
