import * as yup from "yup";

import { EMAIL_REGEX, EMAIL_STAGE_REGEX } from "../constants";

export const CommentSchema = (trns) =>
  yup.object().shape(
    {
      name: yup.string().when("name", (val, schema) => {
        if (typeof val === schema.type) {
          return yup.string().required(trns.field.rules.required);
        } else {
          return yup.string().notRequired();
        }
      }),
      email: yup.string().when("email", (val, schema) => {
        if (typeof val === schema.type) {
          return yup
            .string()
            .required(trns.email.rules.required)
            .matches(
              process.env?.REACT_APP_MODE === "prod"
                ? EMAIL_REGEX
                : EMAIL_STAGE_REGEX,
              trns.email.rules.email
            );
        } else {
          return yup.string().notRequired();
        }
      }),
      body: yup.string().required(trns.field.rules.required)
    },
    [
      ["name", "name"],
      ["email", "email"]
    ]
  );

export const CommentSchemaNew = (trns, isAuth) =>
  yup.object().shape({
    ...(!isAuth
      ? {
          name: yup.string().required(trns.field.rules.required),
          email: yup
            .string()
            .trim()
            .required(trns.email.rules.required)
            .matches(
              /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,4}$/i,
              trns.email.rules.email
            )
        }
      : {}),
    body: yup.string().required(trns.field.rules.required)
  });
