import styled from "@emotion/styled";

export const StyledFormBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
  }
`;

export const StyledFormBlockLabel = styled.div`
display: flex;
    flex-direction: column;
    gap: 8px;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 40%;
  }

   & > h3 {
   color: ${(p) => p.theme.colors["neutral-800"]};
    font-size: ${(p) => p.theme.fontSizes.m};
    font-weight: ${(p) => p.theme.fontWeight.bold};
    margin: 0;
    line-height: 100%;
}
    & > p {
    color: ${(p) => p.theme.colors["neutral-400"]};
    font-size: ${(p) => p.theme.fontSizes.s};
    line-height: 140%;
    margin: 0;
    `;

export const StyledFormBlockBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(p) =>
    p.bodyType === "buttons"
      ? `
    gap: 12px;
    `
      : ""}

  ${(p) =>
    p.bodyType === "buttons"
      ? `@media (min-width: 480px) {
    flex-direction: row;
    flex-wrap: wrap;
  }`
      : ""}

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 60%;
    margin-left: auto;

    ${(p) =>
      p.withoutTitle
        ? `
        width: calc(60% - 12px);
        `
        : ""}
  }
`;
