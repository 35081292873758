import styled from "@emotion/styled";

export const StyledReviewCard = styled.div`
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
  width: 100%;

  background-color: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) =>
    !p.isDashboard ? "0px 15px 46px 0px rgba(135, 140, 189, 0.08)" : "none"};
  border: ${(p) =>
    p.isDashboard ? "1px solid rgba(216, 218, 224, 0.55)" : "none"};

  @media (min-width: ${(p) =>
      p.isDashboard ? p.theme.screenSizes.lgMin : p.theme.screenSizes.xlMin}) {
    padding: 24px;
  }
`;

export const StyledReviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
`;

export const StyledReviewHeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || "flex-start"};
  gap: 8px;
`;

export const StyledReviewSubInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 16px;
  margin-bottom: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
  }
`;

export const StyledReviewSubInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledReviewFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledReviewFooterBox = styled.div`
  display: flex;
  gap: 8px;
`;
