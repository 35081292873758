import styled from "@emotion/styled";

import Container from "../../../../../components/common/New/Container/Container";
import { Title } from "../../../../../components/common/New/Languages/Languages.styled";
import Button from "../../../../../components/ui/New/Button/Button";

export const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  margin-top: -35px !important;
  padding-bottom: 64px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: -165px !important;
    padding-bottom: 120px !important;
  }
`;

export const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 5px 28px 0px rgba(135, 140, 189, 0.08);
  padding: 16px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 36px;
    margin-top: -140px;
    flex-direction: row;
    min-height: 405px;
  }
`;

export const StyledForm = styled.div`
  background: ${({ theme }) => theme.colors["background-color"]};
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 47%;
    max-width: 360px;
    min-width: 300px;
    margin-right: 36px;
    margin-left: 0;
    margin-bottom: 0;
    padding: 24px;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 1.21 !important;
  margin-bottom: 16px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    font-size: 32px !important;
    line-height: 1.18 !important;
    margin-bottom: 24px !important;
  }
`;

export const StyledInputWrapper = styled.div`
  margin-bottom: 24px;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 12px;
`;

export const StyledInfoMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;

  img {
    margin-right: 12px;
  }
`;

export const StyledAdditionalText = styled.button`
  padding-top: 5px;
  color: ${(p) => p.theme.colors["main-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 115%;
  cursor: pointer;

  &::after {
    content: "";
    position: relative;
    top: -1px;
    width: 100%;
    height: 1px;
    display: block;
    background: ${({ theme }) => theme.colors["main-600"]};
  }
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
  }
`;

export const StyledListWrapper = styled.div`
  width: 100%;
`;

export const StyledResults = styled.div`
  width: 100%;
  display: ${(p) => (p.isLoading ? "flex" : "block")};
  justify-content: center;
  align-items: center;
  height: 324px;
  border-radius: 12px;
  border: 1px solid #d8dae0;
  padding: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    height: 213px;
  }
`;

export const StyledResultsContent = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

export const StyledResultsItem = styled.div`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 4px;

  & span {
    color: ${(p) =>
      p.variant === "listed"
        ? p.theme.colors["red-500"]
        : p.theme.colors["main-700"]};
  }
`;
