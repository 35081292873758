import styled from "@emotion/styled";

import Container from "../../../../../components/common/New/Container/Container";

export const FingerprintSection = styled.section`
  background-color: ${({ theme }) => theme.colors["background-color-seo"]};
`;

export const FingerprintContainer = styled(Container)`
  padding-top: 64px;
  padding-bottom: 64px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`;
