import { useTranslation } from "react-i18next";

import {
  ResultItem,
  ResultItemLabel,
  ResultItemValue,
  ResultList
} from "./ResultTable.styled";

export const ResultsTable = ({ data }) => {
  const { t } = useTranslation();

  const renderBoolValue = (key) => {
    if (data[key]) {
      return (
        <>
          <img src="img/icons/check-green.svg" alt="" />
          <span>{t("ui.yesNo.yes")}</span>
        </>
      );
    } else {
      return (
        <>
          <img src="img/icons/no.svg" alt="" />
          <span>{t("ui.yesNo.no")}</span>
        </>
      );
    }
  };

  const Data = [
    {
      key: "provider",
      value: data?.provider || "Triolan",
      src: "img/icons/provider.svg",
      alt: t("myAnonymity.table.provider")
    },
    {
      key: "hostname",
      value: data?.hostname || "155.15.6.225.triolan.net",
      src: "img/icons/hostname.svg",
      alt: t("myAnonymity.table.hostname")
    },
    {
      key: "platform",
      value: data?.platform,
      src: "img/icons/os.svg",
      alt: t("myAnonymity.table.os")
    },
    {
      key: "app",
      value: data?.app,
      src: "img/icons/browser.svg",
      alt: t("myAnonymity.table.browser")
    },
    {
      key: "ipAddress",
      value: data.ipAddress,
      src: "img/icons/dns.svg",
      alt: t("myAnonymity.table.dns")
    },
    {
      key: "proxy",
      value: renderBoolValue("proxy"),
      src: "img/icons/proxy.svg",
      alt: t("myAnonymity.table.proxy")
    },
    {
      key: "anonymizer",
      value: renderBoolValue("anonymizer"),
      src: "img/icons/anonymizer.svg",
      alt: t("myAnonymity.table.anonymizer")
    },
    {
      key: "blacklist",
      value: renderBoolValue("blacklist"),
      src: "img/icons/blacklist.svg",
      alt: t("myAnonymity.table.blacklist")
    }
  ];

  return (
    <ResultList>
      {Data.map((item) => (
        <ResultItem key={item.key}>
          <ResultItemLabel>
            <img src={item.src} alt={item.alt} width="24px" height="24px" />
            <span>{item.alt}</span>
          </ResultItemLabel>
          <ResultItemValue>{item.value}</ResultItemValue>
        </ResultItem>
      ))}
    </ResultList>
  );
};
