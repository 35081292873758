import styled from "@emotion/styled";

export const StyledHeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.center ? "center" : "flex-start")};
  gap: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #a5a5a5;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors["neutral-400"]};
  opacity: 1 !important;

  & [role="tooltip"] {
    opacity: 1 !important;
  }

  ${(p) =>
    p.propMinWidth ? `min-width: ${p.propMinWidth}px; max-width: 100%;` : ""}
  ${(p) =>
    p.propWidth
      ? `min-width: ${p.propWidth}px; max-width: ${p.propWidth}px;`
      : ""}
      ${(p) => (p.propMaxWidth ? `max-width: ${p.propMaxWidth}px;` : "")}
`;

export const StyledHeaderNameCell = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: uppercase;
  white-space: nowrap;
  max-width: 100%;
  margin: 0;
  padding: 0;
`;

export const StyledMenuContainer = styled.div`
  overflow: auto;
  margin-top: 10px;
  min-width: 250px;
  max-height: 300px;
`;

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const StyledListItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover,
  &:focus,
  $:focus-within,
  &:active {
    border-radius: 8px;
    background-color: rgba(246, 247, 249, 0.8);
  }

  ${(p) =>
    p.selected
      ? `
   border-radius: 8px;
  background-color: rgba(246, 247, 249, 0.8);
  `
      : ""}
`;

export const StyledCheckboxLabel = styled.span`
  font-size: ${(p) => p.theme.fontSizes.m};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 150%;
`;

export const StyledNodata = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
`;
