import {
  CopyrightDesktop,
  CopyrightMobile,
  FooterInner,
  LogoWrapper,
  RestyledLanguages,
  RestyledLogo,
  StyledFooter
} from "./Footer.styled";
import { Navigation } from "./Navigation/Navigation";

export const Footer = () => {
  const year = new Date().getFullYear();
  const COPYRIGHT_TEXT = `TrustyTech©${year} | All Rights Reserved`;

  return (
    <StyledFooter>
      <FooterInner>
        <LogoWrapper>
          <RestyledLogo />
          <RestyledLanguages isMobile />
          <CopyrightDesktop>{COPYRIGHT_TEXT}</CopyrightDesktop>
        </LogoWrapper>
        <Navigation />
        <RestyledLanguages />
        <CopyrightMobile>{COPYRIGHT_TEXT}</CopyrightMobile>
      </FooterInner>
    </StyledFooter>
  );
};
