import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { $api } from "../../../services/AxiosInstance";

import { Modal } from "../../../components/common";
import { AuthInput } from "../../../components/forms/AuthInput/AuthInput";
import { Button } from "../../../components/ui/Button/Button";
import { Loader } from "../../../components/ui/Loader/Loader";
import { Typography } from "../../../components/ui/Typography/Typography";
import { useDispatchedActions, useLangUrlDefault } from "../../../hooks";
import { ApiService } from "../../../services";
import { AllActions } from "../../../store/reducers/AllActions";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { LoginSchema } from "../../../utils/validation";
import { Authorization } from "../components/Authorization/Authorization";

import "../Auth.scss";

const LoginPage = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // **Redux state
  const { t } = useTranslation();
  const {
    pageContent: { current }
  } = useSelector(getAllContent);

  // **Local state
  const [adminUser, setAdminUser] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isInProcess, setIsInProcess] = useState(true);

  // Dispatch
  const { setUser, setAuthentication, setIsRedirectToCabinet } =
    useDispatchedActions();

  // Form
  const methods = useForm({
    resolver: yupResolver(LoginSchema(t("forms", { returnObjects: true })))
  });

  const getAdminUser = async () => {
    try {
      const response = await ApiService.getAdminUser();

      if (response && response.status !== 200) {
        throw response;
      }

      if (!response?.data) {
        setAdminUser(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      toast.error(t("notifications.apiError"));
    }
  };

  const createDefaultUser = async (data) => {
    try {
      const response = await ApiService.createDefaultUser(
        data.email,
        data.password
      );

      if (response && response.status !== 200) {
        throw response;
      }

      window.location.reload();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      toast.error(t("notifications.apiError"));
    }
  };

  const modalHandler = () => {
    searchParams.delete("token");
    setIsModalOpened(false);

    if (!isConfirmed) {
      navigate(`${hrefLang}/registration/`);
    }
  };

  const checkToken = async () => {
    try {
      const response = await ApiService.confirmEmail(searchParams.get("token"));

      if (response && response.status !== 200) {
        throw response;
      }

      if (response.data) {
        setIsConfirmed(true);
      } else {
        setIsConfirmed(false);
      }
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsInProcess(false);
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await ApiService.login(data.email, data.password);

      if (response && response.status !== 200) {
        if (response.response.status === 401) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response,
            message: t(`notifications.authError.${response.response.status}`)
          };
        }

        if (response.response.status === 561) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response,
            message: t(`notifications.authError.${response.response.status}`)
          };
        }

        if (response.response.status === 560) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response,
            message: t(`notifications.authError.${response.response.status}`)
          };
        }

        if (response.response.status === 543) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response,
            message: t(`notifications.authError.${response.response.status}`)
          };
        }

        throw response;
      }

      const userInfo = await ApiService.getUserAuth();

      if (userInfo && userInfo.status !== 200) {
        throw response;
      }

      setUser(userInfo?.data);
      setAuthentication(true);
      const pathname = searchParams.get("pathname");
      if (pathname) {
        navigate(pathname || `${hrefLang}/`);
      } else {
        setIsRedirectToCabinet(true);
        navigate(`${hrefLang}/cabinet/proxy/`);
      }

    } catch (err) {
      toast.error(err?.message || t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get("token")) {
      setIsModalOpened(true);

      checkToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    getAdminUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Authorization isTabsShown title={current?.data?.h1}>
        <div className="auth__form-wrapper">
          <FormProvider {...methods}>
            <form
              className="auth__form"
              onSubmit={methods.handleSubmit(
                adminUser ? onSubmit : createDefaultUser
              )}
            >
              <AuthInput
                placeholder={t("forms.email.label")}
                type="email"
                name="email"
                showError
              />
              <AuthInput
                placeholder={t("forms.password.placeholder")}
                type="password"
                name="password"
                showError
              />
              <Button
                disabled={isLoading}
                size="auth"
                position="center"
                btnType={"submit"}
                type="solid"
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {!isLoading ? (
                  adminUser ? (
                    t("authPages.auth.btn")
                  ) : (
                    "Register"
                  )
                ) : (
                  <Loader
                    type="absolute"
                    scale={0.6}
                    color="var(--clr-default-900)"
                  />
                )}
              </Button>
              <Link to={`${hrefLang}/recovery/`} className="auth__recovery">
                {t("authPages.passRecovery")}
              </Link>
            </form>
          </FormProvider>
        </div>
      </Authorization>
      <Modal visible={isModalOpened} cancelHandler={modalHandler}>
        <Typography
          size="large"
          weight="semibold"
          padding="bottom-large"
          position="center"
        >
          {t("modals.email.title")}
        </Typography>
        {isInProcess ? (
          <Loader />
        ) : (
          <p style={{ textAlign: "center" }}>
            {isConfirmed
              ? t("modals.email.confirmed")
              : t("modals.email.notConfirmed")}
          </p>
        )}
      </Modal>
    </>
  );
};

export default LoginPage;

LoginPage.getServerSideState = async (store, params) => {
  const { data: pageContent } = await $api.post(
    "/api/page/front/content",
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.locale,
      innerTag: params.location
    })
  );
};
