import PT from "prop-types";

import Icon from "../../../ui/New/Icon/Icon";

import { ErrorCounter } from "./FormError.styled";

export const FormError = ({ message }) => (
  <ErrorCounter>
    <Icon name="inputError" />
    {message}
  </ErrorCounter>
);

FormError.propTypes = {
  message: PT.string.isRequired
};
