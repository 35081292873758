/* eslint-disable no-nested-ternary */
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import animation from "../../../../assets/animations/free_proxy.riv";
import animationFaq from "../../../../assets/animations/how_we_can_help_you.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  freeProxyHeroImageMobile,
  freeProxyHeroImageMobile2x
} from "../../../../assets/img";
import SeoSection from "../../../../components/common/New/SeoSection/SeoSection";
import { Rive } from "../../../../components/ui/New/Rive/Rive";
import { $api } from "../../../../services/AxiosInstance";
import { AllActions } from "../../../../store/reducers/AllActions";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { getCountryId } from "../../../../utils/helpers";
import { getCityId } from "../../../../utils/helpers/getCityId.helpers";

import { FreeProxyInfo } from "./FreeProxyInfo/FreeProxyInfo";
import { FreeProxyList } from "./FreeProxyList/FreeProxyList";
import {
  FreeProxySection,
  RestyledFAQ,
  RestyledMainSection
} from "./FreeProxyPage.styled";

const FreeProxyPage = () => {
  const { t } = useTranslation();

  // **Redux state
  const {
    pageContent: { current }
  } = useSelector(getAllContent);

  return (
    <FreeProxySection>
      <RestyledMainSection
        title={t("freeProxy.main.title")}
        subtitleHtml={t("freeProxy.main.subtitle")}
        button={false}
        images={{
          mobile1x: freeProxyHeroImageMobile,
          mobile2x: freeProxyHeroImageMobile2x
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-45}
            right={-1}
            bottom={-12}
            left={-83}
          />
        }
      />
      <FreeProxyList />
      <FreeProxyInfo />
      <RestyledFAQ
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        animation={animationFaq}
      />
      <SeoSection content={current?.data?.seo} />
    </FreeProxySection>
  );
};

export default FreeProxyPage;

FreeProxyPage.getServerSideState = async (store, params) => {
  const {
    content: { countries }
  } = store.getState();

  const countryId = getCountryId(countries.data, params?.params?.country)?.id;

  const { data: cities } = await $api.get("/api/front/cities", {
    params: { countryId }
  });

  const cityId = getCityId(cities, params?.params?.city)?.id;

  const fetchParams = {
    page: 0,
    pageSize: 10,
    countryIds: countryId ? [countryId] : [],
    type: params?.params?.type || "",
    cityIds: cityId ? [cityId] : [],
    google:
      params?.params?.google === "yes"
        ? "true"
        : params?.params?.google === "no"
        ? "false"
        : ""
  };

  const { data: tableData } = await $api.post(
    "/api/front/proxy/free",
    fetchParams
  );
  const { data: allData } = await $api.post("/api/front/proxy/free/all", {
    countryIds: fetchParams.countryIds,
    type: fetchParams.type,
    cityIds: fetchParams.cityIds,
    google: fetchParams.google
  });

  const { data: faqData } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });

  if (tableData) {
    store.dispatch(AllActions.setFreeProxyContent(tableData));
  }
  if (allData) {
    store.dispatch(AllActions.setAllFreeProxyContent(allData));
  }
  if (faqData) {
    store.dispatch(
      AllActions.setFaqContent({
        data: faqData,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
  if (cities) {
    store.dispatch(AllActions.setCities(cities));
  }
};
