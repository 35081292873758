import styled from "@emotion/styled";

export const AlertStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 15px;
  border-radius: 8px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};

  border: 1px solid;
  border-color: ${(p) => {
    switch (p.type) {
      case "success":
        return p.theme.colors["main-500"];
      case "warning":
        return "#fcd28c";
      case "error":
        return "#ffa7a0";
      default:
        return "#a7c5fd";
    }
  }};

  background-color: ${(p) => {
    switch (p.type) {
      case "success":
        return "#fcfff5";
      case "warning":
        return "#fffbed";
      case "error":
        return "#fff6f5";
      default:
        return "#f5f9ff";
    }
  }};

  & > svg {
    margin-top: 15px;
    width: 20px;
    height: 20px;
    flex: none;
    margin-right: 8px;
    color: ${(p) => {
      switch (p.type) {
        case "success":
          return p.theme.colors["main-600"];
        case "warning":
          return p.theme.colors.orange;
        case "error":
          return p.theme.colors["red-500"];
        default:
          return p.theme.colors.blue;
      }
    }};
  }

  ${(p) => (p.close ? "display: none;" : "")}
`;

export const SltyledClose = styled.button`
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 4px;
  margin-top: 9px;
  margin-left: 16px;
  border: none;
  background: none;
  outline: none;

  & svg {
    width: 24px;
    height: 24px;
    color: ${(p) => p.theme.colors["neutral-800"]};
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 15px 0;
`;

export const StyledText = styled.div`
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 140%;
`;

export const StyledTitle = styled.strong`
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 140%;

  text-transform: capitalize;
  margin-bottom: 12px;
`;
