import styled from "@emotion/styled";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledHelpText = styled.p`
  color: ${(p) => p.theme.colors["neutral-500"]};

  margin-top: 5px;
  margin-bottom: 0;

  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 115%;
`;
