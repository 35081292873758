import { $api } from "../../../services/AxiosInstance";

import { AllActions } from "../../../store/reducers/AllActions";

import { Comparison } from "./Comparison/Comparison";

const ComparisonPage = () => (
  <>
    <Comparison />
  </>
);

export default ComparisonPage;

ComparisonPage.getServerSideState = async (store, params) => {
  const [, identifier] = params?.pathname.split("/").reverse();

  const { data: goalsByGroups } = await $api.get(
    "/api/front/goal/group/all",
    { params: { languageCode: params.locale } }
  );
  const { data: goals } = await $api.get("/api/goal/front/all", {
    params: { params: { languageCode: params.locale } }
  });
  const { data: comparison } = await $api.get(
    "/api/front/site/proxy/comparison",
    { params: { uuid: identifier } }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(AllActions.setGoals(goals));
  store.dispatch(AllActions.setFullSites(comparison));
};
