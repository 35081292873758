import PT from "prop-types";

import { StyledSkeleton } from "../../../ui/New/Skeleton/components/SkeletonTableComponents.styled";

import { Title } from "../../../ui/New/Title/Title";

import { RestyledSubtitle, WrapperStyled } from "./SectionHead.styled";

export const SectionHead = ({
  title,
  tag,
  description,
  className,
  loading
}) => (
  <WrapperStyled className={className}>
    <Title tag={tag} className={"section-title"}>
      {loading ? <StyledSkeleton /> : title}
    </Title>
    {(description || loading) && (
      <RestyledSubtitle className={"section-desctiption"}>
        {loading ? <StyledSkeleton count={3} /> : description}
      </RestyledSubtitle>
    )}
  </WrapperStyled>
);

SectionHead.propTypes = {
  title: PT.string.isRequired,
  tag: PT.string,
  description: PT.string,
  className: PT.string
};

SectionHead.defaultProps = {
  tag: "h2",
  description: "",
  className: ""
};
