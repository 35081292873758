import styled from "@emotion/styled";
import DataTable from "react-data-table-component";

export const StyledDataTable = styled(DataTable)`
  font-family: ${(props) => props.theme.fontFamily.lato};
  border-radius: unset !important;

  .rdt_Table {
    border: 0;
    background-color: transparent;
    font-weight: 400;
    font-size: 14px;
  }

  .rdt_TableHead {
    border: 0;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors["neutral-400"]};
    font-size: 14px;
  }

  .rdt_TableHeadRow {
    display: flex;
    padding: 0 8px;
    text-transform: uppercase;

    border: ${(props) => props.theme.borders.border};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    height: 54px;

    .rdt_TableCol {
      display: flex;
      align-items: center;
      line-height: 20px;
      padding: 0 8px;

      &_Sortable {
        &:hover {
          color: ${(props) => `${props.theme.colors["neutral-300"]}`};
          & > div {
            color: ${(props) => `${props.theme.colors["neutral-300"]}`};
          }
        }

        &[disabled] {
          color: ${(props) => `${props.theme.colors["neutral-400"]}`};
          & > div {
            color: ${(props) => `${props.theme.colors["neutral-400"]}`};
          }
        }
      }

      & > div {
        display: inline-flex;
        opacity: 1;
      }
    }
  }

  .rdt_TableBody {
    border: ${(props) => props.theme.borders.border};
    border-top: 0;
    border-bottom: ${(props) =>
      props.styleRules?.showedAll ? props.theme.borders.border : "0"};
    color: ${(props) => props.theme.colors["neutral-800"]};
    padding-bottom: ${(props) => (props.styleRules?.showedAll ? "0" : "20px")};
    margin-bottom: ${(props) => (props.styleRules?.showedAll ? "0" : "2px")};
    border-bottom-left-radius: ${(props) =>
      props.styleRules?.showedAll ? "12px" : "0"};
    border-bottom-right-radius: ${(props) =>
      props.styleRules?.showedAll ? "12px" : "0"};

    @media (max-width: ${(props) => props.theme.screenSizes.lgMax}) {
      margin-bottom: ${(props) => (props.styleRules?.showedAll ? "12px" : "0")};
    }
  }

  .rdt_TableRow {
    position: relative;
    display: flex;
    align-items: center;
    padding: ${({ styleRules }) => (styleRules?.row?.noPadding ? "0" : "8px")};

    background-color: transparent;
    transition: background-color 0.3s ease;
    ${({ styleRules, theme }) => {
      if (!styleRules?.row?.noBorder) {
        return ` &:not(:last-child) {
      border-bottom: ${theme.borders.border};
      padding-bottom: 8px;
    }
    &:last-child {
      border-bottom: ${styleRules?.showedAll ? "0" : theme.borders.border};
    }`;
      } else {
        return "border-bottom: 0 !important;";
      }
    }}

    &:hover {
      background-color: rgba(246, 247, 249, 0.8);
      transition: background-color 0.3s ease;
    }
  }

  .rdt_TableCell {
    padding: 0 8px;

    & > div[data-tag="allowRowEvents"] {
      display: inline-flex;
      width: 100%;
      height: 100%;
    }
  }
`;
