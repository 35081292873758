import styled from "@emotion/styled";

export const StyledPromoTitleBox = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(p) => p.theme.fontFamily.lato};
  min-width: ${(p) => (p.variant === "table" ? "220px" : "100%")};
  max-width: ${(p) => (p.variant === "table" ? "220px" : "100%")};
`;

const IMG_D_SIZE = {
  mobile: {
    table: 36,
    light: 36,
    gray: 24,
    dark: 0
  },
  desktop: {
    table: 36,
    light: 44,
    gray: 24,
    dark: 0
  }
};

export const StyledPromoImage = styled.img`
  width: ${(p) => `${IMG_D_SIZE.mobile[p.variant]}px`};
  height: ${(p) => `${IMG_D_SIZE.mobile[p.variant]}px`};
  object-fit: cover;
  margin-right: ${(p) => (p.variant === "gray" ? "4px" : "8px")};

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    width: ${(p) => `${IMG_D_SIZE.desktop[p.variant]}px`};
    height: ${(p) => `${IMG_D_SIZE.desktop[p.variant]}px`};
  }
`;

export const StyledPromoTitleInfoBox = styled.div`
  width: calc(100% - ${(p) => `${IMG_D_SIZE.mobile[p.variant]}px`});
  display: flex;
  gap: ${(p) => (["table", "light"].includes(p.variant) ? "2px" : "8px")};
  flex-direction: ${(p) =>
    ["table", "light"].includes(p.variant) ? "column" : "row"};
  align-items: ${(p) =>
    ["table", "light"].includes(p.variant) ? "flex-start" : "center"};
  justify-content: ${(p) =>
    ["table", "light"].includes(p.variant) ? "flex-start" : "space-between"};

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    width: calc(100% - ${(p) => `${IMG_D_SIZE.desktop[p.variant]}px`});
    gap: ${(p) => (["table", "light"].includes(p.variant) ? "4px" : "8px")};
`;

const FONT_SIZE_TITLE_D = {
  table: 16,
  light: 18,
  gray: 14,
  dark: 16
};

export const StyledPromoTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`;

export const StyledPromoTitle = styled.p`
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => (["dark", "gray"].includes(p.variant) ? "14px" : "16px")};
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  white-space: nowrap;
  text-overflow: ellipsis;
    overflow: hidden;
  margin: 0;
  max-width: 100%;
  color: ${(p) =>
    p.variant === "dark"
      ? p.theme.colors.white
      : p.theme.colors["neutral-800"]};

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    font-size: ${(p) => FONT_SIZE_TITLE_D[p.variant]}px;
`;

export const StyledPromoTitleTime = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) =>
    p.variant === "dark" ? "#c5cdd9" : p.theme.colors["neutral-500"]};

  font-size: 12px;
  font-style: normal;
  font-weight: ${(p) => (["dark", "gray"].includes(p.variant) ? 600 : 400)};
  line-height: normal;

  & > svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;
