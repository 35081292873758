import { useState } from "react";

import { useTranslation } from "react-i18next";

import animation from "../../../../assets/animations/my_anonymity.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  myAnonymityHeroImageMobile
} from "../../../../assets/img";
import SeoSection from "../../../../components/common/New/SeoSection/SeoSection";
import { Rive } from "../../../../components/ui/New/Rive/Rive";
import { useUserIp } from "../../../../hooks/useUserIp";
import { $api } from "../../../../services/AxiosInstance";
import { AllActions } from "../../../../store/reducers/AllActions";
import { faqMyAnonymity } from "../../../../utils/constants/questionsAndAnswers";

import { CheckAnonymity } from "./CheckAnonymity/CheckAnonymity";
import { IpDetails } from "./IpDetails/IpDetails";
import { MyAnonymityInfo } from "./MyAnonymityInfo/MyAnonymityInfo";
import {
  MyAnonymitySection,
  RestyledFAQ,
  RestyledMainSection
} from "./MyAnonymityPage.styled";

const MyAnonymityPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  const { t } = useTranslation();

  const userIp = useUserIp();

  const handleClick = () => {
    setIsLoading(true);

    setTimeout(() => {
      setResult(userIp);
      setIsLoading(false);
    }, 1000);
  };

  return (
    <MyAnonymitySection>
      <RestyledMainSection
        title={t("myAnonymity.main.title")}
        subtitle={t("myAnonymity.main.subtitle")}
        button={false}
        images={{
          mobile1x: myAnonymityHeroImageMobile,
          mobile2x: myAnonymityHeroImageMobile
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-108}
            right={-82}
            bottom={-108}
            left={-82}
          />
        }
      />
      <CheckAnonymity
        userIp={userIp}
        isLoading={isLoading}
        result={result}
        handleClick={handleClick}
      />
      <IpDetails userIp={userIp} isLoading={isLoading} />
      <MyAnonymityInfo />
      <RestyledFAQ
        list={faqMyAnonymity}
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        tools
      />
      <SeoSection />
    </MyAnonymitySection>
  );
};

export default MyAnonymityPage;

MyAnonymityPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });

  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
};
