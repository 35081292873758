import { useEffect, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { FormInputCode } from "../../../../../components/forms/New/FormInput/FormInputCode";
import Button from "../../../../../components/ui/New/Button/Button";
import Modal from "../../../../../components/ui/New/Modal/Modal";
import TextButton from "../../../../../components/ui/New/TextButton/TextButton";

import { ApiService } from "../../../../../services";

import { useDispatchedActions, useLangUrlDefault } from "../../../../../hooks";

import { Timer } from "./Timer";

import styles from "./ModalConfirmCode.module.scss";

export const ModalConfirmCode = ({ open, close, isPass, tokenTime }) => {
  const navigate = useNavigate();
  const [queryLang, hrefLang] = useLangUrlDefault();

  const { setLogout, setMobileMenu, resetCabinet } = useDispatchedActions();

  const { t } = useTranslation();
  const methods = useForm();
  const [time, setTime] = useState(900000);
  const [timeEnd, setTimeEnd] = useState(false);
  const [triger, setTriger] = useState(false);
  const [loading, setLoading] = useState(false);
  const [awaiting, setAwaiting] = useState(false);

  const handleEnd = () => {
    setTimeEnd(true);
  };

  const handleEndAwaiting = () => {
    setAwaiting(false);
  };

  const onSubmit = (data) => {
    setLoading(true);
    if (!isPass) {
      ApiService.confirmEmailChange(data.code)
        .then((res) => {
          if (res?.status === 200) {
            toast.success(t("dashboard.profile.confirmModal.successEmail"));
            close?.();
            setMobileMenu(false);
            setLogout();
            resetCabinet();
            navigate(`${hrefLang}/new/login/`, { replace: true });
          }

          if (res?.response?.status === 400) {
            toast.error(t("dashboard.profile.confirmModal.invalidToken"));
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((ignore) => {
          toast.error(t("notifications.serverError"));
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (isPass) {
      ApiService.confirmPasswordChange(data.code)
        .then((res) => {
          if (res?.status === 200) {
            toast.success(t("dashboard.profile.confirmModal.successPassword"));
            close?.();
            setMobileMenu(false);
            setLogout();
            resetCabinet();
            navigate(`${hrefLang}/new/login/`, { replace: true });
          }

          if (res?.response?.status === 400) {
            toast.error(t("dashboard.profile.confirmModal.invalidToken"));
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((ignore) => {
          toast.error(t("notifications.serverError"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const resendCode = async () => {
    setLoading(true);
    if (!isPass) {
      try {
        const res = await ApiService.resendEmailConfirmCode(queryLang);
        if (res.status === 200) {
          toast.success(t("dashboard.profile.confirmModal.successSend"));
          setTime(900000);
          setTriger((prev) => !prev);
          setAwaiting(true);
        }

        if (res?.response?.status === 409) {
          throw new Error("Not found token");
        }
      } catch (error) {
        toast.error(t("notifications.serverError"));
      } finally {
        setLoading(false);
      }
    }
    if (isPass) {
      try {
        const res = await ApiService.resendPasswordConfirmCode(queryLang);
        if (res?.status === 200) {
          toast.success(t("dashboard.profile.confirmModal.successSend"));
          setTime(900000);
          setTriger((prev) => !prev);
          setAwaiting(true);
        }

        if (res?.response?.status === 409) {
          throw new Error("Not found token");
        }
      } catch (error) {
        toast.error(t("notifications.serverError"));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    methods.reset();
    setTimeEnd(false);
    setAwaiting(false);
    close?.(false);
  };

  useEffect(() => {
    if (tokenTime) {
      setTime(tokenTime);
      setTriger((prev) => !prev);
    }
  }, [tokenTime]);

  return (
    <Modal
      size={"sm"}
      open={open}
      setOpen={handleClose}
      title={t("dashboard.profile.confirmModal.title")}
      className={styles.modal}
      classNameBody={styles.modal_body}
    >
      <p className={styles.modal_subtitle}>
        {t("dashboard.profile.confirmModal.subtitle")}
      </p>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.modal_form}
        >
          <FormInputCode
            name="code"
            label="Code"
            rules={{ required: true }}
            className={styles.modal_input}
          />
          <div className={`${styles.timer} ${timeEnd ? styles.timer_end : ""}`}>
            {`${t("dashboard.profile.confirmModal.timer")}:`}
            <span
              className={`${styles.timer_time} ${
                timeEnd ? styles.timer_end : ""
              }`}
            >
              <Timer time={time} onEnd={handleEnd} restartTriger={triger} />
            </span>
          </div>
          <TextButton
            color={"secondary"}
            className={styles.modal_resend}
            onClick={resendCode}
            disabled={awaiting || loading}
          >
            {t("dashboard.profile.confirmModal.resend")}{" "}
            {awaiting && (
              <Timer
                time={1}
                type={"min"}
                onEnd={handleEndAwaiting}
                restartTriger={triger}
              />
            )}
          </TextButton>

          <Button
            type="submit"
            className={styles.modal_submit}
            disabled={timeEnd || loading}
          >
            {t("forms.buttons.confirm")}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};
