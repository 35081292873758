import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../hooks";

export const BreadcrumbsItem = ({ item }) => {
  const [, hrefLang] = useLangUrlDefault();

  return (
    <li>
      {item.route ? (
        <Link to={`${hrefLang}/${item.route}`}>{item.title}</Link>
      ) : (
        item.title
      )}
    </li>
  );
};
