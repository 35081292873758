import styled from "@emotion/styled";

export const StyledBoxCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  min-height: 47px;

  ${(p) =>
    p.propMinWidth ? `min-width: ${p.propMinWidth}px; max-width: 100%;` : ""}
  ${(p) =>
    p.propWidth
      ? `min-width: ${p.propWidth}px; max-width: ${p.propWidth}px;`
      : ""}
      ${(p) => (p.propMaxWidth ? `max-width: ${p.propMaxWidth}px;` : "")}
`;
