import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { StyledPagination } from "../components/SkeletonTableComponents.styled";

export const SkeletonPaginationDashboardTable = () => (
  <SkeletonTheme baseColor="#d9d9d9" highlightColor="#adadad">
    <StyledPagination>
      <Skeleton width={80} height={24} />
      <Skeleton width={150} height={40} />
      <div>
        <Skeleton width={150} height={40} />
      </div>
    </StyledPagination>
  </SkeletonTheme>
);
