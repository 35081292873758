import styled from "@emotion/styled";

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin: 4px 0;
    width: calc(100vw - 416px);
    padding: 30px;
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.white};
    box-shadow: 0px 7px 30px 0px rgba(135, 140, 189, 0.23);
    height: 100%;
    max-height: 75vh;
  }
`;

export const StyledContainerCountries = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 252px;
  overflow-y: auto;
`;

export const StyledContainerTitle = styled.span`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 140%;
  text-transform: uppercase;
`;

export const StyledCountryList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding-right: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const StyledCountryItem = styled.li`
  min-width: 130px;
  width: 100%;

  display: flex;
  height: 44px;
  padding: 9px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid
    ${(p) =>
      p.checked ? p.theme.colors["neutral-100"] : "rgba(216, 218, 224, 0.5)"};

  background: #fff;

  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${(p) => p.theme.colors["main-600"]};
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 171px;
  }
`;
