import { useTranslation } from "react-i18next";

import { NoDataComponent } from "../../../../../../components/common/New/TableColumns";
import { SkeletonResultsTable } from "../../../../../../components/ui/New/Skeleton/SkeletonResultTable/SkeletonResultsTable";

import {
  CountryFlag,
  CountryName,
  DnsTableWrapper,
  ResultColumnCountry,
  ResultColumnText,
  ResultTable
} from "./ResultTable.styled";

export const ResultsTable = ({ isLoading, data }) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: t("dnsLeak.table.ip").toUpperCase(),
      cell: (row) => <ResultColumnText>{row.ip}</ResultColumnText>,
      width: "170px"
    },
    {
      name: t("dnsLeak.table.provider").toUpperCase(),
      cell: (row) => <ResultColumnText>{row.provider}</ResultColumnText>,
      selector: (row) => row.provider,
      width: "260px"
    },
    {
      name: t("dnsLeak.table.country").toUpperCase(),
      cell: (row) => (
        <ResultColumnCountry>
          <CountryFlag src={row.flag} alt={row.country} />
          <CountryName>{row.country}</CountryName>
        </ResultColumnCountry>
      ),
      selector: (row) => row?.country,
      minWidth: "260px"
    }
  ];

  return (
    <DnsTableWrapper>
      <ResultTable
        columns={columns}
        data={data}
        noDataComponent={<NoDataComponent />}
        progressPending={isLoading}
        progressComponent={<SkeletonResultsTable cells={5} />}
      />
    </DnsTableWrapper>
  );
};
