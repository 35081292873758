import { useId } from "react";

import PT from "prop-types";

import { StyledTooltip } from "./Tooltip.styled";

export const Tooltip = ({
  id,
  body,
  place,
  className,
  classNameWrapper,
  style,
  delayShow,
  children,
  isOpen,
  noArrow,
  maxWidth
}) => {
  const idTooltip = useId();
  return (
    <div
      data-tooltip-id={id ? id : idTooltip}
      className={classNameWrapper}
      style={style}
    >
      {children}
      <StyledTooltip
        id={id ? id : idTooltip}
        className={className}
        opacity={1}
        place={place}
        delayShow={delayShow}
        isOpen={isOpen}
        noArrow={noArrow}
        maxWidth={maxWidth}
      >
        {body}
      </StyledTooltip>
    </div>
  );
};

Tooltip.propTypes = {
  id: PT.string,
  body: PT.node.isRequired,
  place: PT.oneOf([
    "top",
    "top-start",
    "top-end",
    "right",
    "right-start",
    "right-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end"
  ]),
  className: PT.string,
  classNameTooltip: PT.string,
  style: PT.shape({}),
  children: PT.node,
  delayShow: PT.number,
  isOpen: PT.bool,
  noArrow: PT.bool,
  maxWidth: PT.number
};

Tooltip.defaultProps = {
  place: "top",
  className: "",
  classNameWrapper: "",
  style: {},
  delayShow: null,
  noArrow: false,
  maxWidth: null
};

export default Tooltip;
