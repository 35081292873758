import styled from "@emotion/styled";

import Button from "../../../../../../../components/ui/New/Button/Button";

export const StyledContactBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
  }
`;

export const StyledContactLabel = styled.span`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 130%;
  margin-right: 16px;
  min-width: 120px;

  @media (max-width: $md-max) {
    min-height: 40px;
    display: flex;
    align-items: center;
  }
`;

export const StyledContactValue = styled.span`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: 500;
  line-height: 130%;
  word-break: break-all;

  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    min-height: 32px;
    display: flex;
    align-items: center;
  }
`;

export const StyledContactEditButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;

  padding: 10px;
  height: 40px;
  width: fit-content;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-left: auto;
    position: static;
    min-width: 120px;
    padding: 10px 32px;
  }

  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    & > div {
      display: none;
    }
  }
`;

export const StyledContactEditorBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  ${(p) =>
    p.hidden
      ? `
    height: 0;
            overflow: hidden;
            visibility: hidden;
            width: 0;`
      : ""}
`;

export const StyledContactEditorButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  & > button {
    height: 40px;
    width: fit-content;
    padding: 10px 32px;
  }
`;
