import { useTranslation } from "react-i18next";

import { StyledNodata } from "./NoDataComponent.styled";

export const NoDataComponent = ({ bordered, children }) => {
  const { t } = useTranslation();
  return (
    <StyledNodata bordered={bordered}>
      {children ? children : t("proxyPage.content.table.noDataComponent")}
    </StyledNodata>
  );
};
