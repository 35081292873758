import styled from "@emotion/styled";

import Container from "../../../../../components/common/New/Container/Container";
import { StyledLoading } from "../../../../../components/ui/New/Button/Button.styled";
import Icon from "../../../../../components/ui/New/Icon/Icon";
import { Title } from "../../../../../components/ui/New/Title/Title";

export const RestyledContainer = styled(Container)`
 padding-bottom: 64px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin})
    padding-bottom: 120px;
  }
`;

export const RestyledTitle = styled(Title)`
  width: auto;
  margin-bottom: 24px !important;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.white};
  padding: 36px;
  border-radius: 12px;
  box-shadow: ${(p) => p.theme.shadows.card};
  transition: all 0.3s ease;
`;

export const StyledShowMore = styled.button`
  border: none;
  outline: none;
  background-color: transparent;

  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  margin-top: 16px;
  color: ${(p) => p.theme.colors["neutral-500"]};
  cursor: pointer;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: 24px;
  }

  & > span {
    fontfamily: ${(p) => p.theme.fontFamily.lato};
    fontsize: ${(p) => p.theme.fontSizes.m};
    fontweight: ${(p) => p.theme.fontWeight.normal};
    fontstyle: ${(p) => p.theme.fontStyle.normal};
    lineheight: 150%;
  }

  & > svg {
    margin-left: 8px;
    color: ${(p) => p.theme.colors["neutral-800"]};
  }
`;

// styled-components for items

export const Flag = styled.span`
  width: 20px;
  height: 15px;
  transform: scale(1.2);
  margin-right: 12px;
  margin-left: 2px;
  flex: none;
`;

export const SocialIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

export const Point = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 12px;
  background-color: ${(p) =>
    p.color === "red" ? p.theme.colors["red-500"] : p.theme.colors["main-700"]};
`;

export const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #d8dae0;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &[id="language"],
  &[id="socialMedia"] {
    .list {
      display: flex;
      flex-direction: row;
    }

    .list-item {
      width: 97px;
      min-width: 97px;
    }

    .value {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledHeaderBox = styled.div`
  display: flex;
  align-items: center;

  & > img {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }

  & > span {
    color: ${(p) => p.theme.colors["neutral-800"]};
    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: ${(p) => p.theme.fontSizes.l};
    font-style: ${(p) => p.theme.fontStyle.normal};
    font-weight: ${(p) => p.theme.fontWeight.bold};
    line-height: 120%;
  }
`;

export const RestyledLoading = styled(StyledLoading)`
  width: 36px;
  height: 36px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s ease;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 24px;
    height: 24px;
  }
`;

export const StyledIcon = styled(Icon)`
  width: 36px;
  height: 36px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  cursor: pointer;
  transform: ${(p) => (p.isOpened ? "rotate(180deg)" : "rotate(0deg)")};
  transition: all 0.3s ease;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 24px;
    height: 24px;
  }
`;

// styled-components for list

export const StyledList = styled.div`
  visibility: ${(p) => (p.isOpened ? "visible" : "hidden")};
  overflow: hidden;
  opacity: ${(p) => (p.isOpened ? 1 : 0)};
  max-height: ${(p) => (p.isOpened ? "2000px" : "0")};
  margin-top: ${(p) => (p.isOpened ? "16px" : "0")};

  transition: all 0.3s ease;
`;

export const StyledListItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
`;

export const StyledKeyItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

export const StyledKeyTitle = styled.span`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: 600;
  line-height: 150%;
`;

export const StyledKeyValue = styled.div`
  width: 223px;
  text-align: right;
  color: ${(p) => p.theme.colors["neutral-800"]};
`;

export const StyledKey = styled.span`
  color: ${(p) => p.theme.colors["neutral-400"]};
  width: 50%;
`;

export const StyledValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  color: ${(p) => p.theme.colors["neutral-800"]};
  text-align: right;
`;

export const StyledValueItem = styled.div`
  display: flex;
  flex-direction: column;

  & .value {
    width: 100%;
    margin-bottom: 8px;
  }
`;
