import styled from "@emotion/styled";

import Container from "../../../../../../components/common/New/Container/Container";
import { Rive } from "../../../../../../components/ui/New/Rive/Rive";
import { Title } from "../../../../../../components/ui/New/Title/Title";

export const StyledAnimationWrapper = styled.div`
  position: relative;
  display: none;
  width: 0;
  height: 0;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: block;
    width: 650px;
    height: 405px;
  }
`;

export const StyledContainer = styled(Container)`
  padding-top: 64px !important;
  padding-bottom: 64px !important;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    align-items: flex-end;
    flex-direction: row;
    padding-top: 72px !important;
    padding-bottom: 120px !important;
  }
`;

export const StyledAnimation = styled(Rive)`
  position: absolute;
  top: 0;
  left: -88px;
  width: 650px;
  height: 405px;

  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    & > canvas {
      width: 0;
      height: 0;
    }
  }
`;

export const StyledContentWrapper = styled.div`
  margin-bottom: 32px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-left: 85px;
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 24px !important;
`;

export const StyledDescription = styled.div`
  color: ${({ theme }) => theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 167%;
  max-width: 480px;
  margin-bottom: 24px;
`;

export const StyledImage = styled.img`
  width: 100%;
  max-width: 500px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
    width: 474px;
    height: 283px;
  }
`;
