import styled from "@emotion/styled";

export const StyledRenderTextWrapper = styled.div`
  margin-bottom: ${(props) => (props.sub ? "0" : " 16px")};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 16px;
`;

export const StyledRenderText = styled.p`
  position: relative;
  width: 100%;
  text-align: left;
  word-break: break-word;
  overflow: hidden;
  color: ${(props) =>
    props.sub ? props.theme.colors["neutral-500"] : "inherit"};

  max-height: ${(props) => (props.fullSize ? `${props.fullSize}px` : "195px")};

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: ${(props) => (props.showedFull ? "0" : "30px")};
    background: ${(p) =>
      p.nestingLevel % 2 === 0
        ? "linear-gradient(#f6f7f900 0%, #f6f7f9 100%)"
        : "linear-gradient(#ffffff00 0%, #fff 100%)"};

    transition: height 0.3s ease-in-out;
  }

  transition: max-height 0.3s ease-in-out;
`;
