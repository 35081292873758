import styled from "@emotion/styled";

export const StyledTitleWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 32px;
  }

  @media (min-width: ${(props) => props.theme.screenSizes.lgMin}) {
    margin-bottom: 48px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin-bottom: 0;
    }
  }
`;
