import { createSlice } from "@reduxjs/toolkit";

// Thunks
import {
  getBlogComments,
  getBlogContentByAlias,
  getBlogSidebarContent
} from "./BlogInner.thunks";

const initialState = {
  content: {
    data: {
      articleBody: "",
      attachments: [],
      category: false,
      commentCount: 0,
      coverImageId: null,
      coverSmallImageId: null,
      creationDate: "",
      description: "",
      id: "",
      nextArticleAlias: null,
      nextArticleId: "",
      nextArticleTitle: "",
      popular: false,
      previousArticleAlias: null,
      previousArticleId: null,
      previousArticleTitle: null,
      title: "",
      userId: "",
      userName: "",
      alias: "",
      translationId: ""
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  comments: {
    data: {
      content: [],
      totalElements: 0,
      totalPages: 0
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  sidebar: {
    data: {
      latest: [],
      popular: [],
      languageCode: null
    },
    isLoading: false,
    isError: null,
    isDataLoaded: false
  }
};

const BlogInnerReducer = createSlice({
  name: "bloginner",
  initialState,
  reducers: {
    setErrorBlogInnerContent(state, action) {
      state.content.isError = action.payload;
    },
    setBlogSidebarContent(state, action) {
      state.sidebar.data = action.payload;
      state.sidebar.isDataLoaded = true;
    },
    setBlogContentByAlias(state, action) {
      state.content.data = action.payload;
      state.content.isDataLoaded = true;
    },
    setBlogComments(state, action) {
      state.comments.data = action.payload;
      state.comments.isDataLoaded = true;
    }
  },
  extraReducers: (builder) => {
    // getBlogContentByAlias
    builder.addCase(getBlogContentByAlias.pending, (state) => {
      state.content.isError = null;
      state.content.isLoading = true;
    });
    builder.addCase(getBlogContentByAlias.fulfilled, (state, action) => {
      state.content.isError = null;
      state.content.data = action.payload;
      state.content.isLoading = false;
    });
    builder.addCase(getBlogContentByAlias.rejected, (state, action) => {
      state.content.isError = action.payload;
      state.content.isLoading = false;
    });
    // getBlogComments
    builder.addCase(getBlogComments.pending, (state) => {
      state.comments.isError = null;
      state.comments.isLoading = true;
    });
    builder.addCase(getBlogComments.fulfilled, (state, action) => {
      state.comments.isError = null;
      state.comments.data = action.payload;
      state.comments.isLoading = false;
    });
    builder.addCase(getBlogComments.rejected, (state, action) => {
      state.comments.isError = action.payload;
      state.comments.isLoading = false;
    });
    // getBlogSidebarContent
    builder.addCase(getBlogSidebarContent.pending, (state) => {
      state.sidebar.isError = null;
      state.sidebar.isLoading = true;
    });
    builder.addCase(getBlogSidebarContent.fulfilled, (state, action) => {
      state.sidebar.isError = null;
      state.sidebar.data = action.payload;
      state.sidebar.isDataLoaded = true;
      state.sidebar.isLoading = false;
    });
    builder.addCase(getBlogSidebarContent.rejected, (state, action) => {
      state.sidebar.isError = action.payload;
      state.sidebar.isLoading = false;
    });
  }
});

export const BlogInnerReducerActions = {
  ...BlogInnerReducer.actions,
  getBlogContentByAlias,
  getBlogComments,
  getBlogSidebarContent
};
export default BlogInnerReducer.reducer;
