import { useTranslation } from "react-i18next";
import { $api } from "../../../../services/AxiosInstance";
import { AllActions } from "../../../../store/reducers/AllActions";

import { TitleBlock } from "../components/TitleBlock/TitleBlock";

import { ListReview } from "./ListReview/ListReview";

const Reviews = () => {
  const { t } = useTranslation();
  return (
    <>
      <TitleBlock title={t("dashboard.reviews.title")} />
      <ListReview />
    </>
  );
};

export default Reviews;

Reviews.getServerSideState = async (store, params) => {
  const { data: pageContent } = await $api.post(
    "/api/page/front/content",
    {
      ...params,
      languageCode: params.locale
    }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};
