import PT from "prop-types";

import { PaginationWrapper } from "./Pagination.styled";
import { ItemsOnPage, ReactPagination, RowsInfo } from "./components";

export const Pagination = ({
  currentPage,
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  marginPagesDisplayed,
  pageRangeDisplayed,
  useCount,
  className
}) => (
  <PaginationWrapper className={className} useCount={useCount}>
    {useCount ? (
      <RowsInfo
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        rowCount={rowCount}
        className={"rows-info"}
      />
    ) : null}
    <ReactPagination
      rowCount={rowCount}
      currentPage={currentPage}
      rowsPerPage={rowsPerPage}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      onChangePage={onChangePage}
    />
    {useCount ? (
      <ItemsOnPage
        rowsPerPage={rowsPerPage}
        totalRows={rowCount}
        onChangeRowsPerPage={onChangeRowsPerPage}
        className={"items-on-page"}
      />
    ) : null}
  </PaginationWrapper>
);

Pagination.propTypes = {
  currentPage: PT.number.isRequired,
  rowsPerPage: PT.number.isRequired,
  rowCount: PT.number.isRequired,
  onChangeRowsPerPage: PT.func,
  onChangePage: PT.func.isRequired,
  marginPagesDisplayed: PT.number,
  pageRangeDisplayed: PT.number,
  useCount: PT.bool,
  className: PT.string
};

Pagination.defaultProps = {
  onChangeRowsPerPage: () => null,
  marginPagesDisplayed: 2,
  pageRangeDisplayed: 2,
  useCount: false,
  className: ""
};

export default Pagination;
