import { mockPortCheckedData } from "../../../../../utils/constants/mockTableData";

import { CTA } from "./CTA/CTA";
import { StyledContainer, StyledSection } from "./PortScanner.styled";
import { PortScannerSlider } from "./PortScannerSlider/PortScannerSlider";
import { PortScannerSteps } from "./PortScannerSteps/PortScannerSteps";
import { PortsTable } from "./PortsTable/PortsTable";
import { TestPort } from "./TestPort/TestPort";

export const PortScanner = () => (
  <StyledSection>
    <StyledContainer>
      <TestPort />
      <PortsTable data={mockPortCheckedData} />
    </StyledContainer>
    <PortScannerSlider />
    <CTA />
    <PortScannerSteps />
  </StyledSection>
);
