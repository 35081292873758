import { useEffect, useState } from "react";

import { useLockBackdrop } from "../../../../hooks";

// import { throttle } from "../../../../utils/helpers";
import { Promocodes } from "./Promocodes/Promocodes";
import {
  StyledSidebar,
  StyledSidebarCardContent,
  StyledSidebarOverlay
} from "./Sidebar.styled";
import { SidebarButton } from "./SidebarButton/SidebarButton";

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const clickHandler = (e) => {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  useLockBackdrop(isOpen);

  useEffect(() => {
    const funcClose = (e) => {
      if (e.key === "Escape") {
        setIsOpen(false);
      }
    };
    const handleResize = () => {
      if (window.innerWidth >= 993 && isOpen) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      window.addEventListener("keydown", funcClose);
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("keydown", funcClose);
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth >= 993 && isOpen) {
  //       setIsOpen(false);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  return (
    <>
      <StyledSidebarOverlay onClick={clickHandler} isOpen={isOpen} />
      <StyledSidebar isOpen={isOpen}>
        <StyledSidebarCardContent shadow radius={"small"}>
          <Promocodes closeSidebar={setIsOpen} />
        </StyledSidebarCardContent>
      </StyledSidebar>
      <SidebarButton isActive={isOpen} onClick={clickHandler} />
    </>
  );
};

export default Sidebar;
