import { Suspense } from "react";

import { useSelector } from "react-redux";

import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";

// import { useMetaData } from "../../../hooks";
import { Container } from "../../ui";
import { Loader } from "../../ui/Loader/Loader";

import "./SeoSection.scss";

export const SeoSection = () => {
  // const metaData = useMetaData();
  const {
    pageContent: { current }
  } = useSelector(getAllContent);

  return (
    <div className="seo">
      <Container>
        <Suspense fallback={<Loader />}>
          <section
            className="seo__wrapper"
            dangerouslySetInnerHTML={{ __html: current?.data?.seo }}
          ></section>
        </Suspense>
      </Container>
    </div>
  );
};
