import styled from "@emotion/styled";

export const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-left: 8px;
  }
`;

export const StyledStatus = styled.p`
  color: ${(props) =>
    props.status === "success"
      ? props.theme.colors["main-700"]
      : props.theme.colors["red-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
`;
