import styled from "@emotion/styled";

export const StyledRatingContainer = styled.div`
  display: ${(p) => (p.active ? "flex" : "none")};
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-top: 24px;
`;

export const StyledRatingFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledRatingFilterLabel = styled.span`
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: 500;
  line-height: 170%;

  &::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background: ${(p) => p.theme.colors["main-500"]};
    margin-right: 8px;
  }
`;

export const StyledRatingFilterSelectBox = styled.div`
  flex: 1 1 50%;
`;

export const StyledRatingBoxSkeleton = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 2/1;
  & > span {
    width: 100%;
    height: 100%;
  }
`;
