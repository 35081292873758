import PT from "prop-types";

import { StyledIsActiveCell } from "./IsActiveCell.styled";

export const IsActiveCell = ({ isActive }) => (
  <StyledIsActiveCell isActive={isActive} />
);

IsActiveCell.propTypes = {
  isActive: PT.bool
};

IsActiveCell.defaultProps = {
  isActive: false
};
