import styled from "@emotion/styled";
import ReactSelect from "react-select";

const HEIGHT = {
  sm: "32px",
  md: "40px",
  lg: "48px"
};

const PADDING_X = {
  sm: "5px",
  md: "7px",
  lg: "11px"
};

export const ReactSelectStyled = styled(ReactSelect)`
  border: none;
  width: 100%;
  .form-select__control {
    gap: 8px;

    width: 100%;
    height: ${(p) => HEIGHT?.[p?.size] || HEIGHT.lg};
    min-height: ${(p) => HEIGHT?.[p?.size] || HEIGHT.lg};
    padding: ${(p) => PADDING_X?.[p?.size] || PADDING_X.lg} 15px;

    border-radius: 8px;
    border: 1px solid
      ${(p) => {
        if (p.isError) {
          return p.theme.colors["red-500"];
        }
        if (p.color === "dark") {
          return "#ffffff0d";
        }
        if (p.color === "menu") {
          return "transparent";
        }
        return p.theme.colors["neutral-100"];
      }} !important;

    background-color: ${(p) => {
      if (p.isError) {
        return "#fff5f54d";
      }
      if (p.color === "dark") {
        return "#ffffff1a";
      }
      if (p.color === "menu") {
        return "#ffffff0d";
      }
      return p.theme.colors.white;
    }};
    color: ${(p) => {
      if (p.color === "dark") {
        return p.theme.colors.white;
      }
      if (p.color === "menu") {
        return p.theme.colors.white;
      }
      return p.theme.colors["neutral-800"];
    }};
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      border: 1px solid
        ${(p) => {
          if (p.isError) {
            return p.theme.colors["red-500"];
          }
          if (p.color === "menu") {
            return "transparent";
          }
          return p.theme.colors["neutral-500"];
        }} !important;
      background-color: ${(p) => {
        if (p.isError) {
          return "#fff5f54d";
        }
        if (p.color === "dark") {
          return "#ffffff1a";
        }
        if (p.color === "menu") {
          return "#ffffff14";
        }
        return p.theme.colors.white;
      }};
      transition: border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    }

    &--is-focused,
    &--is-focused:hover {
      border: 1px solid
        ${(p) => {
          if (p.isError) {
            return p.theme.colors["red-500"];
          }
          if (p.color === "dark") {
            return p.theme.colors["neutral-500"];
          }

          if (p.color === "menu") {
            return "transparent";
          }
          return p.theme.colors["main-700"];
        }} !important;
      background-color: ${(p) => {
        if (p.isError) {
          return "#fff5f54d";
        }
        if (p.color === "dark") {
          return "#ffffff1a";
        }
        if (p.color === "menu") {
          return "#ffffff14";
        }
        return "#f7ffe926";
      }};
      box-shadow: none;

      transition: border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    }

    &--is-focused {
      box-shadow: none;
    }

    &--is-disabled {
      background-color: ${(p) => p.theme.colors["background-color"]};
      color: ${(p) => p.theme.colors["neutral-300"]};
    }
  }

  .form-select__indicators,
  .form-select__indicator {
    font-size: 0;
    padding: 0;
    color: ${(p) => {
      if (p.color === "dark") {
        return p.theme.colors.white;
      }
      if (p.color === "menu") {
        return p.theme.colors.white;
      }
      return p.theme.colors["neutral-800"];
    }};
  }

  .form-select__indicator {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
    &:hover {
      color: inherit;
    }

    & > svg {
      width: ${(p) => (p.size === "sm" ? "20px" : "24px")};
      height: ${(p) => (p.size === "sm" ? "20px" : "24px")};
    }
  }

  .form-select__control--menu-is-open .form-select__indicator {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }

  .form-select__control--is-disabled .form-select__indicator {
    color: ${(p) => p.theme.colors["neutral-300"]};
  }

  .form-select__value-container {
    padding: 0;
    color: inherit !important;
    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: ${(p) =>
      p.size === "sm" ? p.theme.fontSizes.s : p.theme.fontSizes.m};
    font-style: ${(p) => p.theme.fontStyle.normal};
    font-weight: ${(p) => p.theme.fontWeight.normal};
    line-height: ${(p) => (p.size === "sm" ? "140%" : "150%")};
  }

  .form-select__single-value {
    display: flex;
    align-items: center;
    color: inherit !important;
  }

  .form-select__placeholder {
    margin: 0;
    color: ${(p) => p.theme.colors["neutral-300"]};
  }

  .form-select__input-container {
    margin: 0;
    padding: 0;
    color: inherit !important;
  }

  .form-select__menu {
    z-index: 30;
    margin: 0;
    margin-top: 1px;
    border-radius: 8px;
    padding: 12px 6px;
    box-shadow: ${(p) =>
      p.color === "menu"
        ? "none"
        : "0px 7px 21px 0px rgba(135, 140, 189, 0.23)"} !important;
    background-color: ${(p) => {
      if (p.color === "dark") {
        return "#2e3644";
      }
      if (p.color === "menu") {
        return "#20252c";
      }
      return p.theme.colors.white;
    }};

    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: ${(p) => p.theme.fontSizes.m};
    font-style: ${(p) => p.theme.fontStyle.normal};
    font-weight: ${(p) => p.theme.fontWeight.normal};
    line-height: 150%;

    &-list {
      padding: 0 6px;
      max-height: 460px;
      overflow: auto;
      ${(p) =>
        p.color === "dark"
          ? `
        &::-webkit-scrollbar-track {
         background-color: rgba(238, 239, 240, 0.05);
                }
         &::-webkit-scrollbar-thumb {
          background-color: rgba(238, 239, 240, 0.08);
          }`
          : ""}
    }
  }

  .form-select__option {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    padding: 10px;
    overflow: hidden;
    border-radius: 8px;

    background-color: ${(p) => {
      if (p.color === "dark") {
        return "#2e3644";
      }
      if (p.color === "menu") {
        return "#20252c";
      }
      return p.theme.colors.white;
    }};
    color: ${(p) => {
      if (p.color === "dark") {
        return p.theme.colors.white;
      }
      if (p.color === "menu") {
        return p.theme.colors.white;
      }
      return p.theme.colors["neutral-800"];
    }} !important;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    &--is-focused,
    &--is-selected {
      color: ${(p) => {
        if (p.color === "dark") {
          return p.theme.colors.white;
        }
        if (p.color === "menu") {
          return p.theme.colors["neutral-300"];
        }
        return p.theme.colors["neutral-800"];
      }};
      transition: color 0.3s ease-in-out;
    }

    &--is-focused {
      background-color: ${(p) => {
        if (p.color === "dark") {
          return "#ffffff1a";
        }
        if (p.color === "menu") {
          return "#ffffff0d";
        }
        return "#f6f7f9cc";
      }};
      transition: background-color 0.3s ease-in-out;
    }
    &--is-selected {
      background-color: ${(p) => {
        if (p.color === "dark") {
          return "#ffffff1a";
        }
        if (p.color === "menu") {
          return "#ffffff0d";
        }
        return p.theme.colors["main-400"];
      }};
      transition: background-color 0.3s ease-in-out;
      &.form-select__option--is-focused {
        background-color: ${(p) => {
          if (p.color === "dark") {
            return "#ffffff1a";
          }
          if (p.color === "menu") {
            return "#ffffff0d";
          }
          return p.theme.colors["main-500"];
        }};
        transition: background-color 0.3s ease-in-out;
      }
    }
  }

  .form-select__value-container--is-multi {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    flex-wrap: nowrap;
    gap: 4px;
  }

  .form-select__multi-value {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0;
    padding: 0 12px;
    background-color: ${(p) => p.theme.colors["background-color"]};
    border-radius: 8px;
    &__label {
      padding: 0;
      font-size: ${(p) => p.theme.fontSizes.m};
      line-height: 150%;
    }

    &__remove {
      padding: 0;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: transparent;
        color: ${(p) => p.theme.colors["neutral-800"]};
      }

      & > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
