import { useEffect, useId, useRef, useState } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { Checkbox } from "../../../../../components/ui/New/Checkbox/Checkbox";
import Dropdown from "../../../../../components/ui/New/Dropdown/Dropdown";
import Icon from "../../../../ui/New/Icon/Icon";
import Search from "../../../../../components/ui/New/Search/Search";
import { StyledTooltip } from "../../../../../components/ui/New/Tooltip/Tooltip.styled";

import { useLangUrlDefault } from "../../../../../hooks";

import {
  StyledCheckboxLabel,
  StyledHeaderCell,
  StyledHeaderNameCell,
  StyledList,
  StyledListItem,
  StyledMenuContainer,
  StyledNodata
} from "./HeaderNameCell.styled";

export const HeaderNameCell = ({
  showFilter,
  setFilter,
  filter,
  filterOption,
  children,
  minWidth,
  maxWidth,
  width,
  sortable,
  center
}) => {
  const [lang] = useLangUrlDefault();
  const { t } = useTranslation();
  const id = useId();
  const ref = useRef(null);

  const [needTooltip, setNeedTooltip] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(filterOption);

  const helperWidth = (width) => {
    if (!width) return null;

    return sortable ? width - 34 : width - 16;
  };

  const handleCheckboxClick = (id) => {
    if (filter.includes(id)) {
      setFilter(filter?.filter((item) => item !== id));
    } else {
      setFilter([...filter, id]);
    }
  };
  useEffect(() => {
    if (ref.current) {
      setNeedTooltip(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref, lang]);

  useEffect(() => {
    if (search?.length === 0) {
      setFilteredData(filterOption);
    } else {
      setFilteredData(
        filterOption?.filter((item) =>
          item?.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, filterOption]);

  return (
    <StyledHeaderCell
      propMinWidth={helperWidth(minWidth)}
      propWidth={helperWidth(width)}
      propMaxWidth={helperWidth(maxWidth)}
      center={center}
    >
      <StyledHeaderNameCell data-tooltip-id={id} ref={ref}>
        {children}
      </StyledHeaderNameCell>
      {needTooltip ? (
        <StyledTooltip id={id} place="top">
          {children}
        </StyledTooltip>
      ) : null}
      {showFilter && (
        <Dropdown
          label={<Icon name="filterAlt" />}
          align="center"
          direction="bottom"
          portal
        >
          <Search value={search} onChange={setSearch} variant={"outlined"} />
          <StyledMenuContainer>
            {filteredData?.length ? (
              <StyledList>
                {filteredData.map((item) => {
                  const checked = filter.includes(item.id);
                  return (
                    <StyledListItem key={item?.id} selected={checked}>
                      <Checkbox
                        checked={checked}
                        onClick={() => handleCheckboxClick(item.id)}
                        name={item.id}
                        label={
                          <StyledCheckboxLabel>{item.name}</StyledCheckboxLabel>
                        }
                      />
                    </StyledListItem>
                  );
                })}
              </StyledList>
            ) : (
              <StyledNodata>
                <StyledCheckboxLabel>
                  {t("dashboard.promocodes.table.noDataFilter")}
                </StyledCheckboxLabel>
              </StyledNodata>
            )}
          </StyledMenuContainer>
        </Dropdown>
      )}
    </StyledHeaderCell>
  );
};

HeaderNameCell.propTypes = {
  showFilter: PT.bool,
  setFilter: PT.func,
  filter: PT.array,
  filterOption: PT.array,
  children: PT.node,
  minWidth: PT.number,
  width: PT.number,
  sortable: PT.bool,
  maxWidth: PT.number,
  center: PT.bool
};

HeaderNameCell.defaultProps = {
  showFilter: false,
  setFilter: () => null,
  filter: [],
  filterOption: [],
  children: null,
  minWidth: null,
  width: null,
  sortable: false,
  maxWidth: null,
  center: false
};
