import styled from "@emotion/styled";

export const StyledBackdrop = styled.div`
  z-index: 200;
  position: fixed;
  inset: 0;
  background: #00000052;
  -webkit-tap-highlight-color: transparent;
`;

const SIZES = {
  sm: 24,
  md: 36,
  lg: 48
};

export const StyledModal = styled.div`
  position: fixed;
  z-index: 201;
  display: flex;
  flex-direction: column;
  min-width: ${(p) => p.minWidth || 320}px;
  width: 100%;
  max-width: ${(p) => p.maxWidth || 1200}px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  border: 0;
  outline: 0;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: ${(p) => SIZES[p.size] || SIZES.md}px;
  }
  ${(p) =>
    p.mobilePosition === "bottom"
      ? `
  @media (max-width: ${p.maxWidth || 480}px) {
    top: unset;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0, 0);
    border-radius: 12px 12px 0 0;
    width: 100%;
    }
      `
      : ""}
`;

export const StyledModalTitle = styled.span`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%;
  margin-bottom: 24px;
  max-width: calc(100% - 48px);

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    font-size: ${(p) => p.theme.fontSizes.xll};
    line-height: 118%;
    margin-bottom: 32px;
  }
`;

export const StyledModalClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  cursor: pointer;
  color: ${(p) => p.theme.colors["neutral-400"]};
  padding: 0;
  width: 36px;
  height: 36px;

  transition: color 0.3s;
  & svg {
    width: 36px;
    height: 36px;
  }

  &:hover,
  &:focus {
    color: ${(p) => p.theme.colors["neutral-800"]};
    transition: color 0.3s;
  }
`;

export const StyledModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
