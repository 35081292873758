import { SkeletonTheme } from "react-loading-skeleton";

import { SkeletonSiteNameCell } from "../components/SkeletonSiteNameCell";
import {
  StyledCell,
  StyledRow,
  StyledSceletonTable,
  StyledSkeleton
} from "../components/SkeletonTableComponents.styled";

export const SkeletonMainPageTable = ({ cells }) => (
  <SkeletonTheme inline>
    <StyledSceletonTable>
      <StyledRow rowHeight={52}>
        <StyledCell cellMinWidth={285}>
          <StyledSkeleton width={120} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={100}>
          <StyledSkeleton width={80} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={100}>
          <StyledSkeleton width={80} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={110}>
          <StyledSkeleton width={80} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={94}>
          <StyledSkeleton width={70} height={20} />
        </StyledCell>
        <StyledCell cellMinWidth={105}>
          <StyledSkeleton width={85} height={20} />
        </StyledCell>
      </StyledRow>
      {Array(cells)
        .fill(0)
        .map((_, i) => (
          <StyledRow rowHeight={64} key={`item-skeleton-${i}`}>
            <SkeletonSiteNameCell width={290} subInfoWidth={80} />
            <StyledCell cellMinWidth={100}>
              <StyledSkeleton width={40} height={20} />
            </StyledCell>
            <StyledCell cellMinWidth={100}>
              <StyledSkeleton width={40} height={14} />
            </StyledCell>
            <StyledCell cellMinWidth={110}>
              <StyledSkeleton width={70} height={14} />
            </StyledCell>
            <StyledCell cellMinWidth={94}>
              <StyledSkeleton width={50} height={14} />
            </StyledCell>
            <StyledCell cellMinWidth={105}>
              <StyledSkeleton width={85} height={14} />
            </StyledCell>
          </StyledRow>
        ))}
    </StyledSceletonTable>
  </SkeletonTheme>
);
