import { useEffect, useId, useState } from "react";

import { ClickAwayListener } from "@mui/base";
import { Popper } from "@mui/base/Popper";
import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FormError } from "../../../../../../../../components/forms/New/FormError/FormError";
import Icon from "../../../../../../../../components/ui/New/Icon/Icon";

import { getAllContent } from "../../../../../../../../store/reducers/ContentReducer/Content.selectors";

import { useLangUrlDefault } from "../../../../../../../../hooks";

import { BodyCountries } from "./BodyCountries";
import {
  StyledCountriesButton,
  StyledCountriesModal,
  StyledCountriesWrapper,
  StyledCountryLabel
} from "./Countries.styled";
import { RenderValue } from "./RenderValue";

export const Countries = ({ prefix, type }) => {
  const [queryLang] = useLangUrlDefault();
  const { t } = useTranslation();
  const buttonId = useId();
  const { getValues, clearErrors, setValue } = useFormContext();
  const { errors } = useFormState();

  const { countries } = useSelector(getAllContent);

  const [active, setActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(() => {
    const locations = getValues(`${prefix}locations`);
    return countries.data?.filter((country) => locations?.includes(country.id));
  });

  const handleOpen = (event) => {
    const width = window.innerWidth;
    // console.log(event.target);
    if (width <= 992) {
      setOpenModal(!openModal);
    } else {
      if (openModal) {
        setOpenModal(false);
      }
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
    if (active) {
      const button = document.getElementById(buttonId);
      button.blur();
    }
    setActive(!active);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : null;

  const handleClickAway = (e) => {
    const button = document.getElementById(buttonId);
    if (e.target.id !== buttonId && !button.contains(e.target)) {
      setActive(false);
      setAnchorEl(null);
    }
  };

  const hadleSetSelected = (value) => {
    setSelected(
      countries.data?.filter((country) => value.includes(country.id))
    );
    clearErrors(`proxy.${type}.locations`);
  };

  const handleClearCountries = (item) => {
    const selectedCountries = selected.filter(
      (country) => country.id !== item.value
    );
    setValue(
      `${prefix}locations`,
      selectedCountries.map((country) => country.id)
    );
    setSelected(selectedCountries);
  };

  const countriesOptions = selected?.map((country) => ({
    label: country.localization?.[queryLang],
    value: country.id,
    code: country.code
  }));

  useEffect(() => {
    const reopen = () => {
      if (active) {
        setOpenModal(false);
        setAnchorEl(null);
        setActive(false);
      }
    };

    window.addEventListener("resize", reopen);

    return () => {
      window.removeEventListener("resize", reopen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledCountriesWrapper>
        <StyledCountryLabel
          isActive={active}
          isError={!!errors?.proxy?.[type]?.locations?.message}
        >
          {t("forms.countries.label")}
        </StyledCountryLabel>
        <StyledCountriesButton
          aria-describedby={id}
          id={buttonId}
          type="button"
          isError={errors?.proxy?.[type]?.locations?.message}
          isActive={active}
          onClick={handleOpen}
        >
          <RenderValue
            selected={countriesOptions}
            onClerClick={handleClearCountries}
          />
          <Icon name="arrowDown" className="indicator" />
        </StyledCountriesButton>
        {errors?.proxy?.[type]?.locations?.message && (
          <FormError message={errors?.proxy?.[type]?.locations?.message} />
        )}
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            id={id}
            anchorEl={anchorEl}
            open={open}
            placement="bottom-end"
          >
            <BodyCountries prefix={prefix} setSelected={hadleSetSelected} />
          </Popper>
        </ClickAwayListener>
        <StyledCountriesModal
          open={openModal}
          setOpen={handleOpen}
          title={t("proxyPage.content.table.geo.modal.title")}
        >
          <BodyCountries prefix={prefix} setSelected={hadleSetSelected} />
        </StyledCountriesModal>
      </StyledCountriesWrapper>
    </>
  );
};
