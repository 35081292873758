import styled from "@emotion/styled";

export const StyledIndexCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 47px;
  width: 100%;
  height: 100%;
  font-size: ${(p) => p.theme.fontSizes.m};
  font-weight: 500;
  color: ${(p) => p.theme.colors["neutral-800"]};
`;

export const StyledIndexCellTop = styled.span`
  position: absolute;
  top: -8px;
  left: -8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 17.4px;
  text-align: center;
  background-color: #ffe380;
  width: 47px;
  padding: 0 4px;
  border-bottom-right-radius: 4px;
`;
