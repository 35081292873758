// import { startTransition, useEffect, useState } from "react";

/* eslint-disable import/namespace */
import { Helmet } from "react-helmet-async";
// import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { generateMetaTags } from "../../utils/helpers/generateMetaTags.helper";
import { useLangUrlDefault, useMatchedRoute } from "../../hooks";
import { getAllBlogInner } from "../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { getAllContent } from "../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getSiteDescription } from "../../utils/helpers";

const BLOG_INNER_TAG = "bloginner";
const PROXY_SITE_TAG = "proxy-site";

export const MetaPage = () => {
  // **Props
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  // const metaData = useMetaData();
  const route = useMatchedRoute();

  // **Redux state
  const {
    pageContent: { current }
  } = useSelector(getAllContent);
  const { site } = useSelector(getAllProxySite);
  const { content } = useSelector(getAllBlogInner);

  const imageUrl = current?.data?.imageUrl;

  // const [title, setTitle] = useState(current?.data?.title || "");
  // const [description, setDescription] = useState(
  //   current?.data?.description || ""
  // );

  // useEffect(() => {
  //   if (current?.data?.title) {
  //     startTransition(() => {
  //       setTitle(current?.data?.title);
  //     });
  //   }

  //   if (
  //     route?.tag === PROXY_SITE_TAG &&
  //     site.data?.description &&
  //     getSiteDescription(site.data?.description, queryLang, false)
  //   ) {
  //     startTransition(() => {
  //       setDescription(
  //         getSiteDescription(site.data?.description, queryLang, false)
  //       );
  //     });
  //   } else if (current?.data?.description) {
  //     startTransition(() => {
  //       setDescription(current?.data?.description);
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [current?.data?.title, site.data?.description, queryLang]);

  const getCurrentTitle = () => {
    if (current?.data?.title?.length > 0) {
      return current?.data?.title;
    }
    if (route?.tag === BLOG_INNER_TAG && content?.data?.title?.length > 0) {
      return content?.data?.title;
    }
    return t("metaPage.title");
  };

  const getCurrentDescription = () => {
    if (
      route?.tag === PROXY_SITE_TAG &&
      site.data?.description &&
      getSiteDescription(site.data?.description, queryLang, false)
    ) {
      return getSiteDescription(site.data?.description, queryLang, false);
    }
    if (current?.data?.description?.length > 0) {
      return current?.data?.description;
    }
    if (
      route?.tag === BLOG_INNER_TAG &&
      content?.data?.description?.length > 0
    ) {
      return content?.data?.description;
    }
    return t("metaPage.description");
  };
  // const isTitle = Boolean(title);
  // const isDescription = Boolean(description);
  const currentTitle = getCurrentTitle();
  const currentDescription = getCurrentDescription();

  const isImageUrl = Boolean(imageUrl);
  // let currentTitle = title || t("metaPage.title");
  // let currentDescription = description || t("metaPage.description");
  // if (route?.tag === BLOG_INNER_TAG) {
  //   currentTitle = content?.data?.title;
  // } else if (isTitle) {
  //   currentTitle = title;
  // } else {
  //   currentTitle = t("metaPage.title");
  // }

  // if (tag === BLOG_INNER_TAG) {
  //   currentDescription = content?.data?.description;
  // } else if (isDescription) {
  //   currentDescription = description;
  // } else {
  //   currentDescription = t("metaPage.description");
  // }

  // console.log("currentTitle", currentTitle);
  // console.log(`title`, title);
  // console.log(`content`, content?.data?.title);

  return (
    <Helmet>
      {generateMetaTags(currentTitle, currentDescription, queryLang, false, isImageUrl ? imageUrl : false)}
    </Helmet>
  );
};
