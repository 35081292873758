import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { AuthNavigate } from "../../../../../../../../pages/New/AuthPages/components/AuthNavigate/AuthNavigate";

import { FormInput } from "../../../../../../../forms/New/FormInput/FormInput";
import Button from "../../../../../../../../components/ui/New/Button/Button";

import { getAllBlogInner } from "../../../../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { getAllContent } from "../../../../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "../../../../../../../../store/reducers/UserReducer/User.selectors";

import { ApiService } from "../../../../../../../../services";

import { useLangUrlDefault } from "../../../../../../../../hooks";

import { CommentSchemaNew } from "../../../../../../../../utils/validation";

import { StyledResponse, StyledResponseForm } from "./Response.styled";

export const Response = ({
  review,
  toggleAccordion,
  changeHandler,
  captchaRef,
  setTokenCaptcha,
  isDashboard,
  nestedResponseValue
}) => {
  const [queryLang] = useLangUrlDefault();

  // **Redux state
  const { content } = useSelector(getAllBlogInner);
  const { user, isUserAuthenticated } = useSelector(getAllUser);

  const { t } = useTranslation();
  // Form
  const methods = useForm({
    resolver: yupResolver(
      CommentSchemaNew(t("forms", { returnObjects: true }), isUserAuthenticated)
    )
  });

  const { captchaConfig } = useSelector(getAllContent);
  const { site } = useSelector(getAllProxySite);

  // **Local state
  const [isDisabled, setIsDisabled] = useState(false);

  const { enable, headerName } = captchaConfig.data;

  const leaveComment = async (data) => {
    let header = {};

    if (enable) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
      // const { response: token } = await captchaRef.current.execute({
      //   async: true
      // });
      // header = { [headerName]: token };
    }

    try {
      setIsDisabled(true);
      const values = {
        name: isUserAuthenticated ? user.name : data?.name,
        email: isUserAuthenticated ? user.email : data?.email,
        body: data?.body
      };

      const response = await ApiService.createComment(
        review.id,
        site.data?.id || content.data.id,
        values.name,
        values.email,
        values.body,
        queryLang,
        header
      );

      if (response && response.status !== 200) {
        throw response;
      }

      methods.reset({ name: "", email: "", body: "" });

      // Show success message
      toast.success(t("notifications.comment"));

      // Fetch comment
      if (changeHandler) {
        changeHandler();

        // Close textarea
        toggleAccordion();
      }
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      if (enable) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }

      setIsDisabled(false);
    }
  };

  return (
    <StyledResponse
      nestingLevel={nestedResponseValue}
      isDashboard={isDashboard}
    >
      <FormProvider {...methods}>
        <StyledResponseForm
          onSubmit={methods.handleSubmit(leaveComment)}
          noValidate
        >
          {!isUserAuthenticated && (
            <>
              <FormInput
                name="name"
                label={t("modals.comment.form.name.placeholder")}
                placeholder={t("modals.comment.form.name.placeholder")}
                showError
              />
              <FormInput
                name="email"
                placeholder={t("forms.emailV2.label")}
                label={t("forms.emailV2.label")}
                showError
              />
              <AuthNavigate to="login" isStatic noIcon inReviewResponse />
            </>
          )}
          <FormInput
            name="body"
            label={t("forms.answer.label")}
            placeholder={t("forms.answer.label")}
            showError
            type="textarea"
          />
          <Button disabled={isDisabled} fullWidth type="submit">
            {t("forms.addComment.btn")}
          </Button>
        </StyledResponseForm>
      </FormProvider>
    </StyledResponse>
  );
};
