import styled from "@emotion/styled";

export const StyledIsActiveCell = styled.div`
  width: 48px;
  height: 24px;
  border-radius: 12px;
  outline: 1px solid
    ${(p) =>
      p.isActive ? p.theme.colors["main-700"] : p.theme.colors["neutral-75"]};
  padding: 4px;

  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.isActive ? "flex-end" : "flex-start")};

  background-color: ${(p) =>
    p.isActive ? p.theme.colors["main-700"] : p.theme.colors.white};

  &::before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(p) =>
      p.isActive ? p.theme.colors.white : p.theme.colors["neutral-75"]};
  }
`;
