import { useTranslation } from "react-i18next";

import {
  animationWebRTCLeakFix,
  animationWebRTCLeakMain
} from "../../../../assets/animations";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  webRTCLeakTestHeroImageMobile,
  webRTCLeakTestHeroImageMobile2x
} from "../../../../assets/img";
import SeoSection from "../../../../components/common/New/SeoSection/SeoSection";
import { Rive } from "../../../../components/ui/New/Rive/Rive";
import { $api } from "../../../../services/AxiosInstance";
import { AllActions } from "../../../../store/reducers/AllActions";
import { faqWebRTCLeakTest } from "../../../../utils/constants/questionsAndAnswers";

import { WebRTCLeakTest } from "./WebRTCLeakTest/WebRTCLeakTest";
import { WebRTCLeakTestInfo } from "./WebRTCLeakTestInfo/WebRTCLeakTestInfo";
import {
  RestuledHowToFix,
  RestyledFAQ,
  RestyledMainSection
} from "./WebRTCLeakTestPage.styled";

const stepsData = [
  "Disable WebRTC in your browser settings. Disabling WebRTC can prevent the browser from making STUN requests and potentially leaking your IP address.",
  "Use a VPN with WebRTC leak protection. A VPN can help protect your online privacy by encrypting your internet traffic and routing it through a remote server.",
  "Use a browser extension. Some browser extensions, such as WebRTC Network Limiter for Google Chrome or WebRTC Leak Shield for Mozilla Firefox, can help prevent WebRTC leaks by blocking or restricting WebRTC traffic."
];

const WebRTCLeakTestPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <RestyledMainSection
        title={t("webRTCLeakTest.main.title")}
        subtitle={t("webRTCLeakTest.main.subtitle")}
        button={false}
        images={{
          mobile1x: webRTCLeakTestHeroImageMobile,
          mobile2x: webRTCLeakTestHeroImageMobile2x
        }}
        animation={
          <Rive
            src={animationWebRTCLeakMain}
            autoPlay
            top={-42}
            right={-71}
            bottom={-113}
            left={-71}
          />
        }
      />
      <WebRTCLeakTest />
      <WebRTCLeakTestInfo />
      <RestuledHowToFix
        imageMobile="img/webRTC-leak-test/webRTC-leak-fix-image-mobile.svg"
        imageDesktop="img/webRTC-leak-test/webRTC-leak-fix-image.svg"
        title={t("webRTCLeakTest.howFixWebRTC.title")}
        data={stepsData}
        animation={
          <Rive
            src={animationWebRTCLeakFix}
            autoPlay
            top={-58}
            right={-70}
            bottom={-58}
            left={-70}
          />
        }
      />
      <RestyledFAQ
        list={faqWebRTCLeakTest}
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        tools
      />
      <SeoSection />
    </>
  );
};

export default WebRTCLeakTestPage;

WebRTCLeakTestPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });

  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
};
