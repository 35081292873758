import styled from "@emotion/styled";

export const StyledCardInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 16px;
  border-radius: 12px;
  margin-top: ${(p) => (p.noMargin ? "0" : "16px")};

  background: ${(p) => p.theme.colors["background-color"]};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 24px;
  }
`;

export const StyledCardClose = styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 36px
    height: 36px;
    
    border: none;
    background: none;
    cursor: pointer;
    color: ${(p) => p.theme.colors["neutral-400"]};
    transition: color 0.3s ease;


    &:hover, &:focus {
        color: ${(p) => p.theme.colors["neutral-800"]};
        transition: color 0.3s ease;
}

@media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    top: 24px;
    right: 24px;
}
    
    svg {
        width: 36px;
        height: 36px;
    }
    `;

export const StyledCardTitle = styled.p`
  margin-bottom: 16px;

  font-size: ${(p) => p.theme.fontSizes.m};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 100%;

  color: ${(p) => p.theme.colors["neutral-800"]};
`;

export const StyledCardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
