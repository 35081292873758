import styled from "@emotion/styled";

import Logo from "../../../ui/New/Logo/Logo";
import Languages from "../Languages/Languages";

export const StyledFooter = styled.footer`
  padding: 40px 24px 48px 24px;
  background-color: ${(p) => p.theme.colors["background-color-black"]};

  @media (min-width: 1350px) {
    padding: 48px 175px;
  }
`;

export const FooterInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;

  @media screen and (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
`;

export const RestyledLogo = styled(Logo)`
  margin-left: 0;
  width: 165px;

  @media screen and (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 12px;
  }
`;

export const CopyrightDesktop = styled.div`
  display: none;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  color: ${(p) => p.theme.colors["copyright-color"]};

  line-height: 1.14;
  text-align: left;

  @media screen and (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: block;
    width: 200px;
  }

  @media (min-width: 1350px) {
    width: 250px;
  }
`;

export const CopyrightMobile = styled.div`
  display: block;
  font-family: ${(p) => p.theme.fontFamily.lato};
  color: ${(p) => p.theme.colors["copyright-color"]};
  font-size: ${(p) => p.theme.fontSizes.s};

  line-height: 1.14;
  text-align: left;

  @media screen and (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const RestyledLanguages = styled(Languages)`
  display: ${({ isMobile }) => (isMobile ? "flex" : "none")};

  @media screen and (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: ${({ isMobile }) => (isMobile ? "none" : "flex")};
  }

  & .languages-list {
    ${(p) =>
      p.isMobile
        ? `
         width: 170px !important;
    left: -85px !important;
    `
        : `
        background-color: ${p.theme.colors["background-color-black"]} !important;
    top: auto !important;
    overflow: hidden;
    bottom: 40px;

    @media (min-width: ${p.theme.screenSizes.lgMin}) {
      left: -80px !important;
    }
    @media (min-width: 1350px) {
      left: -5px !important;
    }`}
  }
`;
