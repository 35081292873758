import styled from "@emotion/styled";
import Skeleton from "react-loading-skeleton";

export const StyledInfoList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(218, 218, 225, 0.6);

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    justify-content: flex-start;
  }

  li:first-child {
    margin-right: 36px;
  }
`;

export const StyledInfoItem = styled.li`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  time {
    text-transform: capitalize;
  }
`;

export const SkeletonDate = styled(Skeleton)`
  width: 90px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 110px;
  }
`;

export const SkeletonViews = styled(Skeleton)`
  width: 100px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 120px;
  }
`;

export const SkeletonWrapper = styled.div`
  width: 100%;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 820px;
  }

  span {
    height: 40px;
    margin-bottom: 12px;
  }
`;
