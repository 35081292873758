import styled from "@emotion/styled";
import { Title } from "../../../../../components/ui/New/Title/Title";
import Container from "../../../../../components/common/New/Container/Container";

export const StyledRelated = styled.div`
background: ${({ theme }) => theme.colors["background-color"]};
`;

export const StyledRelatedList = styled.ul`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
  }
`;

export const StyledTitle = styled(Title)`
  padding-bottom: 32px !important;
`;

export const StyledContainer = styled(Container)`
  padding-top: 64px !important;
  padding-bottom: 64px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
`;
