import styled from "@emotion/styled";

export const StyledInfo = styled.div`
  display: flex;

  flex-direction: column;
  align-items: felx-start;
  padding: 16px;
  width: 100%;
  gap: 24px;
  border-radius: 12px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows.recentReviewCard};

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    min-width: 350px;
    width: 400px;
    gap: 32px;
    padding: 32px;
    border-radius: 0 12px 12px 0;
    box-shadow: ${(p) => p.theme.shadows.card};
  }
`;

export const InfoItemContainer = styled.div`
  display: ${(p) => (p.active ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  gap: 16px;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    display: flex;
  }
`;

export const InfoItemTitle = styled.p`
  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 140%;
  text-transform: uppercase;

  color: ${(p) => p.theme.colors["neutral-800"]};
`;

export const StyledLoaderInfoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
