import styled from "@emotion/styled";

export const InformationsContentList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const InformationsContentItem = styled.li`
  display: flex;
  align-items: center;
    justify-content: space-between;
  gap: 16px;
  padding: 8px 0;
  border-bottom: ${(p) => p.theme.borders["border-04"]};

  color: ${(p) => p.theme.colors["neutral-500"]};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 130%;

    &:last-child {
    border-bottom: none;
    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      border-bottom: ${(p) => p.theme.borders["border-04"]};
    }
`;

export const InformationsContentItemValue = styled.div`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-weight: 600;
  & > div {
    color: ${(p) => p.theme.colors["neutral-800"]};
    font-weight: 600;
  }
`;
