import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const ldsRoller = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  padding-top: ${(p) => (p.noPadding ? 0 : "30px")};
  padding-bottom: ${(p) => (p.noPadding ? 0 : "30px")};
  width: 100%;
  transform: scale(${(p) => p?.scale || 1});

  opacity: ${(p) => (p.hide ? 0 : 1)};

  ${(p) => {
    if (p.type === "fixed") {
      return `
        position: fixed;
      left: 0;
      top: 0;
      z-index: 100;
      height: 100%;
      `;
    }
    if (p.type === "absolute") {
      return `
      width: auto;
        position: absolute;
      left: 50%;
      top: 50%;
      margin-top: 0;
      margin-bottom: 0;
      transform: translate(-50%, -50%) scale(${p?.scale || 1});
      `;
    }

    if (p.type === "blur") {
      return `
        position: absolute;
      left: 50%;
      top: 50%;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      height: 100%;
     transform: translate(-50%, -50%) scale(${p?.scale || 1});
      `;
    }
  }}
`;

export const LoaderInner = styled.div`
  position: relative;
  margin: auto;
  width: 80px;
  height: 80px;

  div {
    transform-origin: 4rem 4rem;
    animation: ${ldsRoller} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      margin-top: -0.4rem;
      margin-left: -0.4rem;
      border-radius: 50%;
      width: 0.7rem;
      height: 0.7rem;
      background-color: ${(p) =>
        p.color === "dark"
          ? p.theme.colors["neutral-800"]
          : "var(--clr-primary-400)"};
    }

    &:nth-of-type(1) {
      animation-delay: -0.036s;

      &::after {
        left: 63px;
        top: 63px;
      }
    }

    &:nth-of-type(2) {
      animation-delay: -0.072s;

      &::after {
        left: 56px;
        top: 68px;
      }
    }

    &:nth-of-type(3) {
      animation-delay: -0.108s;

      &::after {
        left: 48px;
        top: 71px;
      }
    }

    &:nth-of-type(4) {
      animation-delay: -0.144s;

      &::after {
        left: 40px;
        top: 72px;
      }
    }

    &:nth-of-type(5) {
      animation-delay: -0.18s;

      &::after {
        left: 32px;
        top: 71px;
      }
    }

    &:nth-of-type(6) {
      animation-delay: -0.216s;

      &::after {
        left: 24px;
        top: 68px;
      }
    }

    &:nth-of-type(7) {
      animation-delay: -0.252s;

      &::after {
        left: 17px;
        top: 63px;
      }
    }

    &:nth-of-type(8) {
      animation-delay: -0.288s;

      &::after {
        left: 12px;
        top: 56px;
      }
    }
  }
`;
