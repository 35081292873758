import { useEffect, useState } from "react";

import { everCookie } from "@octaniumsw/react-tools";
import { useTranslation } from "react-i18next";

import Button from "../../../../../components/ui/New/Button/Button";
import { mockEverCookieData } from "../../../../../utils/constants";

import {
  ImageWrapper,
  InfoMessage,
  InnerContainer,
  RectyledContainer,
  RestyledTitle,
  ResultsWrapper,
  TestCard,
  TestWrapper
} from "./EvercookieTest.styled";
import { ResultsTable } from "./ResultsTable/ResultsTable.jsx";

export const EvercookieTest = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(mockEverCookieData);
  const [uid, setUid] = useState("");

  const {
    setEvercookie,
    localUid,
    sessionUid,
    clear,
    indexedDB,
    globalUid,
    cookie
  } = everCookie.useEverCookie();

  useEffect(() => {
    if (!localUid || !sessionUid || !globalUid) {
      indexedDB.then((data) => {
        if (data) {
          setUid(data.toString());
          setEvercookie(data.toString());
        } else {
          clear();
        }
      });
    } else {
      setUid(localUid);
    }
  }, [clear, localUid, indexedDB, setEvercookie, sessionUid, globalUid]);

  const generateEverCookie = () => {
    setIsLoading(true);

    const uid = everCookie.generateEverCookie();

    setUid(uid.toString());

    setEvercookie(uid.toString());

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const rediscoverEverCookie = () => {
    setIsLoading(true);

    setTimeout(() => {
      indexedDB.then((data) => {
        const newResult = [
          { name: "userData:", value: "N/A" },
          { name: "cookieData:", value: cookie.uid || "N/A" },
          { name: "localData:", value: localUid || "N/A" },
          { name: "globalData:", value: globalUid || "N/A" },
          { name: "sessionData:", value: sessionUid || "N/A" },
          { name: "windowData:", value: window.windowUid || "N/A" },
          { name: "pngData:", value: cookie["everCookie_png"] || "N/A" },
          { name: "dbData:", value: data || "N/A" },
          { name: "etagData:", value: cookie["everCookie_etag"] || "N/A" },
          { name: "lsoData:", value: "N/A" },
          { name: "cacheData:", value: cookie["everCookie_cache"] || "N/A" },
          { name: "slData:", value: "N/A" }
        ];

        setResult(newResult);
        setIsLoading(false);
      });
    }, 1000);
  };

  return (
    <RectyledContainer>
      <InnerContainer>
        <TestWrapper>
          <TestCard>
            <ImageWrapper>
              <picture>
                <source
                  srcSet="img/evercookie-test/evercookie-test-image.svg"
                  media="(min-width: 993px)"
                />
                <source
                  srcSet="img/evercookie-test/evercookie-test-image-mobile.svg"
                  media="(max-width: 992px)"
                />
                <img
                  src="img/evercookie-test/evercookie-test-image.svg"
                  alt={t("evercookieTest.testResult")}
                />
              </picture>
            </ImageWrapper>
            <RestyledTitle tag="div" mb={16}>
              {uid !== "" ? `${t("evercookieTest.cookieFound")} = ` : "UID = "}
              <span>{uid !== "" ? uid : t("evercookieTest.uidNotSet")}</span>
            </RestyledTitle>
            <InfoMessage>
              {result
                ? t("evercookieTest.infoRediscover")
                : t("evercookieTest.infoCreate")}
            </InfoMessage>

            <Button
              onClick={uid !== "" ? rediscoverEverCookie : generateEverCookie}
              loading={isLoading}
              {...(isLoading && { iconLeft: "loading" })}
              fullWidth
            >
              {uid !== ""
                ? t("evercookieTest.buttonRediscover")
                : t("evercookieTest.buttonCreate")}
            </Button>
          </TestCard>
        </TestWrapper>
        <ResultsWrapper>
          <RestyledTitle tag="h2">
            {t("evercookieTest.testResult")}
          </RestyledTitle>
          <ResultsTable data={result} isLoading={isLoading} />
        </ResultsWrapper>
      </InnerContainer>
    </RectyledContainer>
  );
};
