import { useState } from "react";

import { useSelector } from "react-redux";

import Search from "../../../../../components/ui/New/Search/Search";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { servicesList } from "../../../../../utils/constants";
import { Countries } from "../Countries/Countries";

import {
  StyledLocationsList,
  StyledProxiesNavigation,
  StyledProxiesNavigationItem
} from "./ProxiesNavigation.styled";

export const ProxiesNavigation = () => {
  const [search, setSearch] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("topLocations");
  const { countries } = useSelector(getAllContent);

  return (
    <StyledProxiesNavigation>
      <StyledProxiesNavigationItem title="proxies" list={servicesList} />
      <StyledProxiesNavigationItem title="locations">
        <Search value={search} onChange={setSearch} variant="outlined-dark" />
        <StyledLocationsList
          selectedLocation={selectedLocation}
          onClick={setSelectedLocation}
        />
      </StyledProxiesNavigationItem>
      <StyledProxiesNavigationItem title={selectedLocation}>
        <Countries
          countries={countries}
          search={search}
          selectedLocation={selectedLocation}
        />
      </StyledProxiesNavigationItem>
    </StyledProxiesNavigation>
  );
};
