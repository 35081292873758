export const listWithNumbers = {
  en: [
    {
      title: "Choose a Reputable Proxy Service",
      description:
        "Research and select a well-established proxy service with a good track record. Look for reviews and feedback from other users to ensure reliability."
    },
    {
      title: "Choose a Reputable Proxy Service",
      description:
        "Understand Costs. Proxy services often charge fees for their help. Be sure to understand the cost structure"
    },
    {
      title: "Check Legalities ",
      description:
        "Check Legalities. While proxy purchasing is often legal, it’s essential to verify it. It’s whether the practice allowed for specific items or within certain regions."
    },
    {
      title: "Provide Accurate Information",
      description:
        "When submitting your proxy request, ensure that all details are accurate. It’s to prevent any issues during the purchasing and shipping process."
    },
    {
      title: "Provide Accurate Information",
      description:
        "When submitting your proxy request, ensure that all details are accurate. It’s to prevent any issues during the purchasing and shipping process."
    }
  ],
  ru: [
    {
      title: "Выберите надежную прокси-службу",
      description:
        "Исследуйте и выберите хорошо зарекомендовавшую себя прокси-службу с хорошей репутацией. Ищите отзывы и комментарии от других пользователей, чтобы убедиться в надежности."
    },
    {
      title: "Выберите надежную прокси-службу",
      description:
        "Понимание затрат. Прокси-службы часто взимают плату за свои услуги. Убедитесь, что понимаете структуру затрат."
    },
    {
      title: "Проверьте правовые аспекты",
      description:
        "Проверьте правовые аспекты. Хотя покупка прокси обычно является законной, важно это проверить. Убедитесь, что такая практика разрешена для конкретных товаров или в определённых регионах."
    },
    {
      title: "Предоставьте точную информацию",
      description:
        "При подаче запроса на прокси убедитесь, что все детали точны. Это поможет избежать проблем в процессе покупки и доставки."
    },
    {
      title: "Предоставьте точную информацию",
      description:
        "При подаче запроса на прокси убедитесь, что все детали точны. Это поможет избежать проблем в процессе покупки и доставки."
    }
  ],
  ua: [
    {
      title: "Оберіть надійну проксі-службу",
      description:
        "Досліджуйте та вибирайте добре зарекомендувану проксі-службу з хорошою репутацією. Шукайте відгуки та коментарі від інших користувачів, щоб забезпечити надійність."
    },
    {
      title: "Оберіть надійну проксі-службу",
      description:
        "Розуміння витрат. Проксі-служби часто стягують плату за свої послуги. Переконайтесь, що розумієте структуру витрат."
    },
    {
      title: "Перевірте правові аспекти",
      description:
        "Перевірте правові аспекти. Хоча купівля проксі зазвичай є законною, важливо перевірити це. Переконайтесь, що така практика дозволена для конкретних товарів або в певних регіонах."
    },
    {
      title: "Надайте точну інформацію",
      description:
        "При поданні запиту на проксі переконайтесь, що всі деталі є точними. Це допоможе уникнути проблем під час процесу покупки та доставки."
    },
    {
      title: "Надайте точну інформацію",
      description:
        "При поданні запиту на проксі переконайтесь, що всі деталі є точними. Це допоможе уникнути проблем під час процесу покупки та доставки."
    }
  ]
};

export const list = [
  "Lorem ipsum dolor sit amet consectetur. Nec pellentesque dictum amet orci massa varius ornare morbi.",
  "Lorem ipsum dolor sit amet consectetur. Nec pellentesque dictum amet orci massa varius ornare morbi.",
  "Lorem ipsum dolor sit amet consectetur. Nec pellentesque dictum amet orci massa varius ornare morbi.",
  "Lorem ipsum dolor sit amet consectetur. Nec pellentesque dictum amet orci massa varius ornare morbi.",
  "Lorem ipsum dolor sit amet consectetur. Nec pellentesque dictum amet orci massa varius ornare morbi."
];
