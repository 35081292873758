import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledContactLink = styled(Link)`
position: relative;
display: flex;
align-items: center;
gap: 12px;
max-width: 100%;
& > span {
max-width: calc(100% - 36px);
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
font-family: ${(p) => p.theme.fontFamily.lato};
font-size: ${(p) => p.theme.fontSizes.m};
font-weight: ${(p) => p.theme.fontWeight.normal};
font-style: ${(p) => p.theme.fontStyle.normal};
line-height: 140%;
}
& > img {
    width: 24px;
    height: 24px;
    flex-none;
    }

    &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 36px;
    width: 0;
    height: 1px;
    background: ${(p) => p.theme.colors["neutral-800"]};
    transition: width 0.3s ease;
    }
    &:hover,
    &:focus {
    &::after {
        width: calc(100% - 36px);
        transition: width 0.3s ease;
        }
        }
        `;

export const StyledSocialList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  max-width: 100%;

  ${(p) =>
    p.isMedia
      ? `@media (min-width: ${p.theme.screenSizes.xlMin}) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 8px;
          }`
      : "null"}
`;

export const StyledSocialItem = styled.li`
  display: flex;
  align-items: center;
  max-width: 100%;
`;
