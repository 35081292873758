import { $api } from "../../../../../services/AxiosInstance";
import { AllActions } from "../../../../../store/reducers/AllActions";

import { ProxyEdit } from "./ProxyEdit/ProxyEdit";

const ProxyEditPage = () => (
  <>
    <ProxyEdit />
  </>
);

export default ProxyEditPage;

ProxyEditPage.getServerSideState = async (store, params) => {
  const { data: pageContent } = await $api.post(
    "/api/page/front/content",
    {
      ...params,
      languageCode: params.locale
    }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.locale,
      innerTag: params.location
    })
  );
};
