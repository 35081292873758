import { useState } from "react";

import { matchPath, useLocation } from "react-router-dom";

import {
  AppRoutes,
  AppRoutesOLD,
  AuthRoutes,
  AuthRoutesOLD,
  CabinetRoutesOLD,
  DashboardRoutes,
  ExportRoutes,
  SidebarRoutesOLD,
  ToolsRoutes
} from "../routes";
import { getCurrentRoutes } from "../utils/helpers";

export const useMatchedRoute = () => {
  const { pathname } = useLocation();
  const allRoutes = [
    ...AppRoutesOLD(),
    ...AppRoutes(),
    ...AuthRoutes(),
    ...AuthRoutesOLD(),
    ...CabinetRoutesOLD(),
    ...DashboardRoutes(),
    ...ToolsRoutes(),
    ...CabinetRoutesOLD(),
    ...SidebarRoutesOLD(),
    ...ExportRoutes()
  ];
  const [routes] = useState(
    getCurrentRoutes(allRoutes, process.env.REACT_APP_MODE === "dev").map(
      (route) => ({ ...route, component: null, element: null })
    )
  );

  const splittedPathname = pathname.split("/").filter(Boolean);
  if (
    splittedPathname.length > 0 &&
    !["ru", "ua"].includes(splittedPathname[0])
  ) {
    return routes.find((route) =>
      matchPath({ path: route?.path.replace("/:lang?", "") }, pathname)
    );
  }

  return routes.find((route) => matchPath({ path: route?.path }, pathname));
};
