import { useEffect, useRef } from "react";

import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

import { getAllSite } from "../../../store/reducers/SiteReducer/Site.selectors";

import { useDispatchedActions, useOnClickOutside } from "../../../hooks";

import "./BurgerMenu.scss";

export const BurgerMenu = ({ children }) => {
  // **Redux state
  const { isMobileMenuOpened } = useSelector(getAllSite);

  // Dispatch
  const { setMobileMenu } = useDispatchedActions();

  // **Ref
  const menuRef = useRef(null);
  // Hooks
  useOnClickOutside(menuRef, () => {
    if (isMobileMenuOpened) {
      setMobileMenu(false);
    }
  });

  // Lock scroll
  useEffect(() => {
    if (isMobileMenuOpened) {
      // eslint-disable-next-line no-undef
      document.body.classList.add("lock");
    } else {
      // eslint-disable-next-line no-undef
      document.body.classList.remove("lock");
    }

    return () => document.body.classList.remove("lock");
  }, [isMobileMenuOpened]);

  return (
    isMobileMenuOpened &&
    createPortal(
      <div
        ref={menuRef}
        className={
          isMobileMenuOpened ? "burger-menu burger-menu--active" : "burger-menu"
        }
      >
        {children}
      </div>,
      document?.body
    )
  );
};
