import styled from "@emotion/styled";

export const StyledAuthNavigate = styled.div`
  position: ${(p) => (p.isStatic ? "static" : "absolute")};
  display: flex;
  justify-content: ${(p) => (p.inReviewResponse ? "flex-start" : "center")};
  flex-wrap: wrap;
  min-width: ${(p) => (p.inReviewResponse ? "100%" : "250px")};
  width: 100%;
  top: 116px;
  left: 50%;
  transform: ${(p) => (p.isStatic ? "none" : "translateX(-50%)")};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    top: 48px;
  }

  & > span {
    color: ${(p) =>
      p.inReviewResponse
        ? p.theme.colors["neutral-500"]
        : p.theme.colors["neutral-800"]};
    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: ${(p) =>
      p.inReviewResponse ? p.theme.fontSizes.s : p.theme.fontSizes.m};
    font-weight: ${(p) => p.theme.fontWeight.normal};
    font-style: ${(p) => p.theme.fontStyle.normal};
    line-height: ${(p) => (p.inReviewResponse ? "170%" : "150%")};
    margin-right: 8px;
  }
  ${(p) =>
    p.inReviewResponse
      ? `
      margin-top: 12px;
    margin-bottom: 16px;
      @media (min-width: ${p.theme.screenSizes.xlMin}) {
        margin-bottom: 24px;
      }

    & > button, & > a {
     font-size: ${p.theme.fontSizes.s};
     font-weight: ${p.theme.fontWeight.normal};
    font-style: ${p.theme.fontStyle.normal};
    line-height: 170%;
    }
`
      : ""}
`;
