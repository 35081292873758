import styled from "@emotion/styled";

export const StyledBlockFeaturesBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const StyledBlockFeaturesItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const FeaturesItemLabel = styled.span`
  flex: 1 0 calc(50% - 8px);
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 130%;
`;

export const FeaturesItemBox = styled.div`
  flex: 1 0 calc(50% - 8px);
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  & > .form-field {
    min-width: fit-content;
    width: calc((100% - 16px) / 2);
  }
`;
