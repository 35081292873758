import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAllUser } from "../../../../store/reducers/UserReducer/User.selectors";

import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";

import { StyledButton } from "./AuthButton.styled";

const AuthButton = ({ variant }) => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Redux state
  const { isUserAuthenticated, user } = useSelector(getAllUser);

  // **Dispatch
  const { setMobileMenu } = useDispatchedActions();

  const closeMenu = () => {
    setMobileMenu(false);
  };

  if (isUserAuthenticated) {
    return (
      <StyledButton
        variant="outlined"
        color={variant}
        linkTo={`${hrefLang}/new/dashboard/profile/`}
        onClick={closeMenu}
      >
        {user.email}
      </StyledButton>
    );
  }

  return (
    <StyledButton
      variant="outlined"
      color={variant}
      linkTo={`${hrefLang}/new/login/`}
      onClick={closeMenu}
      iconRight="logIn"
    >
      {t("navigation.logIn")}
    </StyledButton>
  );
};

AuthButton.propTypes = {
  variant: PT.oneOf(["light", "dark"])
};

AuthButton.defaultProps = {
  variant: "dark"
};

export default AuthButton;
