import { useTranslation } from "react-i18next";

import { AllActions } from "../../../store/reducers/AllActions";
import { $api } from "../../../services/AxiosInstance";

import { Breadcrumbs } from "../../../components/common";

import { Blog } from "./Blog/Blog";

export const BlogPage = () => {
  // **Redux state
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs items={t("blogPage.breadcrumbs", { returnObjects: true })} />
      <Blog />
    </>
  );
};

export default BlogPage;

BlogPage.getServerSideState = async (store, params) => {
  const { data: goalsByGroups } = await $api.get(
    "/api/front/goal/group/all",
    { params: { languageCode: params.locale } }
  );
  const { data: blogContent } = await $api.get("/api/front/site/blog", {
    params: {
      page: 0,
      size: 16,
      category: params.params.tab === "articles",
      languageCode: params.locale
    }
  });

  const { data: sidebar } = await $api.get(
    "/api/front/site/blog/sidebar",
    { params: { languageCode: params.locale } }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(
    AllActions.setBlogContent({
      ...blogContent,
      params: {
        page: 0,
        size: 16,
        category: params.params.tab === "articles",
        languageCode: params.locale
      }
    })
  );
  store.dispatch(
    AllActions.setBlogSidebarContent({
      ...sidebar,
      languageCode: params.locale
    })
  );
};
