import styled from "@emotion/styled";

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 100%;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxBox = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  flex: none;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};

  color: ${(p) => {
    if (p.checked && !p.disabled) {
      return p.theme.colors["main-700"];
    }
    return p.theme.colors["outline-color"];
  }};
  &:hover,
  &:focus-within {
    color: ${(p) => {
      if (p.disabled) {
        return p.theme.colors["neutral-100"];
      }
      return p.checked ? "#79B22F" : p.theme.colors["main-700"];
    }};
  }

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none !important;
  cursor: inherit;
  margin: 0;
  border: 0;
  background: transparent;
  opacity: 0;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 8px;
  color: ${(p) => p.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: normal;
`;
