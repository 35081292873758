import styled from "@emotion/styled";

export const StyledTabsList = styled.ul`
  list-style: none;
  padding: 0;
  display: ${(p) => (p.variant === "site_info" ? "flex" : "none")};
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  padding: ${(p) => (p.variant === "site_info" ? "4px" : "8px")};
  overflow-x: auto;

  border-radius: 8px;
  background: ${(p) => p.theme.colors["dark-50"]};

  @media (min-width: 480px) {
    gap: 8px;
    justify-content: flex-start;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    display: ${(p) => (p.variant === "site_info" ? "none" : "flex")};
    gap: 8px;
  }
`;

export const StyledTab = styled.li`
  margin: 0;
  width: 100%;
  @media (min-width: 480px) {
    width: auto;
  }
`;

export const StyledTabButton = styled.button`
  flex: none;
  min-width: fit-content;
  width: 100%;
  white-space: nowrap;

  padding: ${(p) => (p.variant === "site_info" ? "8px" : "8px 12px")};
  height: ${(p) => (p.variant === "site_info" ? "44px" : "48px")};
  background: ${(p) => (p.active ? p.theme.colors.white : "transparent")};
  border: none;
  border-radius: 8px;
  outline: none;
  color: ${(p) =>
    p.active ? p.theme.colors["neutral-800"] : p.theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  box-shadow: ${(p) => {
    if (p.active) {
      return p.variant === "site_info"
        ? p.theme.shadows.tabButton
        : p.theme.shadows.tabButton2;
    }
    return "none";
  }};

  transition: color 0.3s ease, background 0.3s ease;

  @media (min-width: 480px) {
    width: auto;
  }

  &:hover,
  &:focus {
    color: ${(p) => p.theme.colors["neutral-800"]};
    transition: color 0.3s ease;
  }
`;
