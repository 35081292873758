import PT from "prop-types";
import { useTranslation } from "react-i18next";

import Tooltip from "../../../../components/ui/New/Tooltip/Tooltip";
import { useLangUrlDefault } from "../../../../hooks";

import {
  ExportName,
  ExportVariant,
  ExportVariants,
  ExportWrapper,
  RestyledIcon
} from "./Export.styled";

export const Export = ({ tooltip, txtData, exportData }) => {
  const { t } = useTranslation();

  const [, hrefLang] = useLangUrlDefault();

  const exportToExcel = () => {
    const link = document.createElement("a");

    link.setAttribute(
      "href",
      `data:text/json;charset=utf-8,${encodeURIComponent(
        txtData.map((item) => item).join("\n")
      )}`
    );

    link.setAttribute("download", "Data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToTxt = () => {
    // eslint-disable-next-line no-undef
    const blob = new Blob([txtData?.join("\n")], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "Data.txt";
    link.href = url;
    link.click();
  };

  const exportToUrl = () => {
    localStorage.setItem("exportData", JSON.stringify(exportData));
    const newWin = window.open(`${hrefLang}/export/`, "_blank");
    if (newWin === null) {
      setTimeout(() => {
        window.open(`${hrefLang}/export/`, "_blank");
      });
    }
  };

  return (
    <ExportWrapper>
      <ExportName>{t("ui.export")}</ExportName>
      <Tooltip body={tooltip} place="top-start">
        <RestyledIcon name="infoOutline" size={"lg"} />
      </Tooltip>
      <ExportVariants>
        <ExportVariant role="button" onClick={exportToTxt}>
          txt
        </ExportVariant>
        <ExportVariant role="button" onClick={exportToExcel}>
          excel
        </ExportVariant>
        <ExportVariant role="button" onClick={exportToUrl}>
          url
        </ExportVariant>
      </ExportVariants>
    </ExportWrapper>
  );
};

Export.propTypes = {
  txtData: PT.arrayOf(PT.string).isRequired
};
