import styled from "@emotion/styled";

import Icon from "../../../../../ui/New/Icon/Icon";

export const StyledLocationItem = styled.li`
  padding: 12px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 8px;
  background: ${(p) =>
    p.active ? "rgba(255, 255, 255, 0.08)" : "transparent"};

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover,
  &:focus,
  &:active {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.08);
  }
`;

export const StyledIcon = styled(Icon)`
  transform: rotate(-90deg);
`;
