import PT from "prop-types";

import {
  StyledImageWrapper,
  StyledItem,
  StyledItemTitle,
  StyledNumber
} from "./IpCheckItem.styled";

export const IpCheckItem = ({ title, image, number }) => (
  <StyledItem>
    <StyledImageWrapper>
      <img src={image} loading="lazy" alt={title} aria-hidden="true" />
    </StyledImageWrapper>
    <div>
      <StyledItemTitle>{title}</StyledItemTitle>
      <StyledNumber>{number}</StyledNumber>
    </div>
  </StyledItem>
);

IpCheckItem.propTypes = {
  title: PT.string,
  number: PT.oneOfType([PT.string, PT.number]),
  image: PT.node
};

IpCheckItem.defaultProps = {
  title: "",
  number: 0,
  image: null
};
