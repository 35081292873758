import styled from "@emotion/styled";

import { FormSelect } from "../../../../../../components/forms/New/FormSelect/FormSelect";
import Button from "../../../../../../components/ui/New/Button/Button";
import { Title } from "../../../../../../components/ui/New/Title/Title";

export const StyledContainer = styled.div`
  position: relative;
  z-index: 2;
  margin-top: -145px;
  padding: 36px;
  border-radius: 12px;
  background: white;
  box-shadow: 0px 5px 28px 0px rgba(135, 140, 189, 0.08);
  margin-bottom: 64px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: -284px;
    margin-bottom: 120px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 16px !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 1.21 !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 24px !important;
    font-size: 32px !important;
    line-height: 1.18 !important;
  }
`;

export const StyledInputWrapper = styled.div`
  margin-top: 0 !important;
  margin-right: 12px;
  margin-bottom: 16px;
  width: 100%;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
  }

`;

export const StyledFormSelect = styled(FormSelect)`
  margin-bottom: 16px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
  }
`;

export const StyledButton = styled(Button)`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-left: 12px;
    margin-top: 26px;
  }
`;

export const StyledAdditionalText = styled.button`
  padding-top: 5px;
  color: ${({ theme }) => theme.colors["main-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 115%;
  cursor: pointer;

  &::after {
    content: "";
    position: relative;
    top: -1px;
    width: 100%;
    height: 1px;
    display: block;
    background: ${({ theme }) => theme.colors["main-600"]};
  }
`;

export const StyledInfoMessage = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 36px;
  color: ${({ theme }) => theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 139%;

  img {
    margin-right: 12px;
  }
`;

export const StyledResults = styled.div`
  display: ${(p) => (p.isLoading ? "flex" : "block")};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 230px;
  border-radius: 12px;
  border: 1px solid #d8dae0;
  padding: 24px 24px 14px 24px;
`;

export const StyledResultsList = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

export const StyledResultsItem = styled.div`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 4px;
`;
