import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../../../hooks";
import { getAllBlogInner } from "../../../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

import {
  StyledIcon,
  StyledIconWrapper,
  StyledNavigation,
  StyledNavigationItem,
  StyledText,
  StyledTitle,
  StyledWrapper
} from "./ArticleNavigation.styled";

export const ArticleNavigation = () => {
  const { t } = useTranslation();
  // **Props
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { content } = useSelector(getAllBlogInner);

  return (
    <StyledNavigation>
      {content.data.previousArticleAlias && (
        <StyledNavigationItem>
          <Link
            to={`${hrefLang}/new/blog/${content.data.previousArticleAlias}`}
          >
            <StyledWrapper>
              <StyledIconWrapper className="icon-wrapper">
                <StyledIcon name="arrowDown" variant="prev" />
              </StyledIconWrapper>
              <StyledText variant="prev">
                {t("blogInnerPage.articleBody.prev")}
              </StyledText>
            </StyledWrapper>
            <StyledTitle variant="prev">
              {content.data.previousArticleTitle}
            </StyledTitle>
          </Link>
        </StyledNavigationItem>
      )}
      {content.data.nextArticleAlias && (
        <StyledNavigationItem next>
          <Link to={`${hrefLang}/new/blog/${content.data.nextArticleAlias}`}>
            <StyledWrapper className="wrapper-next">
              <StyledText variant="next">
                {t("blogInnerPage.articleBody.next")}
              </StyledText>
              <StyledIconWrapper className="icon-wrapper">
                <StyledIcon name="arrowDown" variant="next" />
              </StyledIconWrapper>
            </StyledWrapper>
            <StyledTitle variant="next">
              {content.data.nextArticleTitle}
            </StyledTitle>
          </Link>
        </StyledNavigationItem>
      )}
    </StyledNavigation>
  );
};
