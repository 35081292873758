import { createSlice } from "@reduxjs/toolkit";

import {
  getAllFreeProxyContent,
  getCities,
  getFreeProxyContent
} from "./FreeProxy.thunks";

const initialState = {
  freeProxy: {
    data: {
      totalElements: 0,
      totalPages: 0,
      content: [],
      skip: 0
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  allFreeProxy: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  cities: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  }
};

const FreeProxyReducer = createSlice({
  name: "freeproxy",
  initialState,
  reducers: {
    setFreeProxyContent(state, action) {
      state.freeProxy.data = action.payload;
      state.freeProxy.isDataLoaded = true;
    },
    setAllFreeProxyContent(state, action) {
      state.allFreeProxy.data = action.payload;
      state.allFreeProxy.isDataLoaded = true;
    },
    setCities(state, action) {
      state.cities.data = action.payload;
      state.cities.isDataLoaded = true;
    }
  },
  extraReducers: (builder) => {
    // getFreeProxyContent
    builder.addCase(getFreeProxyContent.pending, (state) => {
      state.freeProxy.isError = null;
      state.freeProxy.isLoading = true;
    });
    builder.addCase(getFreeProxyContent.fulfilled, (state, action) => {
      state.freeProxy.isError = null;
      state.freeProxy.data = action.payload;
      state.freeProxy.isLoading = false;
      state.freeProxy.isDataLoaded = true;
    });
    builder.addCase(getFreeProxyContent.rejected, (state, action) => {
      state.freeProxy.isError = action.payload;
      state.freeProxy.isLoading = false;
    });
    // getAllFreeProxyContent
    builder.addCase(getAllFreeProxyContent.pending, (state) => {
      state.allFreeProxy.isError = null;
      state.allFreeProxy.isLoading = true;
    });
    builder.addCase(getAllFreeProxyContent.fulfilled, (state, action) => {
      state.allFreeProxy.isError = null;
      state.allFreeProxy.data = action.payload;
      state.allFreeProxy.isLoading = false;
      state.allFreeProxy.isDataLoaded = true;
    });
    builder.addCase(getAllFreeProxyContent.rejected, (state, action) => {
      state.allFreeProxy.isError = action.payload;
      state.allFreeProxy.isLoading = false;
    });
    // getCities
    builder.addCase(getCities.pending, (state) => {
      state.cities.isError = null;
      state.cities.isLoading = true;
    });
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.cities.isError = null;
      state.cities.data = action.payload;
      state.cities.isLoading = false;
      state.cities.isDataLoaded = true;
    });
    builder.addCase(getCities.rejected, (state, action) => {
      state.cities.isError = action.payload;
      state.cities.isLoading = false;
    });
  }
});

export const FreeProxyReducerActions = {
  ...FreeProxyReducer.actions,
  getFreeProxyContent,
  getAllFreeProxyContent,
  getCities
};
export default FreeProxyReducer.reducer;
