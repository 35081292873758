import PromocodesCreatePage from "../../pages/CabinetPages/pages/Promocodes/PromocodesCreatePage/PromocodesCreatePage";
import PromocodesEditPage from "../../pages/CabinetPages/pages/Promocodes/PromocodesEditPage/PromocodesEditPage";
import PromocodesListPage from "../../pages/CabinetPages/pages/Promocodes/PromocodesListPage/PromocodesListPage";
import ProxyCreatePage from "../../pages/CabinetPages/pages/Proxy/ProxyCreatePage/ProxyCreatePage";
import ProxyEditPage from "../../pages/CabinetPages/pages/Proxy/ProxyEditPage/ProxyEditPage";
import ProxyListPage from "../../pages/CabinetPages/pages/Proxy/ProxyListPage/ProxyListPage";

export const CabinetRoutesOLD = (ssr) => [
  {
    path: "/:lang?/cabinet/proxy/",
    tag: "cabinet-proxy",
    type: "cabinet",
    component: ssr ? ProxyListPage : () =>
      import(
        "../../pages/CabinetPages/pages/Proxy/ProxyListPage/ProxyListPage"
      ),
    isHeaderFull: true,
    isAuthRequired: true
  },
  {
    path: "/:lang?/cabinet/proxy/create/",
    tag: "cabinet-proxy-create",
    type: "cabinet",
    component: ssr ? ProxyCreatePage : () =>
      import(
        "../../pages/CabinetPages/pages/Proxy/ProxyCreatePage/ProxyCreatePage"
      ),
    isHeaderFull: true,
    isAuthRequired: true
  },
  {
    path: "/:lang?/cabinet/proxy/edit/:id",
    tag: "cabinet-proxy-edit",
    type: "cabinet",
    component: ssr ? ProxyEditPage : () =>
      import(
        "../../pages/CabinetPages/pages/Proxy/ProxyEditPage/ProxyEditPage"
      ),
    isHeaderFull: true,
    isAuthRequired: true
  },
  {
    path: "/:lang?/cabinet/promocodes/",
    tag: "cabinet-promocodes",
    type: "cabinet",
    component: ssr ? PromocodesListPage : () =>
      import(
        "../../pages/CabinetPages/pages/Promocodes/PromocodesListPage/PromocodesListPage"
      ),
    isAuthRequired: true
  },
  {
    path: "/:lang?/cabinet/promocodes/create",
    tag: "cabinet-promocodes-create",
    type: "cabinet",
    component: ssr ? PromocodesCreatePage : () =>
      import(
        "../../pages/CabinetPages/pages/Promocodes/PromocodesCreatePage/PromocodesCreatePage"
      ),
    isHeaderFull: true,
    isAuthRequired: true
  },
  {
    path: "/:lang?/cabinet/promocodes/edit/:id",
    tag: "cabinet-promocodes-edit",
    type: "cabinet",
    component: ssr ? PromocodesEditPage : () =>
      import(
        "../../pages/CabinetPages/pages/Promocodes/PromocodesEditPage/PromocodesEditPage"
      ),
    isHeaderFull: true,
    isAuthRequired: true
  }
];
