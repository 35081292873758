import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "../../../../../components/ui/New/Button/Button";
import { Separator } from "../../../../../components/ui/New/Separator/Separator";
import { useDispatchedActions, useLangUrlDefault } from "../../../../../hooks";
import { ApiService } from "../../../../../services";
import { getAllCabinet } from "../../../../../store/reducers/CabinetReducer/Cabinet.selectors";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllTranslation } from "../../../../../store/reducers/TranslationReducer/Translation.selectors";
import { ServiceSchema } from "../../../../../utils/validation";
import { StyledForm } from "../../AddEditPromocode/AddEditPromocode.styled";
import FormBlock from "../../components/FormBlock/FormBlock";

import { BlockAuth } from "./BlockAuth/BlockAuth";
// import { BlockBenefits } from "./BlockBenefits/BlockBenefits";
import { BlockContacts } from "./BlockContacts/BlockContacts";
import { BlockDescriptions } from "./BlockDescriptions/BlockDescriptions";
import { BlockFeatures } from "./BlockFeatures/BlockFeatures";
import { BlockLogo } from "./BlockLogo/BlockLogo";
import { BlockPromocode } from "./BlockPromocode/BlockPromocode";
import { BlockType } from "./BlockType/BlockType";

export const Form = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { languages } = useSelector(getAllTranslation);
  const [queryLang, hrefLang] = useLangUrlDefault();

  const { proxyTypes, socials } = useSelector(getAllContent);
  const { proxies } = useSelector(getAllCabinet);

  // **Dispatch
  const { setCabinetProxiesPrevParams, getAllCabinetSites } =
    useDispatchedActions();
  // const proxyForLang = proxyTypes.data?.[queryLang];

  const [proxyForLang, setProxyForLang] = useState(
    proxyTypes.data?.[queryLang]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [proxySiteData, setProxySiteData] = useState(null);
  const methods = useForm({
    resolver: yupResolver(
      ServiceSchema(
        t("forms", {
          returnObjects: true
        }),
        languages,
        proxyForLang
      )
    )
  });
  const formData = methods.watch();
  // **Local state
  // eslint-disable-next-line no-unused-vars
  const [files, setFiles] = useState([]);
  const [croppedFileObjects, setCroppedFileObjects] = useState([]);

  const handleSave = async (data) => {
    try {
      setIsLoading(true);

      const curSocials = Object.keys(data?.socialNetworks)
        .filter((key) => data?.socialNetworks?.[key])
        .map((key) => ({
          socialId: socials.data.find((it) => it.code === key).id,
          link: data?.socialNetworks?.[key],
          showOnFront: true
        }));

      const proxyTypeProps = Object.keys(data?.proxy)
        .filter((key) => data.proxyTypes?.[key])
        .map((key) => ({
          proxyTypeId: proxyTypes.data?.[queryLang].find(
            (it) => it.type === key
          ).id,
          proxyType: key,
          id:
            proxySiteData?.proxyTypeProps?.find((it) => it.proxyType === key)
              ?.id || null,
          siteId: id || null,
          link: data?.proxy?.[key]?.link,
          minRentPeriodId: data?.proxy?.[key]?.minRent,
          minPrice: Number(data?.proxy?.[key]?.minPrice),
          countryIds: data?.proxy?.[key]?.locations,
          pullIp: data?.proxy?.[key]?.pullIp || null,
          minBytes: data?.proxy?.[key]?.minGb
            ? data?.proxy?.[key]?.minGb * 1073741824
            : null
        }));
      const [startDate, endDate] = data?.dateRange || [];
      const params = {
        name: data?.name,
        description: data?.description,
        advantages: data?.advantages,
        link: data?.link,
        clientNoAuth: data?.clientNoAuth || false,
        clientLoginAuth: data?.clientLoginAuth || false,
        clientIPAuth: data?.clientIPAuth || false,
        affiliateProgram: data?.affiliateProgram === "true",
        freeTest: data?.freeTest === "true",
        individual: data?.individual === "true",
        replacementPossibility: data?.replacementPossibility === "true",
        refunds: data?.refunds === "true",
        api: data?.api === "true",
        socialNetworks: curSocials,
        promocode: data?.promocode
          ? {
              siteId: id || null,
              promocode: data?.promocode,
              startDate: startDate || null,
              endDate: endDate || null,
              description: data?.promocodeDescription,
              eternal: data?.eternal === "eternal",
              isActive: data?.isActive || false
            }
          : null,
        proxyTypeProps
      };

      // If site edit, add some new values
      if (id) {
        params.id = id;
        // params.userId = user.uid;
      }

      const siteResponse = !id
        ? await ApiService.createProxySiteNew(params)
        : await ApiService.updateProxySiteNew(params);

      // Handling error for "createSiteResponse" query
      if (siteResponse && siteResponse.status !== 200) {
        if (siteResponse?.request?.status === 409) {
          toast.error(t("notifications.site.exist"));
          methods.setError("link", {
            type: "manual",
            message: t("notifications.site.exist")
          });
          return setIsLoading(false);
        }

        throw siteResponse;
      }

      // Show success message that site has been created / updated
      toast.success(
        id ? t("notifications.site.edited") : t("notifications.site.created")
      );

      // Set prev params to null in order to load new site
      setCabinetProxiesPrevParams(null);

      // Getting new sites for promocodes
      getAllCabinetSites(proxies.prevParams);

      // Check if file is added, if no go the main cabinet page
      if (!files.length) {
        if (!id) {
          navigate(`${hrefLang}/new/dashboard/services/`, { replace: true });
        }

        return;
      }

      // If file exists, uploading it to the server
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("fileTop", files[1]);

      const imageUploadResponse = await ApiService.uploadImage(
        formData,
        siteResponse?.data?.id || id,
        toast,
        t("notifications.image.uploading")
      );

      // Handling error for "imageUploadResponse" query
      if (imageUploadResponse && imageUploadResponse.status !== 200) {
        throw imageUploadResponse;
      }

      setProxySiteData((prevState) => ({
        ...prevState,
        image: imageUploadResponse?.data?.file,
        imageTop: imageUploadResponse?.data?.fileTop
      }));
      // setDefaultValues?.((prevState) => ({
      //   ...prevState,
      //   image: imageUploadResponse?.data?.file,
      //   imageTop: imageUploadResponse?.data?.fileTop
      // }));

      // Show success message that image has been uploaded
      toast.success(t("notifications.image.uploaded"));

      setTimeout(() => {
        if (!id) {
          navigate(`${hrefLang}/new/dashboard/services/`, { replace: true });
        }
      }, 300);
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setProxyForLang(proxyTypes.data?.[queryLang]);
    methods.reset({});
    setFiles([]);
    setCroppedFileObjects([]);
    navigate(`${hrefLang}/new/dashboard/services/`, { replace: true });
  };
  useEffect(() => {
    // TODO: try to remove this useEffect ( refactoring shema validation)

    const temp = proxyTypes.data?.[queryLang].map((item) => ({
      ...item,
      checked: proxyForLang?.[item.type] || false
    }));

    setProxyForLang(temp);

    // NOTE REMOVE THIS
    if (methods && Object.keys(methods.formState.errors).length > 0) {
      methods.trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, proxyTypes]);

  // TODO: try to remove this useEffect ( refactoring shema validation)
  useEffect(() => {
    const { proxyTypes } = formData;
    let check = false;
    const newProxyForLang = proxyForLang.map((item) => {
      if (proxyTypes?.[item.type] !== item.checked) {
        check = true;
        return {
          ...item,
          checked: proxyTypes[item.type]
        };
      }
      return item;
    });
    if (check) {
      setProxyForLang(newProxyForLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (!id) {
      return;
    }
    ApiService.getProxySiteById(id)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setProxySiteData(res.data);
        } else {
          throw new Error("error");
        }
      })
      .catch(() => {
        toast.error(
          "Error while fetching data from server! Please try again later."
        );
        navigate(`${hrefLang}/new/dashboard/services/`, { replace: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!proxySiteData) {
      return;
    }

    if (methods) {
      methods.setValue("link", proxySiteData.link);
      methods.setValue("description", proxySiteData.description);
      methods.setValue("advantages", proxySiteData.advantages || {});
      methods.setValue("name", proxySiteData.name);
      methods.setValue("clientNoAuth", proxySiteData.clientNoAuth);
      methods.setValue("clientLoginAuth", proxySiteData.clientLoginAuth);
      methods.setValue("clientIPAuth", proxySiteData.clientIPAuth);
      methods.setValue("affiliateProgram", proxySiteData.affiliateProgram);
      methods.setValue("freeTest", proxySiteData.freeTest);
      methods.setValue("individual", proxySiteData.individual);
      methods.setValue(
        "replacementPossibility",
        proxySiteData.replacementPossibility
      );
      methods.setValue("refunds", proxySiteData.refunds);
      methods.setValue("api", proxySiteData.api);
      methods.setValue(
        "proxyTypes",
        proxySiteData.proxyTypeProps.reduce((acc, item) => {
          acc[item.proxyType] = true;
          return acc;
        }, {})
      );
      methods.setValue("proxy", {
        ...proxySiteData.proxyTypeProps.reduce((acc, item) => {
          acc[item.proxyType] = {
            link: item.link,
            minRent: item.minRentPeriodId,
            minPrice: item.minPrice,
            locations: item.countryIds,
            pullIp: item.pullIp,
            minGb: item.minBytes ? item.minBytes / 1073741824 : null
          };
          return acc;
        }, {})
      });
      methods.setValue("socialNetworks", {
        ...socials.data.reduce((acc, item) => {
          const social = proxySiteData.socialNetworks.find(
            (it) => it.socialId === item.id
          );
          acc[item.code] = social ? social.link : "";
          return acc;
        }, {})
      });
    }
    // setFiles;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxySiteData, methods]);

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(handleSave)}>
        <BlockLogo
          files={files}
          setFiles={setFiles}
          croppedFileObjects={croppedFileObjects}
          setCroppedFileObjects={setCroppedFileObjects}
          defaultImage={proxySiteData?.image}
          setDefaultValues={setProxySiteData}
        />
        <Separator />
        <BlockDescriptions />
        <Separator />

        <BlockType proxyForLang={proxyForLang} />
        <Separator />

        <BlockAuth />
        <Separator />

        {/* <BlockBenefits /> */}
        <BlockFeatures />
        <Separator />

        {!id && (
          <>
            <BlockPromocode />
            <Separator />
          </>
        )}
        <BlockContacts />
        <Separator />
        <FormBlock bodyType={"buttons"}>
          <Button type="submit" loading={isLoading}>
            {t(`dashboard.services.addEdit.${id ? "edit" : "add"}`)}
          </Button>
          <Button
            variant={"secondary"}
            onClick={handleCancel}
            disabled={isLoading}
          >
            {t("forms.buttons.cancel")}
          </Button>
        </FormBlock>
      </StyledForm>
    </FormProvider>
  );
};
