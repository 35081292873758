/* eslint-disable no-nested-ternary */
import styled from "@emotion/styled";
import { Input } from "@mui/base/Input";

import Icon from "../Icon/Icon";

export const StyledInput = styled(Input)`
  display: flex;
  align-items: center;
  height: 44px;
  padding: 12px 8px 12px 16px;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.variant === "default"
        ? "#f7f8f9"
        : props.variant === "outlined"
        ? props.theme.colors["outline-color"]
        : "rgba(216, 218, 224, 0.14)"};
  background-color: ${(props) =>
    props.variant === "default" ? "#f7f8f9" : "transparent"};

  font-family: var(--font-family-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  & input {
    width: 100%;
    outline: none;
    border: none;
    color: ${(props) =>
      props.variant === "outlined-dark"
        ? "white"
        : props.theme.colors["neutral-800"]};
    background-color: ${(props) =>
      props.variant === "default" ? "#f7f8f9" : "transparent"};

    &::placeholder {
      color: ${(props) => props.theme.colors["neutral-200"]};
    }
    &:focus {
      outline: none;
    }
  }

  &:hover {
    border: 1px solid
      ${(props) =>
        props.variant === "default"
          ? "#f7f8f9"
          : props.variant === "outlined"
          ? props.theme.colors["neutral-500"]
          : props.theme.colors["outline-color"]};

    & *[class*="start__icon"] {
      color: ${(props) =>
        props.variant === "default"
          ? props.theme.colors["main-600"]
          : "inherit"};
    }
  }

  :focus::-webkit-input-placeholder {
    opacity: 0;
  }

  &:focus-within {
    border: 1px solid ${(props) => props.theme.colors["main-600"]};
    background: ${(props) =>
      props.variant === "default" ? "#f7ffe926" : "transparent"};

    & input {
      background-color: ${(props) =>
        props.variant === "default" ? "#f7ffe926" : "transparent"};
    }

    & *[class*="start__icon"] {
      color: ${(props) => props.theme.colors["neutral-400"]};
    }

    ::-webkit-input-placeholder {
      opacity: 0;
    }

    & input::placeholder {
      opacity: 0;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  display: ${(props) => (props.hidden ? "none" : "block")};
  cursor: ${(props) => (props.position === "end" ? "pointer" : "default")};
  width: 24px;
  min-width: 24px;
  height: 24px;
  color: ${(props) => props.theme.colors["neutral-400"]};
  margin-right: ${(props) => (props.position === "start" ? "8px" : "0")};
  margin-left: ${(props) => (props.position === "end" ? "8px" : "0")};
`;
