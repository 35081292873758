import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledServiceHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const StyledServiceHeadImage = styled.img`
  width: 44px;
  height: 44px;
  flex: none;
  object-fit: cover;
  color: transparent;
  overflow: hidden;
`;

export const StyledServiceHeadInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  max-width: calc(100% - 135px);
`;

export const StyledServiceHeadTitleLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors["neutral-800"]};
  position: relative;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 140%;
  width: fit-content;
  max-width: 100%;

  & > span {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background: ${(p) => p.theme.colors["neutral-800"]};
    transition: width 0.3s ease;
    width: 0;
  }

  &:hover,
  &:focus {
    &::after {
      width: 100%;
      transition: width 0.3s ease;
    }
  }
`;
