import styled from "@emotion/styled";

export const StyledHeading = styled.h1`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.18;
  margin-bottom: 16px;
  word-break: break-word;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.08;
    max-width: 724px;
  }
`;
