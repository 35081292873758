import styled from "@emotion/styled";

import Tooltip from "../../../../../../components/ui/New/Tooltip/Tooltip";

export const RestyledTooltip = styled(Tooltip)`
  height: 40px;
  font-size: 14px !important;
  font-style: normal;
  line-height: 1.1;
  justify-content: center;
`;
