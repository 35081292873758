/* eslint-disable no-empty-function */
import PT from "prop-types";

import Search from "../../../ui/New/Search/Search";

import { Title } from "../../../ui/New/Title/Title";

import { StyledWrapper } from "./SectionSearch.styled";

export const SectionSearch = ({
  title,
  className,
  titleClassName,
  searchClassName,
  variant,
  value,
  onChange,
  onClear,
  placeholder
}) => (
  <StyledWrapper className={className}>
    <Title tag="h2" className={titleClassName}>
      {title}
    </Title>
    <Search
      variant={variant}
      className={searchClassName}
      onChange={onChange}
      onClear={onClear}
      placeholder={placeholder}
      value={value}
    />
  </StyledWrapper>
);

SectionSearch.propTypes = {
  title: PT.string.isRequired,
  className: PT.string,
  titleClassName: PT.string,
  searchClassName: PT.string,
  variant: PT.oneOf(["outlined", "default"]),
  value: PT.string.isRequired,
  onChange: PT.func,
  onClear: PT.func,
  placeholder: PT.string
};

SectionSearch.defaultProps = {
  className: "",
  titleClassName: "",
  searchClassName: "",
  variant: "outlined",
  onChange: () => {},
  onClear: () => {},
  placeholder: "Search"
};
