import styled from "@emotion/styled";

export const StyledTitle = styled.p`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-family: ${({ theme }) => theme.fontFamily.title};
  text-align: center;
  word-wrap: break-all;

  font-size: 20px;
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 125%;

  margin-bottom: 8px;
  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    font-size: 24px;

    line-height: 120%;
  }
`;

export const StyledSubtitle = styled.p`
  color: ${({ theme }) => theme.colors["neutral-500"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  text-align: center;
  word-wrap: break-all;

  font-size: 16px;
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 150%;

  margin-bottom: 36px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: -15px;
  font-size: 0;
  max-width: ${(p) => (p?.imgMaxWidth ? `${p.imgMaxWidth}px` : "288px")};
`;
