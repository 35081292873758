import styled from "@emotion/styled";

import Button from "../../../ui/New/Button/Button";
import Languages from "../Languages/Languages";

export const StyledLanguages = styled(Languages)`
  padding-top: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  margin-top: 24px;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const StyledNavMobile = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const StyledNavDesktop = styled.div`
  display: none;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: grid;
    grid-template-columns: 70% 30%;
  }
`;

export const StyledAddSiteButton = styled(Button)`
  background-color: #ffffff14;
  background-image: none;
  color: white;
  margin-bottom: 12px;
  margin-top: auto;

  path {
    stroke: ${(p) => p.theme.colors["main-500"]};
  }
`;
