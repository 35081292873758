import styled from "@emotion/styled";

export const StyledBodyWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    padding-bottom: 48px;
  }
`;

export const StyledBody = styled.div`
  width: 100%;
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 18px;
  font-weight: 400;
  line-height: 1.66;
  word-break: break-word;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 70%;
    margin-right: 56px;
  }

  h2 {
    font-family: ${(p) => p.theme.fontFamily.title};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.18;
    margin: 24px 0;
  }

  h3 {
    font-family: ${(p) => p.theme.fontFamily.title};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin: 24px 0;
  }

  p {
    margin: 24px 0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  ul {
    margin: 24px 0;

    li {
      position: relative;
      padding-left: 8px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  ol {
    counter-reset: list;
    margin: 24px 0;

    li {
      &::before {
        counter-increment: list;
        content: counters(list, "") ". ";
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  img {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

export const StyledSummary = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    position: sticky;
    top: 50px;
    margin-bottom: 0;
  }
`;

export const StyledSummaryTitle = styled.p`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
`;

export const StyledSummaryItem = styled.li`
  display: ${(p) => (p.isActive ? "flex" : "block")};
  padding: 8px 0px 8px 24px;
  align-items: flex-start;
  align-self: stretch;
  color: ${(p) =>
    p.isActive ? p.theme.colors["neutral-800"] : p.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(p) => (p.isActive ? "700" : "400")};
  line-height: 1.5;
  cursor: pointer;
  border-left: ${(p) =>
    p.isActive
      ? `2px solid ${p.theme.colors["main-600"]}`
      : "2px solid transparent"};

  &::before {
    display: block;
    content: ${(p) => (p.isActive ? "" : "attr(id)")};
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    padding-top: 2px;
  }
`;
