import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";

import { AppRoutes } from "../../../../routes/new/app.routes";
import { ToolsRoutes } from "../../../../routes/new/tools.routes";

import { getAllSite } from "../../../../store/reducers/SiteReducer/Site.selectors";
import { getAllUser } from "../../../../store/reducers/UserReducer/User.selectors";
import { getAllBlogInner } from "../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import Logo from "../../../ui/New/Logo/Logo";
import AuthButton from "../../../ui/New/AuthButton/AuthButton";

import Languages from "../Languages/Languages";

import {
  AddSiteButton,
  BurgerDesktop,
  BurgerMobile,
  ButtonWrapper,
  HeaderContainer,
  LanguageWrapper,
  ServiceButton,
  StyledHeader
} from "./Header.styled";

export const Header = () => {
  const { t } = useTranslation();
  const { isServiceMenuOpened } = useSelector(getAllSite);

  // Dispatch
  const { setServiceMenu, setBurgerMenu, getBlogSidebarContent } = useDispatchedActions();
  const location = useLocation();
  const navigate = useNavigate();
  const router = matchRoutes(
    [...AppRoutes(), ...ToolsRoutes()],
    location.pathname
  )?.find((route) => route.pathname === location.pathname);
  const { isUserAuthenticated } = useSelector(getAllUser);

  const [queryLang, hrefLang] = useLangUrlDefault();
  const { sidebar } = useSelector(getAllBlogInner);
  const variant = router.route?.isHeadLight ? "light" : "dark";

  const redirectToCabinet = () => {
    if (!isUserAuthenticated) {
      navigate(`${hrefLang}/new/login/`, {
        replace: true
      });
    } else {
      navigate(`${hrefLang}/new/dashboard/services/create`, {
        replace: true
      });
    }
  };

  useEffect(() => {
    if (queryLang !== sidebar?.data?.languageCode) {
      getBlogSidebarContent({ languageCode: queryLang });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);

  const handleLogoClick = () => {
    setServiceMenu(false);
    setBurgerMenu(false);
  };
  return (
    <StyledHeader variant={variant}>
      <HeaderContainer>
        <BurgerDesktop variant={variant} />
        <Logo
          color={variant === "dark" ? "white" : "black"}
          onClick={handleLogoClick}
        />
        <ButtonWrapper>
          <ServiceButton
            size="nm"
            iconRight="arrowDown"
            isOpen={isServiceMenuOpened}
            onClick={() => {
              setBurgerMenu(false);
              setServiceMenu(!isServiceMenuOpened);
            }}
          >
            {t("navigation.selectService")}
          </ServiceButton>
          <AddSiteButton
            variant="secondary"
            size="nm"
            iconLeft="plus"
            color={variant}
            onClick={redirectToCabinet}
          >
            {t("navigation.addsite")}
          </AddSiteButton>
        </ButtonWrapper>
        <BurgerMobile variant={variant} />
        <LanguageWrapper>
          <Languages variant={variant} />
          <AuthButton variant={variant} />
        </LanguageWrapper>
      </HeaderContainer>
    </StyledHeader>
  );
};
