import LoginPage from "../../pages/AuthPages/LoginPage/LoginPage";
import PasswordRecoveryPage from "../../pages/AuthPages/PasswordRecoveryPage/PasswordRecoveryPage";
import RecoveryPage from "../../pages/AuthPages/RecoveryPage/RecoveryPage";
import RegisterPage from "../../pages/AuthPages/RegistrationPage/RegisterPage";

export const AuthRoutesOLD = (ssr) => [
  {
    path: "/:lang?/login/",
    tag: "login",
    type: "auth",
    component: ssr ? LoginPage : () => import("../../pages/AuthPages/LoginPage/LoginPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/registration/",
    tag: "registration",
    type: "auth",
    component: ssr ? RegisterPage : () => import("../../pages/AuthPages/RegistrationPage/RegisterPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/recovery/",
    tag: "recovery",
    type: "auth",
    component: ssr ? RecoveryPage : () => import("../../pages/AuthPages/RecoveryPage/RecoveryPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/recovery/:token",
    tag: "recovery-password",
    type: "auth",
    component: ssr ? PasswordRecoveryPage : () =>
      import("../../pages/AuthPages/PasswordRecoveryPage/PasswordRecoveryPage"),
    isAuthRequired: false,
    isSsr: false
  }
];
