import styled from "@emotion/styled";

export const StyledInfoPart = styled.div`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 140%;
  margin-bottom: 24px;
`;

export const StyledListSubInfo = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const StyledListSubInfoItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media (min-width: ${(p) =>
      p.isDashboard ? p.theme.screenSizes.lgMin : p.theme.screenSizes.xlMin}) {
    display: flex;
    align-items: flex-start;
  }
`;

export const StyledInfoItemTitle = styled.span`
  font-weight: ${(p) => p.theme.fontWeight.bold};
  margin-bottom: 8px;
  @media (min-width: ${(p) =>
      p.isDashboard ? p.theme.screenSizes.lgMin : p.theme.screenSizes.xlMin}) {
    margin-right: 12px;
    margin-bottom: 0;
    min-width: 110px;
  }
`;
