import PT, { bool } from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";

import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";

import {
  StyledRecentReviewsAllButton,
  StyledRecentReviewsContainer,
  StyledRecentReviewsList,
  StyledRecentReviewsListItem,
  StyledRecentReviewsListMobileBox,
  StyledRecentReviewsTitle,
  StyledRecentReviewsTitleBox
} from "./RecentReviews.styled";
import { Review } from "./Review/Review";

export const RecentReviews = ({
  isHiden,
  className,
  onAll,
  itemHovered,
  siteNameFirst,
  isSitePage,
  isMainPage
}) => {
  const { t } = useTranslation();
  const handleAllReviews = () => onAll?.();
  // **Redux state
  const { reviews } = useSelector(getAllContent);
  return (
    <StyledRecentReviewsContainer
      className={`${className} ${isHiden ? "is_hidden" : ""}`}
      isSitePage={isSitePage}
    >
      <StyledRecentReviewsTitleBox isSitePage={isSitePage}>
        <StyledRecentReviewsTitle tag={"h3"} isSitePage={isSitePage}>
          {t("recentReviews.title")}
        </StyledRecentReviewsTitle>
        {!isMainPage && (
          <StyledRecentReviewsAllButton
            iconRight={"arrowAltRight"}
            className={"all_btn"}
            onClick={handleAllReviews}
            isSitePage={isSitePage}
          >
            {t("recentReviews.all")}
          </StyledRecentReviewsAllButton>
        )}
      </StyledRecentReviewsTitleBox>
      <StyledRecentReviewsList>
        {reviews.isDataLoaded
          ? reviews.data.map((review, index) => (
              <StyledRecentReviewsListItem key={index} isSitePage={isSitePage}>
                <Review
                  review={review}
                  itemHovered={itemHovered}
                  siteNameFirst={siteNameFirst}
                />
              </StyledRecentReviewsListItem>
            ))
          : Array.from({ length: 5 }).map((_, index) => (
              <StyledRecentReviewsListItem key={index} isSitePage={isSitePage}>
                <Review loading />
              </StyledRecentReviewsListItem>
            ))}
      </StyledRecentReviewsList>
      <StyledRecentReviewsListMobileBox isSitePage={isSitePage}>
        <Swiper
          style={{
            padding: "24px 16px"
          }}
          breakpoints={{
            320: {
              slidesPerView: 1.05,
              spaceBetween: 12
            },

            480: {
              slidesPerView: 1.1,
              spaceBetween: 12
            },
            768: {
              slidesPerView: 2.1,
              spaceBetween: 12
            }
          }}
        >
          {reviews.isDataLoaded
            ? reviews.data.map((review, index) => (
                <SwiperSlide key={index}>
                  <Review review={review} siteNameFirst={siteNameFirst} />
                </SwiperSlide>
              ))
            : Array.from({ length: 5 }).map((_, index) => (
                <SwiperSlide key={index}>
                  <Review loading />
                </SwiperSlide>
              ))}
        </Swiper>
      </StyledRecentReviewsListMobileBox>
    </StyledRecentReviewsContainer>
  );
};

RecentReviews.propTypes = {
  isHiden: PT.bool,
  className: PT.string,
  onAll: PT.func,
  itemHovered: bool,
  siteNameFirst: PT.bool,
  isSitePage: PT.bool,
  isMainPage: PT.bool
};

RecentReviews.defaultProps = {
  className: "",
  isHiden: false,
  onAll: null,
  itemHovered: false,
  siteNameFirst: false,
  isSitePage: false,
  isMainPage: false
};

export default RecentReviews;
