import PT from "prop-types";
import DatePicker from "react-datepicker";
import { Controller, useFormContext } from "react-hook-form";

import { FormFieldWrapper } from "../FormFieldWrapper/FormFieldWrapper";

import {
  CalendarContainer,
  FormDateBoxStyled,
  FormDateCalendarIcon
} from "./FormDate.styled";

export const FormDate = ({ name, id, label, className, rules, showError }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={[new Date(), null]}
      render={({ field, fieldState: { error } }) => (
        <FormFieldWrapper
          className={className}
          label={label}
          error={error?.message}
          showError={showError}
          htmlFor={id}
        >
          <FormDateBoxStyled isError={!!error}>
            <DatePicker
              id={id}
              showIcon
              icon={<FormDateCalendarIcon name="calendar" />}
              calendarContainer={({ className, children }) => (
                <CalendarContainer className={className}>
                  {children}
                </CalendarContainer>
              )}
              selectsRange
              monthsShown={2}
              startDate={field.value[0]}
              endDate={field.value[1]}
              onChange={(dates) => field.onChange(dates)}
              popperPlacement="bottom-end"
            />
          </FormDateBoxStyled>
        </FormFieldWrapper>
      )}
    />
  );
};

FormDate.propTypes = {
  name: PT.string.isRequired,
  label: PT.string,
  className: PT.string,
  showError: PT.bool
};

FormDate.defaultProps = {
  label: "",
  className: "",
  showError: false
};
