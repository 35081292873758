import styled from "@emotion/styled";
import DataTable from "react-data-table-component";

export const DnsTableWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.card};
  padding: 16px;
  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    width: 100%;
    height: 390px;
    padding: 0;
    box-shadow: none;
    margin-left: 5px;
    margin-right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    height: 58px;
    bottom: 28px;
    width: 94%;
    background: linear-gradient(348deg, #fff 18%, #ffffff00 102.73%);
    @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
      bottom: 18px;
      width: 100%;
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.xlMin}) {
      bottom: 0;
      right: 6px;
    }
  }
`;

export const ResultTable = styled(DataTable)`
  font-family: ${({ theme }) => theme.fontFamily.lato};
  border-radius: 12px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    position: absolute;
  }

  .rdt_Table {
    border: 0;
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.white};
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 16px;

    @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
      padding: 0;
      box-shadow: ${({ theme }) => theme.shadows.card};
    }
  }

  .rdt_TableHead {
    border: 0;
    padding-top: 0;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors["neutral-400"]};
    font-size: ${({ theme }) => theme.fontSizes.s};
  }

  .rdt_TableHeadRow {
    border-radius: 12px;
    background-color: #f6f7f9;
    height: 48px;
    min-height: 48px;
    border: 0;

    .rdt_TableCol {
      padding-left: 24px;
      padding-right: 0;
    }
  }

  .rdt_TableBody {
    border: 0;
    max-height: 320px;
    color: ${({ theme }) => theme.colors["neutral-800"]};
    overflow-y: auto;
  }

  .rdt_TableRow {
    height: 48px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(216, 218, 224, 0.5);
    }

    &:hover {
      background: rgba(246, 247, 249, 0.6);
    }
  }

  .rdt_TableCell {
    padding-left: 24px;

    & > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .rdt_TableCol_Sortable {
    div {
      white-space: normal;
    }
  }
`;

export const ResultColumnText = styled.span`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  line-height: 125%;
`;

export const ResultColumnCountry = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CountryFlag = styled.img`
  width: 22px;
  height: 16px;
  flex-shrink: 0;
`;
export const CountryName = styled.span`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  line-height: 140%;
`;
