import { useTranslation } from "react-i18next";
import { $api } from "../../../services/AxiosInstance";

import { Breadcrumbs } from "../../../components/common";
import { AllActions } from "../../../store/reducers/AllActions";

import { Addsite } from "./Addsite/Addsite";

const AddsitePage = () => {
  // **Redux state
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        items={t("addsitePage.breadcrumbs", {
          returnObjects: true
        })}
      />
      <Addsite />
    </>
  );
};

export default AddsitePage;

AddsitePage.getServerSideState = async (store, params) => {
  const { data: goalsByGroups } = await $api.get(
    "/api/front/goal/group/all",
    { params: { languageCode: params.locale } }
  );
  const { data: goals } = await $api.get("/api/goal/front/all", {
    params: { params: { languageCode: params.locale } }
  });

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(AllActions.setGoals(goals));
};
