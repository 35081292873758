import styled from "@emotion/styled";

import Button from "../../../../ui/New/Button/Button";

export const DashboardHeaderWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 16px;
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    position: relative;
    width: 100%;
    top: unset;
    right: unset;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 48px 10px 288px;

    background-color: ${({ theme }) => theme.colors["background-color"]};
    border-bottom: 1px solid #ebecef;
  }
`;

export const Avatar = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  width: 36px;
  height: 36px;
  border-radius: 50%;

  background-color: ${({ theme }) => theme.colors["main-400"]};
  color: ${({ theme }) => theme.colors["neutral-800"]};

  transition: background-color 0.3s ease;
`;

export const Email = styled.span`
  display: none;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: inline-block;
    margin-left: 8px;
    color: #222133;
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

export const ButtonAvatar = styled.button`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: 500;

  outline: none;

  @media (max-width: ${({ theme }) => theme.screenSizes.mdMax}) {
    &:hover,
    &:focus {
      & .avatar {
        background-color: ${({ theme }) => theme.colors["main-500"]};

        transition: background-color 0.3s ease;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    border-radius: 8px;
    padding: 8px;
    background-color: transparent;

    transition: background-color 0.3s ease;

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.colors.white};
      transition: background-color 0.3s ease;
    }
  }
`;

export const ActionsBox = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 4px 0;
  }
`;

export const LogoutButton = styled(Button)`
  display: none;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: inline-block;
    padding: 10px;
    background-color: transparent;
    background-image: none;
    max-height: 44px;

    transition: color 0.3s ease, background-color 0.3s ease;

    & svg {
      width: 24px;
      height: 24px;
    }

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.colors["neutral-800"]};
      background-color: ${({ theme }) => theme.colors.white};

      transition: color 0.3s ease, background-color 0.3s ease;
    }
  }
`;
