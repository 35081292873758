import styled from "@emotion/styled";

export const StyledShortInfoList = styled.ul`
  list-style: none;
  padding: 0;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  margin-top: auto;
`;

export const StyledShortInfoItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: felx-start;
  gap: 8px;

  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: 600;
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-family: ${(p) => p.theme.fontFamily.lato};
  line-height: 130%;
`;

export const StyledShortInfoText = styled.div`
  color: ${(p) =>
    p.variant === "value"
      ? p.theme.colors["neutral-800"]
      : p.theme.colors["neutral-400"]} !important;
  & > div {
    color: ${(p) =>
      p.variant === "value"
        ? p.theme.colors["neutral-800"]
        : p.theme.colors["neutral-400"]} !important;
  }
`;
