import styled from "@emotion/styled";

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StatusImage = styled.img`
  width: 84px;
  height: 84px;
  margin-bottom: 24px;
  flex: none;
`;

export const StatusValue = styled.div`
  color: ${(p) => p.theme.colors["neutral-800"]};
  text-align: center;
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%;
  margin-bottom: 8px;

  span {
    color: ${(p) => p.theme.colors["main-700"]};
  }
`;

export const Description = styled.p`
  max-width: 428px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  text-align: center;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
`;
