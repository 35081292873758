import { useEffect, useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import RecentReviews from "../../../../../components/common/New/RecentReviews/RecentReviews";
import { GroupButtonTab } from "../../../../../components/ui/New/GroupButtonTab/GroupButtonTab";
import Icon from "../../../../../components/ui/New/Icon/Icon";
import Select from "../../../../../components/ui/New/Select/Select";

import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";

import { useLangUrlDefault } from "../../../../../hooks";

import {
  StyledAboutContainer,
  StyledAboutContentTop,
  StyledAboutSelectBox,
  StyledCaptchaBox,
  StyledLabelOption
} from "./About.styled";
import { HeadAbout } from "./HeadAbout/HeadAbout";
import { Info } from "./Informations/Info";
import { Promocodes } from "./Promocodes/Promocodes";
import { Rating } from "./Rating/Rating";
import { AddReview } from "./Reviews/AddReview/AddReview";
import { ListReview } from "./Reviews/ListReview/ListReview";

export const About = () => {
  const { alias } = useParams();

  const { pathname, hash } = useLocation();
  const [, lastUrlParam] = pathname.split("/").reverse();
  // **Ref
  const captchaRef = useRef(null);
  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();

  const options = [
    {
      label: (
        <StyledLabelOption>
          <Icon name={"info"} />
          {t("proxySitePage.about.tabs.info")}
        </StyledLabelOption>
      ),
      value: "info"
    },
    {
      label: (
        <StyledLabelOption>
          <Icon name={"messegeText"} />
          {t("proxySitePage.about.tabs.reviews")}
        </StyledLabelOption>
      ),
      value: "reviews"
    },
    {
      label: (
        <StyledLabelOption>
          <Icon name={"lineChart"} />
          {t("proxySitePage.about.tabs.rating")}
        </StyledLabelOption>
      ),
      value: "rating"
    },
    {
      label: (
        <StyledLabelOption>
          <Icon name={"ticket"} />
          {t("proxySitePage.about.tabs.promocodes")}
        </StyledLabelOption>
      ),
      value: "promocodes"
    }
  ];

  const initTab = (urlParam, hash) => {
    if (urlParam === "promocodes") {
      return "promocodes";
    }

    if (hash) {
      const tab = options.find(
        (option) => option.value === hash.replace("#", "")
      );

      return tab ? tab.value : "info";
    }

    return "";
  };

  // **Redux state
  const { captchaConfig } = useSelector(getAllContent);

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(null);
  const [, setTokenCaptcha] = useState(null);

  const { enable, clientKey } = captchaConfig.data;

  const setTab = (newTab = "") => {
    if (!newTab || !options.find((option) => option.value === newTab)) {
      setActiveTab("info");
      navigate(`${hrefLang}/new/proxy/${alias}/#info`, { replace: true });
      return;
    }

    setActiveTab(newTab);
    if (newTab === "promocodes") {
      navigate(`${hrefLang}/new/proxy/${alias}/promocodes/`, {
        replace: !newTab
      });
    } else {
      navigate(`${hrefLang}/new/proxy/${alias}/#${newTab}`, {
        replace: !newTab
      });
    }
  };

  useEffect(() => {
    if (!activeTab) {
      setTab(initTab(lastUrlParam, hash.replace("#", "")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUrlParam, hash]);

  return (
    <StyledAboutContainer active={activeTab}>
      <StyledAboutContentTop active={activeTab}>
        <HeadAbout onChange={setTab} />
        <StyledAboutSelectBox>
          <Select
            options={options}
            value={activeTab}
            onChange={setTab}
            size={"lg"}
          />
        </StyledAboutSelectBox>
        <GroupButtonTab data={options} active={activeTab} onSelect={setTab} />
        <Info active={activeTab === "info"} />
        <AddReview
          active={activeTab === "reviews"}
          captchaRef={captchaRef}
          setTokenCaptcha={setTokenCaptcha}
        />
        <Rating active={activeTab === "rating"} />
        <Promocodes active={activeTab === "promocodes"} />
      </StyledAboutContentTop>
      <RecentReviews
        isHiden={activeTab !== "info"}
        onAll={() => setTab("reviews")}
        isSitePage
      />
      <ListReview
        active={activeTab === "reviews"}
        captchaRef={captchaRef}
        setTokenCaptcha={setTokenCaptcha}
      />
      {enable && clientKey && (
        <StyledCaptchaBox>
          <HCaptcha
            ref={captchaRef}
            sitekey={clientKey}
            onVerify={setTokenCaptcha}
            onExpire={() => setTokenCaptcha(null)}
            onError={(err) => toast.error(err?.message)}
            size="invisible"
          />
        </StyledCaptchaBox>
      )}
    </StyledAboutContainer>
  );
};
