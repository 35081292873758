import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AllProxyCountriesModal } from "../../../../../../components/common/New/Modals/AllProxyCountriesModal/AllProxyCountriesModal";
import YesNo from "../../../../../../components/common/New/YesNo/YesNo";
import TextButton from "../../../../../../components/ui/New/TextButton/TextButton";

import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";

import { getCountriesById } from "../../../../../../utils/helpers";

import { TabTitle } from "../TabTitle/TabTitle";

import { StyledInformadionsBlock, StyledListInfo } from "./Informations.styled";
import { RowInfo } from "./RowInfo";

export const Info = ({ active }) => {
  const { t } = useTranslation();

  const {
    site: { data, isDataLoaded }
  } = useSelector(getAllProxySite);

  const [open, setOpen] = useState(false);

  const { countries } = useSelector(getAllContent);
  const allNewCountries =
    data?.proxyTypeProps?.length > 0
      ? data.proxyTypeProps?.reduce((acc, type) => {
          if (type?.countryIds?.length > 0) {
            type.countryIds.forEach((id) => {
              if (!acc.includes(id)) {
                acc.push(id);
              }
            });
          }
          return acc;
        }, [])
      : [];
  const countriesList = getCountriesById(allNewCountries, countries.data);

  const auth = [
    ...(data?.clientIPAuth
      ? [t("proxyPage.content.table.characteristics.autorization.ip")]
      : []),
    ...(data?.clientLoginAuth
      ? [t("proxyPage.content.table.characteristics.autorization.login")]
      : []),
    ...(data?.clientNoAuth
      ? [t("proxyPage.content.table.characteristics.autorization.noAuth")]
      : [])
  ];

  return (
    <StyledInformadionsBlock active={active}>
      <TabTitle
        title={t("proxySitePage.about.info.title")}
        imgSrc={"/img/site-page/Info.png"}
      />
      {isDataLoaded ? (
        <StyledListInfo>
          <RowInfo
            title={t("proxySitePage.about.info.countries")}
            valueDirection="column"
          >
            <span>
              {countriesList.length} {t("proxyPage.content.table.geo.title")}
            </span>
            <TextButton size={"sm"} onClick={() => setOpen(true)}>
              {t("proxyPage.content.table.geo.modal.title")}
            </TextButton>
          </RowInfo>
          {data?.speedFrom && data?.speedTo ? (
            <RowInfo
              title={t("proxySitePage.about.info.speed")}
              tooltip={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              }
              labelCenter
            >
              <span>{`from ${data?.speedFrom} Mb/s`}</span>
              <span>{`to ${data?.speedTo} Mb/s`}</span>
            </RowInfo>
          ) : null}
          {data?.pingFrom && data?.pingTo ? (
            <RowInfo
              title={t("proxySitePage.about.info.ping")}
              tooltip={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              }
              labelCenter
            >
              <span>{`from ${data?.pingFrom} ms`}</span>
              <span>{`to ${data?.pingTo} ms`}</span>
            </RowInfo>
          ) : null}
          <RowInfo title={t("proxySitePage.about.info.auth")} labelCenter>
            {auth?.length
              ? auth.map((item, index) => (
                  <span key={`auth${index}`}>
                    {item}
                    {index + 1 < auth?.length ? "," : ""}
                  </span>
                ))
              : null}
          </RowInfo>
          <RowInfo title={t("proxySitePage.about.info.api")}>
            <YesNo value={data?.api} />
          </RowInfo>
        </StyledListInfo>
      ) : (
        <StyledListInfo>
          {[...Array(4)].map((_, index) => (
            <RowInfo key={`skeleton_${index}`} loading />
          ))}
        </StyledListInfo>
      )}

      <AllProxyCountriesModal
        data={countriesList}
        open={open}
        onClose={setOpen}
      />
    </StyledInformadionsBlock>
  );
};
