import { Suspense, useEffect } from "react";

import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import animFitback from "../../assets/animations/good_service.riv";
import animAutn from "../../assets/animations/welcome_to_trustytech.riv";
import { Rive } from "../../components/ui/New/Rive/Rive";
import {
  useDispatchedActions,
  useLangUrlDefault,
  useMatchedRoute
} from "../../hooks";
import { getAllUser } from "../../store/reducers/UserReducer/User.selectors";

import {
  AnimationWrapper,
  AuthLayoutStyled,
  RestyledLogo,
  RestyledSubtitle,
  RestyledTitle,
  StyledAside,
  StyledSectionWrapper
} from "./AuthLayout.styled";

export const AuthLayout = () => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const route = useMatchedRoute();

  // **Redux state
  const { isUserAuthenticated, isRedirectToCabinet } = useSelector(getAllUser);
  // const { pageContent: { current } } = useSelector(getAllContent);
  // **Dispatch
  const { setIsRedirectToCabinet } = useDispatchedActions();

  useEffect(() => {
    if (
      isUserAuthenticated &&
      !isRedirectToCabinet &&
      route.tag !== "feedback"
    ) {
      setIsRedirectToCabinet(false);
      navigate(`${hrefLang}/dashboard/profile/`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated, isRedirectToCabinet]);

  const translate = t("layouts.auth", { returnObjects: true });

  return (
    <AuthLayoutStyled>
      <RestyledLogo color="black" />
      <StyledAside>
        <RestyledTitle tag="h3" size="sm" color="white">
          {translate?.[route?.tag]?.title || translate.default?.title}
        </RestyledTitle>
        <RestyledSubtitle size="lg" color="white">
          {translate?.[route?.tag]?.subtitle || translate.default?.subtitle}
        </RestyledSubtitle>
        <AnimationWrapper variant={route?.tag}>
          <Rive
            src={route?.tag === "feedback" ? animFitback : animAutn}
            autoPlay
            top={route?.tag === "feedback" ? -91 : -40}
            left={route?.tag === "feedback" ? -91 : -66}
            bottom={route?.tag === "feedback" ? -96 : -76}
            right={route?.tag === "feedback" ? -91 : -66}
          />
        </AnimationWrapper>
      </StyledAside>
      <StyledSectionWrapper>
        <Suspense fallback>
          <Outlet />
        </Suspense>
      </StyledSectionWrapper>
    </AuthLayoutStyled>
  );
};
