import styled from "@emotion/styled";

export const StyledListReview = styled.div`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: flex;
    flex-direction: column;

    padding: 32px;

    border-radius: 12px;
    background: ${(p) => p.theme.colors.white};
    box-shadow: ${(p) => p.theme.shadows.card};
  }
`;
