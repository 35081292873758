import styled from "@emotion/styled";

export const StyledAboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    gap: 24px;
    margin-bottom: 48px;
  }
`;

export const StyledAboutTitleBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const StyledAboutTitle = styled.h3`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%;
  margin: 0;
  max-width: 100%;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    font-size: ${(p) => p.theme.fontSizes.xll};
  }
`;

export const StyledAboutButtonBox = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-left: auto;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    flex: 0 1 auto;
  }
  & > * {
    height: 40px !important;
    min-height: 40px;
    flex: 1 0 fit-content;
  }
`;

export const StyledAboutDescription = styled.p`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
`;
