import { useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import camelCase from "lodash.camelcase";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Progress } from "../../../../../components/common/New/Progress/Progress";
import { FormInput } from "../../../../../components/forms/New/FormInput/FormInput";
import { useUserIp } from "../../../../../hooks/useUserIp";
import { IpBlacklistCheckSchema } from "../../../../../utils/validation/IpBlacklistCheck.validation";

import {
  StyledAdditionalText,
  StyledButton,
  StyledContainer,
  StyledForm,
  StyledInfoMessage,
  StyledInnerWrapper,
  StyledInputWrapper,
  StyledTitle,
  StyledList,
  StyledListWrapper,
  StyledResults,
  StyledResultsContent,
  StyledResultsItem
} from "./IpCheck.styled";
import { IpCheckItem } from "./IpCheckItem/IpCheckItem";

const mockData = [
  {
    address: "xbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "zen.spamhaus.org",
    result: "Listed"
  },
  {
    address: "pbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "sbl-xbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "sbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "cbl.abuseat.org",
    result: "Listed"
  },
  {
    address: "xbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "zen.spamhaus.org",
    result: "Listed"
  },
  {
    address: "pbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "sbl-xbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "sbl.spamhaus.org",
    result: "Not listed"
  },
  {
    address: "cbl.abuseat.org",
    result: "Listed"
  }
];

export const IpCheck = () => {
  const { t } = useTranslation();

  const userIp = useUserIp();

  const methods = useForm({
    resolver: yupResolver(
      IpBlacklistCheckSchema(t("ipBlacklist", { returnObjects: true }))
    )
  });

  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const onSubmit = (data) => {
    try {
      setIsLoading(true);

      methods.reset();

      setResult(mockData);

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      // setIsLoading(false);
    }
  };

  const checkList = useMemo(
    () => [
      {
        title: t("ipBlacklist.checks"),
        image: "img/ui/cheks.svg",
        number: 0
      },
      {
        title: t("ipBlacklist.checkedOut"),
        image: "img/ui/checkedOut.svg",
        number: 0
      },
      {
        title: t("ipBlacklist.blacklisted"),
        image: "img/ui/blacklisted.svg",
        number: 0
      }
    ],
    [t]
  );

  const memoResultList = useMemo(
    () =>
      result?.map((el, index) => (
        <StyledResultsItem key={index} variant={camelCase(el.result)}>
          {index + 1}. {el.address} -{" "}
          <span>{el.result}</span>
        </StyledResultsItem>
      )),
    [result]
  );

  const memoCheckList = useMemo(
    () => checkList.map((item) => <IpCheckItem key={item.title} {...item} />),
    [checkList]
  );

  return (
    <StyledContainer>
      <StyledInnerWrapper>
        <StyledForm>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <StyledTitle tag="h2">
                {t("ipBlacklist.ipCheckserver")}
              </StyledTitle>
              <StyledInputWrapper>
                <FormInput
                  name="ipAddress"
                  type="text"
                  placeholder={t("ipBlacklist.form.enterIp")}
                  label={t("ipBlacklist.form.label")}
                  showError
                />
                <StyledAdditionalText
                type="button"
                  onClick={() => {
                    methods.setValue("ipAddress", userIp?.ipAddress);
                    methods.clearErrors();
                  }}
                >
                  {t("portScanner.form.pasteIP")}
                </StyledAdditionalText>
              </StyledInputWrapper>
              <StyledButton
                type="submit"
                loading={isLoading}
                {...(isLoading && { iconLeft: "loading" })}
                fullWidth
              >
                {isLoading
                  ? t("ipBlacklist.form.loading")
                  : t("ipBlacklist.form.examination")}
              </StyledButton>
            </form>
          </FormProvider>
          <StyledInfoMessage>
            <img src="img/icons/information.svg" alt="Info icon" />
            <span>{t("ipBlacklist.form.infoMessage")}</span>
          </StyledInfoMessage>
        </StyledForm>
        <StyledListWrapper>
          <StyledList>{memoCheckList}</StyledList>
          <StyledResults isLoading={isLoading}>
            {isLoading ? (
              <Progress isLoading={isLoading} />
            ) : (
              <StyledResultsContent>
                {result ? memoResultList : null}
              </StyledResultsContent>
            )}
          </StyledResults>
        </StyledListWrapper>
      </StyledInnerWrapper>
    </StyledContainer>
  );
};
